import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * AWSDateTime is legacy from AppSync - the type means a date and is not validated as such
   * It will be replaced by a proper, validated date type in the future.
   */
  AWSDateTime: any;
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
};




export enum Role {
  Admin = 'ADMIN',
  TeamLead = 'TEAM_LEAD',
  ReadOnly = 'READ_ONLY'
}

export enum TimePeriodType {
  Currentmonth = 'CURRENTMONTH',
  Previousmonth = 'PREVIOUSMONTH'
}

export enum Provider {
  Aws = 'AWS',
  Azure = 'AZURE',
  Gcp = 'GCP',
  Services = 'SERVICES',
  Nordcloud = 'NORDCLOUD',
  External = 'EXTERNAL',
  Vmware = 'VMWARE',
  Kubernetes = 'KUBERNETES',
  Openshift = 'OPENSHIFT',
  Ibmcloud = 'IBMCLOUD',
  Hybrid = 'HYBRID'
}

export type MutationResponse = {
  __typename?: 'MutationResponse';
  id?: Maybe<Scalars['String']>;
};

/**
 * KeyValue is used anywhere we would use a map, but since there is no
 * map/object type for GraphQL we return a list of key/value instead.
 */
export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

/**
 * KeyValueSource is used anywhere we return a list of key/value similarly to KeyValue.
 * KeyValueSource is used where we return metadata provider by different services
 * e.g. tags with the same name can be scanned from scanner and from capacity data
 * the `source` field differentiates which source the metadata comes from.
 */
export type KeyValueSource = {
  __typename?: 'KeyValueSource';
  key: Scalars['String'];
  value: Scalars['String'];
  source: Scalars['String'];
};

export type MetadataAndTags = {
  __typename?: 'MetadataAndTags';
  tags?: Maybe<Array<KeyValueSource>>;
  metadata?: Maybe<Array<KeyValueSource>>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuesInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum CloudProviderType {
  Kubernetes = 'KUBERNETES',
  Openshift = 'OPENSHIFT',
  Vmware = 'VMWARE',
  Cloud = 'CLOUD'
}

export type Account = {
  __typename?: 'Account';
  /** @deprecated id and nid have been unified, and are going to be the same field, use `nid` instead */
  id: Scalars['String'];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars['String'];
  /**
   * `provider` is the cloud provider that the account is from, e.g.
   * `AWS`, `GCP`, `AZURE`, `VMWARE`
   */
  provider: Provider;
  /**
   * `providerId` is how the account is identified within cloud provider, e.g.
   * `123123123123` for AWS, subscription UUID for Azure, organization id for GCP,
   * for VMWARE provider this field contains nid as in VMWARE there is no provider id concept.
   */
  providerId: Scalars['String'];
  /** `name` is usually `alias` when the account is from AWS or similar in others */
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /**
   * Accounts that have no credentials are not `activated`, since they
   * were added automatically
   */
  activated: Scalars['Boolean'];
  /** Metadata stores information for k8s scanner */
  metadata?: Maybe<Scalars['String']>;
  /** Account status is used for discovered resources */
  status?: Maybe<Scalars['String']>;
  /**
   * `providerType` is the cloud provider type, e.g.
   * `KUBERNETES`, `OPENSHIFT`, `VMWARE`, `CLOUD`
   */
  providerType?: Maybe<CloudProviderType>;
  estate?: Maybe<ResourceResponse>;
  contacts?: Maybe<Array<Maybe<Person>>>;
  owner?: Maybe<Person>;
};


export type AccountEstateArgs = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type AccountsFilter = {
  /** Returns only accounts with specified provider */
  providers?: Maybe<Array<Provider>>;
  /** Returns only accounts with specified provider type */
  providerTypes?: Maybe<Array<CloudProviderType>>;
};

export type AccountsResponse = {
  __typename?: 'AccountsResponse';
  accounts?: Maybe<Array<Account>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type AddAccount = {
  providerId: Scalars['String'];
  provider: Provider;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Scalars['String']>>;
  ownerId: Scalars['String'];
  orgUnitIds?: Maybe<Array<Scalars['String']>>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   *   "password": "asdf2389-fwan",
   *   "appId": "63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   *   "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   *   "type": "service_account",
   *   "project_id": "test-sa",
   *   "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   *   "private_key": "-----BEGIN PRIVATE KEY-----...",
   *   "client_email": "test@test-sa.iam.gserviceaccount.com",
   *   "client_id": "494254016998140918876",
   *   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   *   "token_uri": "https://oauth2.googleapis.com/token",
   *   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   *   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "credentials: {
   *     "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   *     "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   *   },
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ],
   *   "region": "cn-northwest-1"
   * }
   */
  creds: Scalars['String'];
};

export type AddAccountV2 = {
  providerId?: Maybe<Scalars['ID']>;
  provider: Provider;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Scalars['ID']>>;
  ownerId: Scalars['ID'];
  metadata?: Maybe<Scalars['String']>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   *   "password": "asdf2389-fwan",
   *   "appId": "63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   *   "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   *   "type": "service_account",
   *   "project_id": "test-sa",
   *   "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   *   "private_key": "-----BEGIN PRIVATE KEY-----...",
   *   "client_email": "test@test-sa.iam.gserviceaccount.com",
   *   "client_id": "494254016998140918876",
   *   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   *   "token_uri": "https://oauth2.googleapis.com/token",
   *   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   *   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "credentials: {
   *     "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   *     "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   *   },
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ],
   *   "region": "cn-northwest-1"
   * }
   */
  creds?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  providerType?: Maybe<CloudProviderType>;
};

export type UpdateAccount = {
  id: Scalars['String'];
  providerId?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Scalars['String']>;
  orgUnitIds?: Maybe<Array<Scalars['String']>>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   *   "password": "asdf2389-fwan",
   *   "appId":	"63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   *   "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   *   "type": "service_account",
   *   "project_id": "test-sa",
   *   "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   *   "private_key": "-----BEGIN PRIVATE KEY-----...",
   *   "client_email": "test@test-sa.iam.gserviceaccount.com",
   *   "client_id": "494254016998140918876",
   *   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   *   "token_uri": "https://oauth2.googleapis.com/token",
   *   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   *   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "credentials: {
   *     "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   *     "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   *   },
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ],
   *   "region": "cn-northwest-1"
   * }
   */
  creds?: Maybe<Scalars['String']>;
};

export type UpdateAccountV2 = {
  providerId?: Maybe<Scalars['ID']>;
  provider?: Maybe<Provider>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Scalars['ID']>>;
  ownerId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['String']>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   *   "password": "asdf2389-fwan",
   *   "appId":	"63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   *   "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   *   "type": "service_account",
   *   "project_id": "test-sa",
   *   "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   *   "private_key": "-----BEGIN PRIVATE KEY-----...",
   *   "client_email": "test@test-sa.iam.gserviceaccount.com",
   *   "client_id": "494254016998140918876",
   *   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   *   "token_uri": "https://oauth2.googleapis.com/token",
   *   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   *   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "credentials: {
   *     "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   *     "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   *   },
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ],
   *   "region": "cn-northwest-1"
   * }
   */
  creds?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated use `accountsV2` instead */
  accounts?: Maybe<Array<Maybe<Account>>>;
  accountsV2?: Maybe<AccountsResponse>;
  /** @deprecated use `accountV2` instead */
  account?: Maybe<Account>;
  accountV2?: Maybe<Account>;
  /** @deprecated use `applicationsPaginated` instead */
  applications?: Maybe<Array<Maybe<Application>>>;
  applicationsPaginated?: Maybe<ApplicationsPaginatedResponse>;
  application?: Maybe<Application>;
  applicationsByIds?: Maybe<Array<Maybe<Application>>>;
  businessContexts?: Maybe<BusinessContextResponse>;
  businessContext?: Maybe<BusinessContext>;
  globalTagKeys?: Maybe<GlobalTagKeys>;
  /** @deprecated use `discoveryRules` */
  mappingRules?: Maybe<Array<Maybe<MappingRule>>>;
  discoveryRules?: Maybe<Array<Maybe<DiscoveryRule>>>;
  /** Information about organization */
  organization?: Maybe<Organization>;
  /** Get events for given nid in date range from evenciarz */
  events?: Maybe<Array<Event>>;
  costMappings?: Maybe<Array<Maybe<CostCategoryMapping>>>;
  costCategories?: Maybe<Array<Maybe<CostCategory>>>;
  dashboardApplicationCost?: Maybe<Array<DashboardApplicationCost>>;
  /** `topSpendingApplications` returns applications with the highest `currentMonth` costs */
  topSpendingApplications: TopSpendingApplicationsResponse;
  reservationsGlobalUtilisation: ReservationsGlobalUtilisation;
  reservationsGlobalCoverage: ReservationsGlobalCoverage;
  environment?: Maybe<Environment>;
  environmentsByIds?: Maybe<Array<Maybe<Environment>>>;
  estate?: Maybe<ResourceResponse>;
  estateRecord?: Maybe<Resource>;
  /**
   * Returns count of all discovered estate records, supports providing multiple filter values
   * e.g. account: ["id1", "id2"]
   * @deprecated use estateResourceCount
   */
  estateCount?: Maybe<Scalars['Int']>;
  /**
   * Returns count of all discovered estate records, supports providing multiple filter values
   * e.g. cloudAccount: ["id1", "id2"]
   */
  estateResourceCount?: Maybe<Scalars['Int']>;
  search?: Maybe<SearchResponse>;
  /**
   * `reservableResources` lists all resources, assigned to a given environment, that are reservable. This means either
   * resources that are reserved all that can be reserved
   */
  reservableResources?: Maybe<CloudResourcesResponse>;
  /** `downloadExport` returns URL of a report previously generated by `requestEstateRecordsExport` */
  downloadExport?: Maybe<DownloadExportResponse>;
  /** `resourceTypes` returns Cloud Estate resource types optionally filtered by providers */
  resourceTypes: Array<Scalars['String']>;
  listReservations?: Maybe<ReservationsPaginated>;
  reservationDetails?: Maybe<Reservation>;
  listReservationCoveredInstances?: Maybe<ReservationCoveredInstancesPaginated>;
  listReservationCoverageByInstanceType?: Maybe<ReservationCoveredByTypeInstancesPaginated>;
  listReservationsRecommendations?: Maybe<ReservationsRecommendationsPaginated>;
  notifications?: Maybe<NotificationsResponse>;
  orgUnitsV2?: Maybe<OrgUnitsV2Response>;
  orgUnitV2?: Maybe<OrgUnitV2>;
  orgUnitWithChildren?: Maybe<OrgUnitWithChildren>;
  orgUnitTypesV2?: Maybe<OrgUnitTypesV2Response>;
  orgUnitTypeV2?: Maybe<OrgUnitTypeV2>;
  people?: Maybe<Array<Maybe<Person>>>;
  person?: Maybe<Person>;
  /** List all active access keys, without secret */
  accessKeysPermissions?: Maybe<AccessKeysPermissionsResponse>;
  /** Get permissions of an active access key, without secret */
  accessKeyPermissions?: Maybe<AccessKeyPermissions>;
  /** List permissions for all users */
  usersPermissions?: Maybe<UsersPermissionsResponse>;
  /** Get permissions of a user */
  userPermissions?: Maybe<UserPermissions>;
  customerDirtiness: Dirtiness;
  recalculations: Array<Maybe<Recalculation>>;
  monthlyReport?: Maybe<MonthlyReportResponse>;
  hooks: Array<Hook>;
  /**
   * Search through tag keys by providing key prefix with minimum length of two.
   * After is an optional cursor for pagination.
   */
  searchTagKeys?: Maybe<SearchTagKeysResponse>;
  /**
   * Search through tag values by providing exact key and value prefix with minimum length of two.
   * After is an optional cursor for pagination.
   */
  searchTagValues?: Maybe<SearchTagValuesResponse>;
  customerConfig?: Maybe<CustomerConfig>;
  /** `cloudWasteSettings` returns defined rules that classify resources as cloud wastes */
  cloudWasteSettings: Array<CloudWasteSetting>;
  /**
   * `cloudWasteSummary` describes summarized cost of cloud waste per policy.
   * It takes optional `period` parameter - string in format YYYY-MM, empty value represents current billing period
   */
  cloudWasteSummary?: Maybe<CloudWasteSummaryResponse>;
  /**
   * This is the new version of the `estate` or `estateRecords` query, which is for now in an 'experimental' stage.
   * This query is missing parameters like search phrase, etc. since we will add them later, and we are not sure
   * on how they should be defined.
   *
   * The query supports single level sorting, via the `sorting` field. It is not required, if no sorting is required,
   * the default sorting will be used - sorting by `COST` `DSC` - or by monthly cost from highest to lowest.
   *
   * This query supports filtering by given filters via the optional `filter` field.
   *
   * This query supports searching by given query via the optional `query` field.
   *
   * This query is naturally using pagination. If the `page` parameter is missing, the first page will be returned.
   */
  estateV2?: Maybe<ResourceResponse>;
};


export type QueryAccountsV2Args = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  filter?: Maybe<AccountsFilter>;
};


export type QueryAccountArgs = {
  id: Scalars['String'];
};


export type QueryAccountV2Args = {
  id: Scalars['String'];
};


export type QueryApplicationsPaginatedArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryApplicationArgs = {
  id: Scalars['String'];
};


export type QueryApplicationsByIdsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryBusinessContextsArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryBusinessContextArgs = {
  id: Scalars['ID'];
};


export type QueryMappingRulesArgs = {
  environmentId: Scalars['String'];
};


export type QueryDiscoveryRulesArgs = {
  environmentId: Scalars['String'];
};


export type QueryEventsArgs = {
  nid: Scalars['String'];
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};


export type QueryTopSpendingApplicationsArgs = {
  limit: Scalars['Int'];
};


export type QueryEnvironmentArgs = {
  id: Scalars['String'];
};


export type QueryEnvironmentsByIdsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
};


export type QueryEstateArgs = {
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<ResourceFilter>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryEstateRecordArgs = {
  nid: Scalars['String'];
};


export type QueryEstateCountArgs = {
  filter?: Maybe<MultipleResourceFilter>;
};


export type QueryEstateResourceCountArgs = {
  filter?: Maybe<EstateResourceFilter>;
};


export type QuerySearchArgs = {
  phrase: Scalars['String'];
  filter?: Maybe<SearchFilter>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryReservableResourcesArgs = {
  environmentId: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryDownloadExportArgs = {
  exportJobId: Scalars['ID'];
};


export type QueryResourceTypesArgs = {
  provider?: Maybe<Array<Provider>>;
};


export type QueryListReservationsArgs = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type QueryReservationDetailsArgs = {
  reservationId: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type QueryListReservationCoveredInstancesArgs = {
  reservationId: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryListReservationCoverageByInstanceTypeArgs = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryListReservationsRecommendationsArgs = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryNotificationsArgs = {
  limit: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationsFilter>;
};


export type QueryOrgUnitsV2Args = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryOrgUnitV2Args = {
  nid: Scalars['ID'];
};


export type QueryOrgUnitWithChildrenArgs = {
  nid: Scalars['ID'];
};


export type QueryOrgUnitTypesV2Args = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryOrgUnitTypeV2Args = {
  id: Scalars['ID'];
};


export type QueryPersonArgs = {
  id: Scalars['String'];
};


export type QueryAccessKeysPermissionsArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryAccessKeyPermissionsArgs = {
  id: Scalars['ID'];
};


export type QueryUsersPermissionsArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryUserPermissionsArgs = {
  email: Scalars['String'];
};


export type QueryMonthlyReportArgs = {
  input: MonthlyReportInput;
};


export type QuerySearchTagKeysArgs = {
  keyPrefix: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QuerySearchTagValuesArgs = {
  key: Scalars['String'];
  valuePrefix: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryEstateV2Args = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<SearchSort>;
  filter?: Maybe<EstateV2Filter>;
  query?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated  use `addAccount` instead */
  addAccount?: Maybe<MutationResponse>;
  addAccountV2?: Maybe<MutationResponse>;
  /** @deprecated  use `updateAccount` instead */
  updateAccount?: Maybe<Scalars['Boolean']>;
  updateAccountV2?: Maybe<Scalars['Boolean']>;
  /** @deprecated  use `removeAccount` instead */
  removeAccount?: Maybe<Scalars['Boolean']>;
  removeAccountV2?: Maybe<Scalars['Boolean']>;
  /** @deprecated use `addApplicationV2` instead */
  addApplication?: Maybe<MutationResponse>;
  addApplicationV2?: Maybe<MutationResponse>;
  /** @deprecated use `updateApplicationV2` instead */
  updateApplication?: Maybe<Scalars['Boolean']>;
  updateApplicationV2?: Maybe<Scalars['Boolean']>;
  /** @deprecated use `removeApplicationV2` instead */
  removeApplication?: Maybe<Scalars['Boolean']>;
  removeApplicationV2?: Maybe<Scalars['Boolean']>;
  addBusinessContext?: Maybe<MutationResponse>;
  updateBusinessContext?: Maybe<Scalars['Boolean']>;
  removeBusinessContext?: Maybe<Scalars['Boolean']>;
  updateGlobalTagKeys?: Maybe<Scalars['Boolean']>;
  /** Add event for given nid in evenciarz */
  addEvent?: Maybe<Scalars['Boolean']>;
  addDiscoveryRule?: Maybe<MutationResponse>;
  updateDiscoveryRule?: Maybe<Scalars['Boolean']>;
  removeDiscoveryRule?: Maybe<Scalars['Boolean']>;
  /** @deprecated use `addDiscoveryRule` */
  addMappingRule?: Maybe<MutationResponse>;
  /** @deprecated use `updateDiscoveryRule` */
  updateMappingRule?: Maybe<Scalars['Boolean']>;
  /** @deprecated use `removeDiscoveryRule` */
  removeMappingRule?: Maybe<Scalars['Boolean']>;
  addCostCategoryMapping?: Maybe<MutationResponse>;
  updateCostCategoryMapping?: Maybe<Scalars['Boolean']>;
  /** @deprecated support for removing cost splitting rules has been dropped */
  removeCostCategoryMapping?: Maybe<Scalars['Boolean']>;
  /**
   * A user with the `TEAM_LEAD` role assigned to him can only add environments to applications that he has access to
   * @deprecated use `addEnvironmentV2` instead
   */
  addEnvironment?: Maybe<MutationResponse>;
  /** A user with the `TEAM_LEAD` role assigned to him can only add environments to applications that he has access to */
  addEnvironmentV2?: Maybe<MutationResponse>;
  /** @deprecated use `updateEnvironmentV2` instead */
  updateEnvironment?: Maybe<Scalars['Boolean']>;
  updateEnvironmentV2?: Maybe<Scalars['Boolean']>;
  /** @deprecated use `removeEnvironmentV2` instead */
  removeEnvironment?: Maybe<Scalars['Boolean']>;
  removeEnvironmentV2?: Maybe<Scalars['Boolean']>;
  /**
   * Permanently attach a resource to an environment
   * Required fields are: resource id or nid (from the `id` field of CloudResource) of resource that is being attached
   * and nid of the environment (from the `nid` field of Environment)
   */
  attachResource?: Maybe<Scalars['Boolean']>;
  /**
   * Mark and unmark resources as reserved instances in Cloud Estate
   * Note that there is bulk request limit in Cloud Estate
   */
  reservedInstances?: Maybe<Scalars['Boolean']>;
  /**
   * Accept (when `accept` is equal to true) or reject (when `accept` is equal to false)
   * pending drifts for a list of resources given by the `ids` array
   * ids in the `ids` array are resource identifiers (`id` field from CloudResource)
   * if there are no drifts for one of resources in the list, an error will be returned
   */
  updateDriftStatuses?: Maybe<Scalars['Boolean']>;
  /** Request an asynchronous export of the estate records to csv file */
  requestEstateRecordsExport?: Maybe<RequestExportResponse>;
  markNotificationsAsRead?: Maybe<Scalars['Boolean']>;
  addOrgUnitV2?: Maybe<MutationResponse>;
  updateOrgUnitV2?: Maybe<Scalars['Boolean']>;
  removeOrgUnitV2?: Maybe<Scalars['Boolean']>;
  addOrgUnitTypeV2?: Maybe<MutationResponse>;
  updateOrgUnitTypeV2?: Maybe<Scalars['Boolean']>;
  removeOrgUnitTypeV2?: Maybe<Scalars['Boolean']>;
  /**
   * `addEnvToOrgUnitV2` attaches an Environment to a OrgUnitV2
   * each environment can be attached to one org unit within a specific BusinessContext, but can be attached to
   * many different OrgUnits that belong to different BusinessContexts
   * `env` is the id of the Environment that is to be attached
   * `orgUnit` is the nid of the OrgUnit that the environment should be attached to\
   */
  addEnvToOrgUnitV2?: Maybe<Scalars['Boolean']>;
  /**
   * `removeEnvFromOrgUnitV2` removes the attachment that was created via `addEnvToOrgUnitV2`
   * After the removal the environment can still be attached to other OrgUnits
   * `env` is the id of the Environment that is to be removed
   * `orgUnit` is the nid of the OrgUnit that the environment should be removed from
   */
  removeEnvFromOrgUnitV2?: Maybe<Scalars['Boolean']>;
  addPerson?: Maybe<MutationResponse>;
  updatePerson?: Maybe<Scalars['Boolean']>;
  removePerson?: Maybe<Scalars['Boolean']>;
  /**
   * Create an access key with access devised similarly to `setUserPermissions`
   * This mutation returns the permissions ąssigned to the key, along with instances
   * Identifier and secret that is needed to use the API
   *
   * The secret is only available within this operation, and can't be retrieved later
   */
  createAccessKey: AccessKeyPermissionsWithSecret;
  /** Update a key with different permissions */
  updateAccessKeyPermissions?: Maybe<Scalars['Boolean']>;
  /** Remove an access key */
  removeAccessKey?: Maybe<Scalars['Boolean']>;
  /**
   * Set permissions for a user
   * If the ROLE is `ADMIN` applications list is obsolete
   * If the ROLE is  `TEAM_LEAD`, the `applications` field controls to which applications and
   * entities assigned the user has access
   */
  setUserPermissions?: Maybe<Scalars['Boolean']>;
  /** Revoke any permissions that user had previously assigned */
  removeUserPermissions?: Maybe<Scalars['Boolean']>;
  /**
   * Trigger the scan process for the entire customer
   * a subset of all the accounts can be selected, to skip scan for unwanted accounts
   */
  triggerScan?: Maybe<Scalars['Boolean']>;
  /** Set a budget for an application given by `nid` */
  setApplicationBudget: Scalars['Boolean'];
  /** Set a budget for an environment given by `nid` */
  setEnvironmentBudget: Scalars['Boolean'];
  /** Remove the budget from an application given by `nid` */
  unsetApplicationBudget: Scalars['Boolean'];
  /** Remove the budget from an environment given by `nid` */
  unsetEnvironmentBudget: Scalars['Boolean'];
  triggerRecalculation?: Maybe<Scalars['Boolean']>;
  setHook?: Maybe<Scalars['Boolean']>;
  removeHook?: Maybe<Scalars['Boolean']>;
  updateRICoverageTarget?: Maybe<Scalars['Boolean']>;
  updateDriftsDisabled?: Maybe<Scalars['Boolean']>;
  updateAzureRGTagPropagation?: Maybe<Scalars['Boolean']>;
  /** Update status or expiration date of existing cloud waste setting rule that classifies resources as cloud wastes */
  updateCloudWasteSetting?: Maybe<Scalars['Boolean']>;
};


export type MutationAddAccountArgs = {
  input?: Maybe<AddAccount>;
};


export type MutationAddAccountV2Args = {
  input?: Maybe<AddAccountV2>;
};


export type MutationUpdateAccountArgs = {
  input?: Maybe<UpdateAccount>;
};


export type MutationUpdateAccountV2Args = {
  nid: Scalars['ID'];
  input?: Maybe<UpdateAccountV2>;
};


export type MutationRemoveAccountArgs = {
  id: Scalars['String'];
};


export type MutationRemoveAccountV2Args = {
  nid: Scalars['ID'];
};


export type MutationAddApplicationArgs = {
  input?: Maybe<AddApplication>;
};


export type MutationAddApplicationV2Args = {
  input?: Maybe<AddApplicationV2>;
};


export type MutationUpdateApplicationArgs = {
  input?: Maybe<UpdateApplication>;
};


export type MutationUpdateApplicationV2Args = {
  nid: Scalars['ID'];
  input: UpdateApplicationV2;
};


export type MutationRemoveApplicationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveApplicationV2Args = {
  nid: Scalars['ID'];
};


export type MutationAddBusinessContextArgs = {
  input: AddBusinessContext;
};


export type MutationUpdateBusinessContextArgs = {
  id: Scalars['String'];
  input: UpdateBusinessContext;
};


export type MutationRemoveBusinessContextArgs = {
  id: Scalars['String'];
};


export type MutationUpdateGlobalTagKeysArgs = {
  input?: Maybe<UpdateGlobalTagKeys>;
};


export type MutationAddEventArgs = {
  input?: Maybe<AddEvent>;
};


export type MutationAddDiscoveryRuleArgs = {
  input: AddDiscoveryRule;
};


export type MutationUpdateDiscoveryRuleArgs = {
  input: UpdateDiscoveryRule;
};


export type MutationRemoveDiscoveryRuleArgs = {
  id: Scalars['String'];
};


export type MutationAddMappingRuleArgs = {
  input?: Maybe<AddMappingRule>;
};


export type MutationUpdateMappingRuleArgs = {
  input?: Maybe<UpdateMappingRule>;
};


export type MutationRemoveMappingRuleArgs = {
  id: Scalars['String'];
};


export type MutationAddCostCategoryMappingArgs = {
  input?: Maybe<AddCostCategoryMapping>;
};


export type MutationUpdateCostCategoryMappingArgs = {
  input?: Maybe<UpdateCostCategoryMapping>;
};


export type MutationRemoveCostCategoryMappingArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationAddEnvironmentArgs = {
  input?: Maybe<AddEnvironment>;
};


export type MutationAddEnvironmentV2Args = {
  input?: Maybe<AddEnvironmentV2>;
};


export type MutationUpdateEnvironmentArgs = {
  input?: Maybe<UpdateEnvironment>;
};


export type MutationUpdateEnvironmentV2Args = {
  nid: Scalars['ID'];
  input?: Maybe<UpdateEnvironmentV2>;
};


export type MutationRemoveEnvironmentArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEnvironmentV2Args = {
  nid: Scalars['ID'];
};


export type MutationAttachResourceArgs = {
  resourceNid: Scalars['String'];
  environmentNid: Scalars['String'];
};


export type MutationReservedInstancesArgs = {
  mark?: Maybe<Array<MarkReservedInstance>>;
  unmark?: Maybe<Array<UnmarkReservedInstance>>;
};


export type MutationUpdateDriftStatusesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  accept?: Maybe<Scalars['Boolean']>;
};


export type MutationRequestEstateRecordsExportArgs = {
  input: EstateRecordsExportInput;
};


export type MutationMarkNotificationsAsReadArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationAddOrgUnitV2Args = {
  input: AddOrgUnitV2;
};


export type MutationUpdateOrgUnitV2Args = {
  nid: Scalars['ID'];
  input: UpdateOrgUnitV2;
};


export type MutationRemoveOrgUnitV2Args = {
  nid: Scalars['ID'];
};


export type MutationAddOrgUnitTypeV2Args = {
  input: AddOrgUnitTypeV2;
};


export type MutationUpdateOrgUnitTypeV2Args = {
  id: Scalars['ID'];
  input: UpdateOrgUnitTypeV2;
};


export type MutationRemoveOrgUnitTypeV2Args = {
  id: Scalars['ID'];
};


export type MutationAddEnvToOrgUnitV2Args = {
  envId: Scalars['ID'];
  orgUnitId: Scalars['ID'];
};


export type MutationRemoveEnvFromOrgUnitV2Args = {
  envId: Scalars['ID'];
  orgUnitId: Scalars['ID'];
};


export type MutationAddPersonArgs = {
  input?: Maybe<AddPerson>;
};


export type MutationUpdatePersonArgs = {
  input?: Maybe<UpdatePerson>;
};


export type MutationRemovePersonArgs = {
  id: Scalars['String'];
};


export type MutationCreateAccessKeyArgs = {
  input: AccessInput;
};


export type MutationUpdateAccessKeyPermissionsArgs = {
  id: Scalars['ID'];
  input: AccessInput;
};


export type MutationRemoveAccessKeyArgs = {
  id: Scalars['ID'];
};


export type MutationSetUserPermissionsArgs = {
  email: Scalars['String'];
  input: AccessInput;
};


export type MutationRemoveUserPermissionsArgs = {
  email: Scalars['String'];
};


export type MutationTriggerScanArgs = {
  accountNids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationSetApplicationBudgetArgs = {
  nid: Scalars['ID'];
  budget: BudgetInput;
};


export type MutationSetEnvironmentBudgetArgs = {
  nid: Scalars['ID'];
  budget: BudgetInput;
};


export type MutationUnsetApplicationBudgetArgs = {
  nid: Scalars['ID'];
};


export type MutationUnsetEnvironmentBudgetArgs = {
  nid: Scalars['ID'];
};


export type MutationSetHookArgs = {
  type: HookType;
  url: Scalars['String'];
};


export type MutationRemoveHookArgs = {
  type: HookType;
};


export type MutationUpdateRiCoverageTargetArgs = {
  target?: Maybe<Scalars['Float']>;
};


export type MutationUpdateDriftsDisabledArgs = {
  input?: Maybe<UpdateDriftsDisabled>;
};


export type MutationUpdateAzureRgTagPropagationArgs = {
  input: UpdateAzureRgTagPropagation;
};


export type MutationUpdateCloudWasteSettingArgs = {
  input: UpdateCloudWasteSetting;
};

export type Application = {
  __typename?: 'Application';
  /**
   * `id` is the id from CustomerDB
   * @deprecated id and nid have been unified, and are going to be the same field, use `nid` instead
   */
  id: Scalars['String'];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars['String'];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars['String'];
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName: Scalars['String'];
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
  /**
   * `spend` is the total cost of all resources assigned to the environments of the application,
   * for current/previous month depending on `timePeriod`
   * `providers` is an optional filter to find costs only for certain set of providers
   * @deprecated use `cost`
   */
  spend?: Maybe<Scalars['String']>;
  /**
   * `cost` is the total cost of all resources assigned to the environments of the application
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /**
   * The environment field currently uses a lambda to buffer all results from
   * a paginated API, so it would be nice to stop using it and remove it
   * @deprecated use `environmentsV2` instead
   */
  environments?: Maybe<Array<Maybe<Environment>>>;
  /** A list of resources for this environment, paginated */
  environmentsV2?: Maybe<EnvironmentResponse>;
  /** Optionally the person that is the owner of the environment */
  owner?: Maybe<Person>;
  /** A list of contacts attached for this environment */
  contacts?: Maybe<Array<Maybe<Person>>>;
  /**
   * Budget that can be set for the application.
   * If the application cost reaches a certain threshold, a notification will be sent to the application owner,
   * or if the owner is not available to all ADMIN users within the customer. The threshold is currently 100%.
   */
  budget?: Maybe<Budget>;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
};


export type ApplicationSpendArgs = {
  timePeriod: TimePeriodType;
  providers?: Maybe<Array<Provider>>;
};


export type ApplicationCostArgs = {
  providers?: Maybe<Array<Provider>>;
};


export type ApplicationEnvironmentsV2Args = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type ApplicationsPaginatedResponse = {
  __typename?: 'ApplicationsPaginatedResponse';
  results?: Maybe<Array<Application>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type AddApplication = {
  name: Scalars['String'];
  /**
   * `displayName` is a string up to 256 characters long that helps to identify the application
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddApplicationV2 = {
  name: Scalars['String'];
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName: Scalars['String'];
  ownerId: Scalars['ID'];
  contactIds?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateApplication = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /**
   * `displayName` is a string up to 256 characters long that helps to identify the application
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateApplicationV2 = {
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name?: Maybe<Scalars['String']>;
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName?: Maybe<Scalars['String']>;
  /** The id of the person that is the owner of the application */
  ownerId?: Maybe<Scalars['ID']>;
  /** A list of contacts attached to this application */
  contactIds?: Maybe<Array<Scalars['ID']>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
};

export type BusinessContext = {
  __typename?: 'BusinessContext';
  /**
   * Business contexts have id, not nid, to identify them since they are not searchable
   * entities within Klarity
   */
  id: Scalars['ID'];
  /** `name` is a string up to 256 characters long that helps to identify the context */
  name: Scalars['String'];
  /** `description` is an optional text describing what the context represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
  /** `color` is a string in the form of a 6 character hex, eg #666666 */
  color?: Maybe<Scalars['String']>;
  costAllocation: CostAllocation;
  /**
   * `orgUnits` are listing ALL the organizational units that are retrieved from the backend,
   * since they are a list containing a tree like structure
   * they are returned as a 'non paginated response' but contain the total count
   */
  orgUnits?: Maybe<OrgUnitsV2NonPaginatedResponse>;
};


export type BusinessContextCostAllocationArgs = {
  providers?: Maybe<Array<Provider>>;
};

export type BusinessContextResponse = {
  __typename?: 'BusinessContextResponse';
  businessContexts?: Maybe<Array<BusinessContext>>;
  pages?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type AddBusinessContext = {
  /** `name` is a string up to 256 characters long that helps to identify the context */
  name: Scalars['String'];
  /** `description` is an optional text that describes what the context represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
  /** `color` is a string in the form of a hex, eg #666666 */
  color: Scalars['HexColorCode'];
};

export type UpdateBusinessContext = {
  /** `name` is a string up to 256 characters long that helps to identify the context */
  name?: Maybe<Scalars['String']>;
  /** `description` is an optional text that describes what the context represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
  /** `color` is a string in the form of a hex, eg #666666 */
  color?: Maybe<Scalars['HexColorCode']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  cost?: Maybe<Cost>;
};


export type OrganizationCostArgs = {
  providers?: Maybe<Array<Provider>>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['String'];
  nid: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
};

export type CloudResourcesResponse = {
  __typename?: 'CloudResourcesResponse';
  resources?: Maybe<Array<CloudResource>>;
  count: Scalars['Int'];
  pages: Scalars['Int'];
};

export type UpdateGlobalTagKeys = {
  application?: Maybe<Array<Maybe<Scalars['String']>>>;
  environment?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GlobalTagKeys = {
  __typename?: 'GlobalTagKeys';
  application: Array<Maybe<Scalars['String']>>;
  environment: Array<Maybe<Scalars['String']>>;
};

export type AddEvent = {
  nid: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  content: Scalars['String'];
};

export enum MappingRuleType {
  Account = 'ACCOUNT',
  Resourcetype = 'RESOURCETYPE',
  Tag = 'TAG',
  Resourcegroup = 'RESOURCEGROUP',
  Projectid = 'PROJECTID'
}

export type MappingRule = {
  __typename?: 'MappingRule';
  id: Scalars['String'];
  name: Scalars['String'];
  applicationId: Scalars['String'];
  environmentId: Scalars['String'];
  type?: Maybe<MappingRuleType>;
  tags?: Maybe<Array<KeyValue>>;
  cloudAccount?: Maybe<Scalars['String']>;
  resourceGroup?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};

export type AddMappingRule = {
  environmentId: Scalars['String'];
  name: Scalars['String'];
  cloudAccount?: Maybe<Scalars['String']>;
  resourceGroup?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<KeyValueInput>>;
  projectId?: Maybe<Scalars['String']>;
};

export type UpdateMappingRule = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  cloudAccount?: Maybe<Scalars['String']>;
  resourceGroup?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<KeyValueInput>>;
};

export enum RuleOperator {
  And = 'AND',
  Or = 'OR'
}

export type DiscoveryRuleMatch = {
  __typename?: 'DiscoveryRuleMatch';
  tags?: Maybe<Array<KeyValue>>;
  nid?: Maybe<Array<Scalars['String']>>;
  customerId?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Array<Scalars['String']>>;
  subtype?: Maybe<Array<Scalars['String']>>;
  providerId?: Maybe<Array<Scalars['String']>>;
  resourceId?: Maybe<Array<Scalars['String']>>;
  resourceGroup?: Maybe<Array<Scalars['String']>>;
  cloudAccount?: Maybe<Array<Scalars['String']>>;
  projectId?: Maybe<Array<Scalars['String']>>;
};

export type DiscoveryRuleMatchInput = {
  tags?: Maybe<Array<KeyValueInput>>;
  nid?: Maybe<Array<Scalars['String']>>;
  customerId?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Array<Scalars['String']>>;
  subtype?: Maybe<Array<Scalars['String']>>;
  providerId?: Maybe<Array<Scalars['String']>>;
  resourceId?: Maybe<Array<Scalars['String']>>;
  resourceGroup?: Maybe<Array<Scalars['String']>>;
  cloudAccount?: Maybe<Array<Scalars['String']>>;
  projectId?: Maybe<Array<Scalars['String']>>;
};

export type DiscoveryRule = {
  __typename?: 'DiscoveryRule';
  id: Scalars['String'];
  name: Scalars['String'];
  applicationId: Scalars['String'];
  environmentId: Scalars['String'];
  match: DiscoveryRuleMatch;
  exclusive: Scalars['Boolean'];
  tagsOperator: RuleOperator;
};

export type AddDiscoveryRule = {
  environmentId: Scalars['String'];
  name: Scalars['String'];
  match: DiscoveryRuleMatchInput;
  exclusive?: Maybe<Scalars['Boolean']>;
  tagsOperator?: Maybe<RuleOperator>;
};

export type UpdateDiscoveryRule = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  match?: Maybe<DiscoveryRuleMatchInput>;
  exclusive?: Maybe<Scalars['Boolean']>;
  tagsOperator?: Maybe<RuleOperator>;
};

export enum CostMappingAlgorithm {
  Proportional = 'PROPORTIONAL',
  Equal = 'EQUAL',
  Manual = 'MANUAL'
}

export type MonthDaily = {
  __typename?: 'MonthDaily';
  month: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type Cost = {
  __typename?: 'Cost';
  currentMonth: Scalars['String'];
  previousMonth: Scalars['String'];
  previousMonthDayToDay?: Maybe<Scalars['String']>;
  forecast?: Maybe<Scalars['String']>;
  dirty: Scalars['Boolean'];
  currentMonthDaily?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastThreeMonthsDaily?: Maybe<Array<Maybe<MonthDaily>>>;
};

export type DashboardApplicationCost = {
  __typename?: 'DashboardApplicationCost';
  nid: Scalars['String'];
  name: Scalars['String'];
  currentMonth: Scalars['String'];
  previousMonth: Scalars['String'];
  previousMonthDayToDay: Scalars['String'];
  forecast: Scalars['String'];
};

export type TopSpendingApplicationsResponse = {
  __typename?: 'TopSpendingApplicationsResponse';
  /** `topSpending` is the list of applications with the highest `currentMonth` cost */
  topSpending?: Maybe<Array<DashboardApplicationCost>>;
  /** `defaultApplication` is the application with unallocated costs */
  defaultApplication?: Maybe<DashboardApplicationCost>;
  /** `other` is the application with summed costs of all applications excluding `topSpending` and `defaultApplication` */
  other: DashboardApplicationCost;
};

export type CostCategory = {
  __typename?: 'CostCategory';
  type: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type EnvCostShare = {
  __typename?: 'EnvCostShare';
  id: Scalars['String'];
  /** Float from 0 to 1 */
  splitValue?: Maybe<Scalars['String']>;
};

export type CostSettings = {
  __typename?: 'CostSettings';
  allEnvironments?: Maybe<Scalars['Boolean']>;
  environments?: Maybe<Array<EnvCostShare>>;
};

export type CostCategoryMapping = {
  __typename?: 'CostCategoryMapping';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  algorithm?: Maybe<CostMappingAlgorithm>;
  settings?: Maybe<CostSettings>;
};

export type EnvCostShareInput = {
  id: Scalars['String'];
  /** Float from 0 to 1 */
  splitValue?: Maybe<Scalars['String']>;
};

export type CostSettingsInput = {
  allEnvironments?: Maybe<Scalars['Boolean']>;
  environments?: Maybe<Array<EnvCostShareInput>>;
};

export type CostAllocation = {
  __typename?: 'CostAllocation';
  allocated: Scalars['String'];
  unallocated: Scalars['String'];
  dirty: Scalars['Boolean'];
};

export type AddCostCategoryMapping = {
  type: Scalars['String'];
  provider: Provider;
  algorithm: CostMappingAlgorithm;
  settings: CostSettingsInput;
};

export type UpdateCostCategoryMapping = {
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  algorithm?: Maybe<CostMappingAlgorithm>;
  settings?: Maybe<CostSettingsInput>;
};

export type CostResource = {
  __typename?: 'CostResource';
  /**
   * `spendOverall` is the total cost of the resource, before it was split between environments,
   * for current/previous month depending on `timePeriod`
   * @deprecated use `cost`
   */
  spendOverall?: Maybe<Scalars['String']>;
  /** `cost` is the total cost of the resource, before it was split between environments */
  cost?: Maybe<Cost>;
  /**
   * `environments` are coupled with spend for each of the environment, this allows to see how
   * the cost of this resource has been split between environments according to splitting rules
   * @deprecated use environmentsPaginated instead
   */
  environments?: Maybe<Array<EnvironmentAndSpends>>;
  /**
   * `environmentsPaginated` same as `environments` but with pagination capabilities
   * You can use argument `envNid` to filter output result to only a selected environment.
   */
  environmentsPaginated: EnvironmentAndSpendsPaginated;
  /** ID the same as nid in Cloud Estate */
  id: Scalars['String'];
  provider: Provider;
  /** `providerType` for cost is the type of the cost, e.g. `Support` */
  providerType?: Maybe<Scalars['String']>;
  /**
   * Costs don't have name, therefore there is no `name` field
   *
   * `region` for cost is always `global`
   */
  region?: Maybe<Scalars['String']>;
  /**
   * Costs are not attached to cloudAccounts, therefore there is no `cloudAccountId` field
   *
   * MetadataAndTags contains both `metadata` and `tags` since they are both resolved
   * from the same source of data
   */
  metadataAndTags?: Maybe<MetadataAndTags>;
  /** `assignments contain counts of applications and environments returned from search */
  assignments?: Maybe<Assignments>;
};


export type CostResourceSpendOverallArgs = {
  timePeriod: TimePeriodType;
  providers?: Maybe<Array<Provider>>;
};


export type CostResourceCostArgs = {
  providers?: Maybe<Array<Provider>>;
};


export type CostResourceEnvironmentsPaginatedArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  envNid?: Maybe<Scalars['String']>;
};

export type CloudResource = {
  __typename?: 'CloudResource';
  /**
   * `spend` the total cost of this resource for the current/previous month depending on `timePeriod`
   * @deprecated use `cost`
   */
  spend?: Maybe<Scalars['String']>;
  /** `cost` is the total cost of this resource */
  cost?: Maybe<Cost>;
  /** `environment` shows what environment has this resource been attached to */
  environment?: Maybe<Environment>;
  /** ID the same as nid in Cloud Estate */
  id: Scalars['String'];
  provider: Provider;
  /** `providerId` is what the resource is identified by it's provider e.g. `arn` in AWS */
  providerId: Scalars['String'];
  /**
   * `providerType` is type/subtype of the resource concatenated with `/`
   * e.g. for an AWS Lambda function it will be `lambda/function`
   * for some resources subtype is not relevant, there providerType
   * will be just main type, e.g. `s3`
   */
  providerType?: Maybe<Scalars['String']>;
  /**
   * `name` is what the resource is named within the provider,
   * e.g. `auto-backup-scanner-lambda-function-X678Y`
   */
  name: Scalars['String'];
  /**
   * `region` is taken straight from provider data, for some resources
   * the region field may be `global`, it may also be null for some resources
   */
  region?: Maybe<Scalars['String']>;
  /**
   * ID of the cloud account, e.g. `123123123123` for AWS or subscriptionId for Azure
   * in some cases cloudAccoundId can be empty, e.g. for S3 buckets scanned from capacity
   */
  cloudAccountId?: Maybe<Scalars['String']>;
  /**
   * `metadataAndTags` contains both `metadata` and `tags` since they are both resolved
   * from the same source of data
   */
  metadataAndTags?: Maybe<MetadataAndTags>;
  /**
   * `drifted` is true, when drifter found a new assignment for this resource, when drifted
   * is false, `pendingAssignment` will be null
   */
  drifted?: Maybe<Scalars['Boolean']>;
  /**
   * `pendingAssignment` is the environment that mapper wants to assign this resource to, or in other
   * words the environment which this resource has a drift to
   * when the `updateDriftStatuses` mutation is used for this resource, the environment from `pendingAssignment`
   * will be in the `environment` field, meaning that the resource has been drifted
   */
  pendingAssignment?: Maybe<Environment>;
  /**
   * `reservation` describes the time period in which the resource is reserved
   * Can be non-null only if the CloudResource comes from the reservableResources query
   */
  reservation?: Maybe<Reservation>;
  /** `cloudWaste` describes information about Cloud Waste */
  cloudWaste?: Maybe<CloudWaste>;
};


export type CloudResourceSpendArgs = {
  timePeriod: TimePeriodType;
  providers?: Maybe<Array<Provider>>;
};


export type CloudResourceCostArgs = {
  providers?: Maybe<Array<Provider>>;
};

export type Environment = {
  __typename?: 'Environment';
  /**
   * `spend` is the total cost of all resources assigned to the environment, for current/previous
   * month depending on `timePeriod`, `providers` is an optional filter to find costs only for certain set of providers
   * @deprecated use `cost`
   */
  spend?: Maybe<Scalars['String']>;
  /**
   * `cost` of all resources assigned to the environment, `providers` is an optional filter to find costs only for certain
   * set of providers
   */
  cost?: Maybe<Cost>;
  /** @deprecated id and nid have been unified, and are going to be the same field, use `nid` instead */
  id: Scalars['String'];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars['String'];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars['String'];
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName: Scalars['String'];
  /** The application this environment is a part of */
  application: Application;
  /** Type of the environment, according to the enum */
  environmentType: EnvironmentType;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
  /** `estate` is a list of Cloud and Cost resources that belong to this environment, paginated */
  estate?: Maybe<ResourceResponse>;
  metadataAndTags?: Maybe<MetadataAndTags>;
  /** `resourceCount` is the number of resources that belong to this environment */
  resourceCount: Scalars['Int'];
  /** `orgUnitsV2` is a paginated list of all organizational units, one organizational unit per business context */
  orgUnitsV2?: Maybe<OrgUnitsV2Response>;
  /** The person that is the owner of the environment */
  owner?: Maybe<Person>;
  /** A list of contacts attached for this environment */
  contacts?: Maybe<Array<Maybe<Person>>>;
  /**
   * Budget that can be set for the environment.
   * If the environment cost reaches a certain threshold, a notification will be sent to the environment owner,
   * or if the owner is not available to all ADMIN users within the customer. The threshold is currently 100%.
   */
  budget?: Maybe<Budget>;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
};


export type EnvironmentSpendArgs = {
  timePeriod: TimePeriodType;
  providers?: Maybe<Array<Provider>>;
};


export type EnvironmentCostArgs = {
  providers?: Maybe<Array<Provider>>;
};


export type EnvironmentEstateArgs = {
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<ResourceFilter>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};


export type EnvironmentOrgUnitsV2Args = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type EnvironmentAndSpends = {
  __typename?: 'EnvironmentAndSpends';
  /** @deprecated use `cost` */
  spend?: Maybe<Scalars['String']>;
  cost?: Maybe<Cost>;
  environment?: Maybe<Environment>;
};


export type EnvironmentAndSpendsSpendArgs = {
  timePeriod: TimePeriodType;
  providers?: Maybe<Array<Provider>>;
};


export type EnvironmentAndSpendsCostArgs = {
  providers?: Maybe<Array<Provider>>;
};

export type OrgUnitV2 = {
  __typename?: 'OrgUnitV2';
  /**
   * `cost` is the total cost of all resources assigned to the environments of the org unit,
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /** A paginated list of environments assigned to this org unit */
  environments?: Maybe<EnvironmentResponse>;
  /**
   * Org units are differentiated by Nordcloud ID, a unique identifier which is present for
   * every searchable entity in Klarity
   */
  nid: Scalars['ID'];
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name: Scalars['String'];
  /** `description` is an optional text that helps identify what the unit represents */
  description?: Maybe<Scalars['String']>;
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: Maybe<Scalars['ID']>;
  /** The context that the OrgUnit belongs to */
  businessContext: BusinessContext;
  /** Each org unit can have a type assigned, which works as a label for the org unit */
  type?: Maybe<OrgUnitTypeV2>;
  /** The contact person for this org unit */
  contact?: Maybe<Person>;
};


export type OrgUnitV2CostArgs = {
  providers?: Maybe<Array<Provider>>;
};


export type OrgUnitV2EnvironmentsArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type OrgUnitWithChildren = {
  __typename?: 'OrgUnitWithChildren';
  /**
   * `cost` is the total cost of all resources assigned to the environments of the org unit,
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /** A paginated list of environments assigned to this org unit */
  environments?: Maybe<EnvironmentResponse>;
  /**
   * Org units are differentiated by Nordcloud ID, a unique identifier which is present for
   * every searchable entity in Klarity
   */
  nid: Scalars['ID'];
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name: Scalars['String'];
  /** `description` is an optional text that helps identify what the unit represents */
  description?: Maybe<Scalars['String']>;
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: Maybe<Scalars['ID']>;
  /** All child org units */
  children?: Maybe<Array<OrgUnitWithChildren>>;
  /** The context that the OrgUnit belongs to */
  businessContext: BusinessContext;
  /** Each org unit can have a type assigned, which works as a label for the org unit */
  type?: Maybe<OrgUnitTypeV2>;
  /** The contact person for this org unit */
  contact?: Maybe<Person>;
};


export type OrgUnitWithChildrenCostArgs = {
  providers?: Maybe<Array<Provider>>;
};


export type OrgUnitWithChildrenEnvironmentsArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  dailyUtilization?: Maybe<ReservationUtilization>;
  monthlyUtilization?: Maybe<ReservationUtilization>;
  reservationId: Scalars['String'];
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
  instanceType?: Maybe<Scalars['String']>;
  purchaseOption?: Maybe<Scalars['String']>;
  paymentOption?: Maybe<Scalars['String']>;
  upfrontCost?: Maybe<Scalars['String']>;
  monthlyCost?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  region?: Maybe<Scalars['String']>;
  cloudAccount?: Maybe<Scalars['String']>;
};

export type ReservationUtilization = {
  __typename?: 'ReservationUtilization';
  utilization: Scalars['String'];
  benefit: Scalars['String'];
  forecast?: Maybe<Scalars['String']>;
  timeSeries?: Maybe<Array<Maybe<TimeSeries>>>;
};

export type TimeSeries = {
  __typename?: 'TimeSeries';
  date: Scalars['String'];
  value: Scalars['String'];
  benefit: Scalars['String'];
};

export type ReservationsGlobalUtilisation = {
  __typename?: 'ReservationsGlobalUtilisation';
  totalReservationCost?: Maybe<Scalars['String']>;
  totalOnDemandCostEquivalent?: Maybe<Scalars['String']>;
  totalBenefit?: Maybe<Scalars['String']>;
  timeSeries?: Maybe<Array<Maybe<TimeSeries>>>;
};

export type ReservationsGlobalCoverage = {
  __typename?: 'ReservationsGlobalCoverage';
  averageCoverage?: Maybe<Scalars['String']>;
  totalCoveredHours?: Maybe<Scalars['String']>;
  totalOnDemandHours?: Maybe<Scalars['String']>;
  totalOnDemandCost?: Maybe<Scalars['String']>;
  timeSeries?: Maybe<Array<Maybe<TimePoint>>>;
};

export type TimePoint = {
  __typename?: 'TimePoint';
  date: Scalars['String'];
  value: Scalars['String'];
};

export enum EnvironmentType {
  Production = 'PRODUCTION',
  Staging = 'STAGING',
  Development = 'DEVELOPMENT',
  Other = 'OTHER'
}

export type AddEnvironment = {
  /**
   * The NID of the application this new environment will be assigned to
   * each environment must be assigned to an application
   */
  applicationId: Scalars['String'];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars['String'];
  /**
   * `displayNam`e is a string up to 256 characters long that helps to identify the environment
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: Maybe<Scalars['String']>;
  /** This field was never used */
  estateId?: Maybe<Array<Scalars['String']>>;
  /** The type of the environment */
  type: EnvironmentType;
  /** The id of the person that is the owner of the environment */
  ownerId: Scalars['String'];
  /** A list of contacts attached for this environment */
  contactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
};

export type AddEnvironmentV2 = {
  /**
   * The NID of the application this new environment will be assigned to
   * each environment must be assigned to an application
   */
  applicationNid: Scalars['ID'];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars['String'];
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName: Scalars['String'];
  /** The type of the environment */
  type: EnvironmentType;
  /** The id of the person that is the owner of the environment */
  ownerId: Scalars['ID'];
  /** A list of contacts attached for this environment */
  contactIds?: Maybe<Array<Scalars['ID']>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
};

export type UpdateEnvironment = {
  /**
   * `id` is equal to `nid` in Environment right now
   * we want to use `nid` everywhere
   * for now they are kept as a backward compability issue, but `nid` should be used whenever possible
   */
  id: Scalars['String'];
  applicationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /**
   * `displayName` is a string up to 256 characters long that helps to identify the environment
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: Maybe<Scalars['String']>;
  /** This field was never used */
  estateId?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<EnvironmentType>;
  ownerId?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
};

export type UpdateEnvironmentV2 = {
  /**
   * The NID of the application this new environment will be assigned to
   * each environment must be assigned to an application
   */
  applicationNid?: Maybe<Scalars['ID']>;
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name?: Maybe<Scalars['String']>;
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName?: Maybe<Scalars['String']>;
  /** The type of the environment */
  type?: Maybe<EnvironmentType>;
  /** The id of the person that is the owner of the environment */
  ownerId?: Maybe<Scalars['ID']>;
  /** Optionally a list of contacts can be attached to the environment */
  contactIds?: Maybe<Array<Scalars['ID']>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars['String']>;
};

export type EnvironmentAndSpendsPaginated = {
  __typename?: 'EnvironmentAndSpendsPaginated';
  environments?: Maybe<Array<EnvironmentAndSpends>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type EnvironmentResponse = {
  __typename?: 'EnvironmentResponse';
  environments?: Maybe<Array<Environment>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type Resource = CostResource | CloudResource;

export type Assignments = {
  __typename?: 'Assignments';
  appCount: Scalars['Int'];
  envCount: Scalars['Int'];
};

export type ResourceResponse = {
  __typename?: 'ResourceResponse';
  resources?: Maybe<Array<Resource>>;
  cost?: Maybe<ResourcesCost>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

/** ResourcesCost represents a cost for a set of resources. */
export type ResourcesCost = {
  __typename?: 'ResourcesCost';
  /** A sum of all costs for resources in the current period. */
  currentMonthTotal: Scalars['String'];
};

export type MultipleResourceFilter = {
  /**
   * The filters for `unassigned`, `environment`, `application` are exclusive,
   * and they are resolved in order unassigned > environment > application
   * so if all are specified, only one is going to work along with that ordering
   *
   *
   * Return all resources assigned to application `default` and environment `default`
   */
  unassigned?: Maybe<Scalars['Boolean']>;
  /** Return all resources assigned to given environment */
  environment?: Maybe<Scalars['String']>;
  /** Return all resources assigned to enviromnents that belong to given application */
  application?: Maybe<Scalars['String']>;
  /** Return only resources with specified type */
  type?: Maybe<Array<Scalars['String']>>;
  /** Return only resources with specified subtype */
  subtype?: Maybe<Array<Scalars['String']>>;
  /** Return all resources belonging to an account */
  account?: Maybe<Array<Scalars['String']>>;
  /** Return all resources from a particular provider */
  provider?: Maybe<Array<Provider>>;
  /** Return all resources from a given region */
  region?: Maybe<Array<Scalars['String']>>;
};

export type EstateResourceFilter = {
  /** Return all resources belonging to an account */
  cloudAccount?: Maybe<Array<Scalars['String']>>;
  /** Return all resources belonging to a Azure resource group */
  resourceGroup?: Maybe<Array<Scalars['String']>>;
  /** Return all resources belonging to a GCP project ID */
  projectId?: Maybe<Array<Scalars['String']>>;
  /** Return all resources belonging to a specific tags */
  tags?: Maybe<Array<KeyValueInput>>;
  /** Specify which logical operator should be used to concatenate tags */
  tagsOperator?: Maybe<RuleOperator>;
  /** Return all resources with specified type */
  type?: Maybe<Array<Scalars['String']>>;
  /** Return all resources with specified subtype */
  subtype?: Maybe<Array<Scalars['String']>>;
};

export type ResourceFilter = {
  /** Return only resources with specified type */
  type?: Maybe<Scalars['String']>;
  /**
   * The filters for `unassigned`, `environment`, `application` are exclusive,
   * and they are resolved in order unassigned > environment > application
   * so if all are specified, only one is going to work along with that ordering
   *
   *
   * Return all resources assigned to application `default` and environment `default`
   */
  unassigned?: Maybe<Scalars['Boolean']>;
  /** Return all resources assigned to given environment */
  environment?: Maybe<Scalars['String']>;
  /** Return all resources assigned to enviromnents that belong to given application */
  application?: Maybe<Scalars['String']>;
  /** Return all resources that have an active/unresolved drift assignment */
  drifted?: Maybe<Scalars['Boolean']>;
  /** Return all resources belonging to an account */
  account?: Maybe<Scalars['String']>;
  /** Return all resources from a particular provider */
  provider?: Maybe<Provider>;
  /** Return all resources from a given region */
  region?: Maybe<Scalars['String']>;
  /** Return all resources which are marked as cloud waste */
  waste?: Maybe<Scalars['Boolean']>;
};

export enum SearchType {
  Account = 'ACCOUNT',
  Application = 'APPLICATION',
  Environment = 'ENVIRONMENT',
  OrgUnit = 'ORG_UNIT',
  Cost = 'COST',
  Resource = 'RESOURCE'
}

export type SearchResult = CloudResource | CostResource | Application | Environment | Account | OrgUnitV2;

export type SearchFilter = {
  /**
   * `type` is the field that separates different entities in search, while category should
   * only be used to discriminate between different types of cloud resources
   * if no `type` is provided, search will be performed on all entities
   *
   * Disclaimer: those filters don't work when the query phrase is an empty string, since
   * there is a huge cutoff that allows to optimise the search
   */
  type?: Maybe<SearchType>;
  /**
   * `category` is the type of the resource, meaning `ec2` or `lambda`, etc.
   * this only works for cloud resources
   */
  category?: Maybe<Array<Scalars['String']>>;
  /** `region` is straightforward, e.g. `eu-west-1` */
  region?: Maybe<Array<Scalars['String']>>;
  /**
   * the `environment` and `application` fields are exclusive, and they are resolved
   * in order: `environment` > `application`, so if both are specified, only the first
   * is going to have impact on the results
   *
   * The NID of the environment, which is the `nid` field in the Environment type
   */
  environment?: Maybe<Array<Scalars['String']>>;
  /** The CustomerDB ID of the application, which is the `id` field in the Application type */
  application?: Maybe<Array<Scalars['String']>>;
  /**
   * The provider ID of the CloudAccount, which is the `providerId` field in the Account type,
   * e.g. `123123123123` for AWS accounts or `272a852e-3468-4372-b4fa-6ae68bce3bff` for AZURE subscriptions
   */
  account?: Maybe<Array<Scalars['String']>>;
  /** `provider` is straightforward */
  provider?: Maybe<Array<Provider>>;
  /** Filter records to the ones which are marked as cloud waste */
  waste?: Maybe<Scalars['Boolean']>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  results?: Maybe<Array<SearchResult>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type MarkReservedInstance = {
  nid: Scalars['String'];
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};

export type UnmarkReservedInstance = {
  nid: Scalars['String'];
};

export type ReservationsPaginated = {
  __typename?: 'ReservationsPaginated';
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  reservations?: Maybe<Array<Reservation>>;
};

export type ReservationCoveredInstance = {
  __typename?: 'ReservationCoveredInstance';
  id?: Maybe<Scalars['String']>;
  nid: Scalars['String'];
  provider?: Maybe<Provider>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  benefit?: Maybe<Scalars['String']>;
};

export type ReservationCoveredInstancesPaginated = {
  __typename?: 'ReservationCoveredInstancesPaginated';
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  instances?: Maybe<Array<ReservationCoveredInstance>>;
};

export type ReservationCoveredByType = {
  __typename?: 'ReservationCoveredByType';
  instanceType?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  averageCoverage?: Maybe<Scalars['String']>;
  totalCoveredHours?: Maybe<Scalars['String']>;
  totalOnDemandHours?: Maybe<Scalars['String']>;
  totalOnDemandCost?: Maybe<Scalars['String']>;
};

export type ReservationRecommendation = {
  __typename?: 'ReservationRecommendation';
  numberOfInstances?: Maybe<Scalars['Int']>;
  termInYears?: Maybe<Scalars['Int']>;
  instanceType?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  monthlySavings?: Maybe<Scalars['String']>;
  monthlySavingsPercentage?: Maybe<Scalars['String']>;
  upfrontCost?: Maybe<Scalars['String']>;
  recurringMonthlyCost?: Maybe<Scalars['String']>;
  expectedUtilisation?: Maybe<Scalars['String']>;
};

export type ReservationsRecommendationsPaginated = {
  __typename?: 'ReservationsRecommendationsPaginated';
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  recommendations?: Maybe<Array<ReservationRecommendation>>;
};

export type ReservationCoveredByTypeInstancesPaginated = {
  __typename?: 'ReservationCoveredByTypeInstancesPaginated';
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  resources?: Maybe<Array<ReservationCoveredByType>>;
};

export type EstateRecordsExportInput = {
  /** Email address that will be used to notify about export completion */
  notificationEmail: Scalars['String'];
  /**
   * Url of the frontend view for which the export has been requested
   * it should include all the filters
   */
  viewUrl: Scalars['String'];
  /** The same field as in `Query.estate()` */
  query?: Maybe<Scalars['String']>;
  /** The same field as in `Query.estate()` */
  filter?: Maybe<ResourceFilter>;
  /** Filter records to the ones which are marked as cloud waste */
  waste?: Maybe<Scalars['Boolean']>;
};

export type RequestExportResponse = {
  __typename?: 'RequestExportResponse';
  exportJobId: Scalars['ID'];
};

export type DownloadExportResponse = {
  __typename?: 'DownloadExportResponse';
  fileUrl: Scalars['String'];
};

export enum NotificationType {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['String'];
  type: NotificationType;
  title: Scalars['String'];
  details: Scalars['String'];
  targetView?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  notifications?: Maybe<Array<Notification>>;
  cursor?: Maybe<Scalars['String']>;
};

export type NotificationsFilter = {
  unread?: Maybe<Scalars['Boolean']>;
};

export type OrgUnitTypeV2 = {
  __typename?: 'OrgUnitTypeV2';
  /** Org unit types have id, not nid since they are not searchable entities */
  id: Scalars['ID'];
  /** `name` is a string up to 256 characters long that describes the type */
  name: Scalars['String'];
  /** `description` is an optional text that helps identify what the type represents */
  description?: Maybe<Scalars['String']>;
};

export type OrgUnitsV2Response = {
  __typename?: 'OrgUnitsV2Response';
  orgUnits?: Maybe<Array<OrgUnitV2>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type OrgUnitsV2NonPaginatedResponse = {
  __typename?: 'OrgUnitsV2NonPaginatedResponse';
  orgUnits?: Maybe<Array<OrgUnitV2>>;
  count?: Maybe<Scalars['Int']>;
};

export type OrgUnitTypesV2Response = {
  __typename?: 'OrgUnitTypesV2Response';
  types?: Maybe<Array<OrgUnitTypeV2>>;
  count?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
};

export type AddOrgUnitV2 = {
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name: Scalars['String'];
  /** `context` is the ID of the business context this org unit is in */
  contextId: Scalars['ID'];
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: Maybe<Scalars['ID']>;
  /** `typeId` is optional id of the OrgUnitTypeV2 that can be assigned to the OrgUnit */
  typeId?: Maybe<Scalars['ID']>;
  /** `description` is an optional text that helps identify what the unit represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
  /** `contactId` is optional id of the contact person that should be assigned to the OrgUnit */
  contactId?: Maybe<Scalars['ID']>;
};

export type UpdateOrgUnitV2 = {
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name?: Maybe<Scalars['String']>;
  /** `contextId` is the ID of the business context this org unit is in */
  contextId?: Maybe<Scalars['ID']>;
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: Maybe<Scalars['ID']>;
  /** `typeId` is optional id of the OrgUnitTypeV2 that can be assigned to the OrgUnit */
  typeId?: Maybe<Scalars['ID']>;
  /** `description` is an optional text that helps identify what the unit represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
  /** `contactId` is optional id of the contact person that should be assigned to the OrgUnit */
  contactId?: Maybe<Scalars['ID']>;
};

export type AddOrgUnitTypeV2 = {
  /** `name` is a string up to 256 characters long helps identify the type */
  name: Scalars['String'];
  /** `description` is an optional text that helps identify what the type represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
};

export type UpdateOrgUnitTypeV2 = {
  /** `name` is a string up to 256 characters long helps identify the type */
  name?: Maybe<Scalars['String']>;
  /** `description` is an optional text that helps identify what the type represents, and it's purpose */
  description?: Maybe<Scalars['String']>;
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type AddPerson = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type UpdatePerson = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AccessInput = {
  role: Role;
  applications?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
};

export type AccessKeyPermissions = {
  __typename?: 'AccessKeyPermissions';
  id: Scalars['ID'];
  access: Access;
  description?: Maybe<Scalars['String']>;
};

export type AccessKeyPermissionsWithSecret = {
  __typename?: 'AccessKeyPermissionsWithSecret';
  id: Scalars['ID'];
  secret: Scalars['String'];
  access: Access;
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  email: Scalars['String'];
  access: Access;
};

export type ApplicationName = {
  __typename?: 'ApplicationName';
  nid: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type Access = {
  __typename?: 'Access';
  role: Role;
  /** This field is only defined if the role of the user/access key is `TEAM_LEAD` */
  applications?: Maybe<Array<Scalars['String']>>;
  /** This field is only defined if the role of the user/access key is `TEAM_LEAD` */
  applicationsWithNames?: Maybe<Array<ApplicationName>>;
};

export type AccessKeysPermissionsResponse = {
  __typename?: 'AccessKeysPermissionsResponse';
  keyPermissions?: Maybe<Array<AccessKeyPermissions>>;
  pages?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type UsersPermissionsResponse = {
  __typename?: 'UsersPermissionsResponse';
  usersPermissions?: Maybe<Array<UserPermissions>>;
  pages?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

/** Currencies which are available in the budgets API. */
export enum Currency {
  Eur = 'EUR',
  Usd = 'USD',
  Cad = 'CAD',
  Aed = 'AED',
  Aud = 'AUD',
  Bgn = 'BGN',
  Brl = 'BRL',
  Chf = 'CHF',
  Cny = 'CNY',
  Czk = 'CZK',
  Dkk = 'DKK',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jpy = 'JPY',
  Krw = 'KRW',
  Kwd = 'KWD',
  Mad = 'MAD',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Php = 'PHP',
  Pln = 'PLN',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Tnd = 'TND',
  Try = 'TRY',
  Zar = 'ZAR'
}

export type Budget = {
  __typename?: 'Budget';
  currency: Currency;
  amount: Scalars['UnsignedFloat'];
};

export type BudgetInput = {
  currency: Currency;
  amount: Scalars['UnsignedFloat'];
};

export enum RecalculationStatus {
  Running = 'RUNNING'
}

export type Dirtiness = {
  __typename?: 'Dirtiness';
  isDirty: Scalars['Boolean'];
};

export type Recalculation = {
  __typename?: 'Recalculation';
  status: RecalculationStatus;
};

export enum ReportStatus {
  Scheduled = 'SCHEDULED',
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED',
  Error = 'ERROR'
}

export enum ReportType {
  MonthlyReportFull = 'MONTHLY_REPORT_FULL',
  MonthlyReportFullWithoutMetadata = 'MONTHLY_REPORT_FULL_WITHOUT_METADATA',
  MonthlyReportStripped = 'MONTHLY_REPORT_STRIPPED',
  MonthlyReportStrippedWithoutMetadata = 'MONTHLY_REPORT_STRIPPED_WITHOUT_METADATA',
  MonthlyReportBc = 'MONTHLY_REPORT_BC',
  MonthlyReportBcWithoutMetadata = 'MONTHLY_REPORT_BC_WITHOUT_METADATA'
}

export type MonthlyReportInput = {
  /** Report period in YYYY-MM format. Repors are available for N-x months where x >= 2 */
  period: Scalars['String'];
  /** If report tyle is MONTHLY_REPORT_BC, businessContextId field should be provided */
  reportType: ReportType;
  businessContextId?: Maybe<Scalars['String']>;
};

export type MonthlyReportResponse = {
  __typename?: 'MonthlyReportResponse';
  /**
   * If report status is SCHEDULED or IN_PROGRESS - report was not yet prepared
   * If report status is FINISHED `fileUrl` field contains presigned URL to download report
   * If report status is ERROR `error` field contains error message
   */
  status: ReportStatus;
  fileUrl?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  reportType: ReportType;
};

export enum HookType {
  MappingFinalized = 'MAPPING_FINALIZED'
}

export type Hook = {
  __typename?: 'Hook';
  type: HookType;
  url: Scalars['String'];
  lastInvokedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** SearchTagKeysResponse represents response from searching tag keys. */
export type SearchTagKeysResponse = {
  __typename?: 'SearchTagKeysResponse';
  /** Keys are tag keys returned from search. */
  keys: Array<Scalars['String']>;
  /** Next is an optional cursor for pagination, that should be passed in `after` field. */
  next?: Maybe<Scalars['String']>;
};

/** SearchTagValuesResponse represents response from searching tag values. */
export type SearchTagValuesResponse = {
  __typename?: 'SearchTagValuesResponse';
  /** Values are tag values returned from search. */
  values: Array<Scalars['String']>;
  /** Next is an optional cursor for pagination, that should be passed in `after` field. */
  next?: Maybe<Scalars['String']>;
};

export type CustomerConfig = {
  __typename?: 'CustomerConfig';
  /** RI enabled represents reserved instances feature */
  riEnabled?: Maybe<Scalars['Boolean']>;
  /** Experimental features enabled for customer */
  allowExperimental?: Maybe<Scalars['Boolean']>;
  /** Features enabled for the customer */
  enabledFeatures?: Maybe<Array<Scalars['String']>>;
  /** Default currency for the customer */
  defaultCurrency?: Maybe<Scalars['String']>;
  /** RI coverage target for the customer */
  riCoverageTarget?: Maybe<Scalars['Float']>;
  /** Determines whether estate record drifts are disabled */
  driftsDisabled?: Maybe<Scalars['Boolean']>;
  /** Indicates whether Azure Resource Group tags will be retrieved and mapped to resources */
  azureRGTagPropagation?: Maybe<Scalars['Boolean']>;
};

export type UpdateDriftsDisabled = {
  disabled: Scalars['Boolean'];
};

export type UpdateAzureRgTagPropagation = {
  enabled: Scalars['Boolean'];
};

export type CloudWaste = {
  __typename?: 'CloudWaste';
  /** `isWaste` describes whether the resource was marked as a waste. */
  isWaste: Scalars['Boolean'];
  /** `reason` describes reason why the resource was marked as a waste. */
  reason?: Maybe<Scalars['String']>;
};

export type CloudWasteAggregate = {
  __typename?: 'CloudWasteAggregate';
  /** `hasWaste` describes whether entity contains cloud waste records */
  hasWaste?: Maybe<Scalars['Boolean']>;
  /** `cost` describes the total sum of spends that are marked as cloud waste inside entity */
  cost?: Maybe<Scalars['String']>;
  /** `count` describes the number of cloud waste records inside entity */
  count?: Maybe<Scalars['Int']>;
};

export enum CloudWasteSettingKey {
  StoppedVm = 'STOPPED_VM',
  UnattachedVolume = 'UNATTACHED_VOLUME',
  UnassignedIp = 'UNASSIGNED_IP',
  UnassignedSecurityGroup = 'UNASSIGNED_SECURITY_GROUP',
  OutdatedSnapshot = 'OUTDATED_SNAPSHOT'
}

export enum CloudWasteSettingStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export enum CloudWasteSettingParameterId {
  Days = 'DAYS'
}

export type CloudWasteSettingParameter = CloudWasteSettingParameterInt;

export type CloudWasteSettingParameterInt = {
  __typename?: 'CloudWasteSettingParameterInt';
  id: CloudWasteSettingParameterId;
  description: Scalars['String'];
  value: Scalars['Int'];
  min: Scalars['Int'];
  max: Scalars['Int'];
};

export type CloudWasteSetting = {
  __typename?: 'CloudWasteSetting';
  id: CloudWasteSettingKey;
  name: Scalars['String'];
  description: Scalars['String'];
  status: CloudWasteSettingStatus;
  parameters: Array<CloudWasteSettingParameter>;
};

export type UpdateCloudWasteSettingParameter = {
  id: CloudWasteSettingParameterId;
  /**
   * `value` is typed as String to be universal for both number and text input,
   * union types for graphql input are not supported
   */
  value: Scalars['String'];
};

export type UpdateCloudWasteSetting = {
  id: CloudWasteSettingKey;
  status: CloudWasteSettingStatus;
  parameters?: Maybe<Array<UpdateCloudWasteSettingParameter>>;
};

export type CloudWastePolicySummary = {
  __typename?: 'CloudWastePolicySummary';
  id?: Maybe<CloudWasteSettingKey>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type CloudWasteSummaryResponse = {
  __typename?: 'CloudWasteSummaryResponse';
  /** `policies` describes the basic information and cost of cloud waste policies */
  policies?: Maybe<Array<Maybe<CloudWastePolicySummary>>>;
  /** `totalCost` describes summarized cloud waste cost of all queried policies */
  totalCost?: Maybe<Scalars['String']>;
};

/** Search sorting order is used to differentiate between ascending 'ASC' and descending 'DSC' orders. */
export enum SearchSortOrder {
  Asc = 'ASC',
  Dsc = 'DSC'
}

/** Search sorting fields denominates the field by which result data will be ordered. */
export enum SearchSortField {
  /** Sort by monthly cost */
  Cost = 'COST',
  /**
   * Sort by monthly cost forecast. This is currently identical to sorting by cost, since forecast is linear but might
   * change in the future.
   */
  Forecast = 'FORECAST',
  /**
   * Sort by the type and subtype fields. The sorting is done as shown on frontend, so alphanumerically with type joined
   * with subtype by a '/', e.g. 'ec2/instance' is before 'ec2/volume' in ASC order.
   */
  Type = 'TYPE',
  /** Sort by resource region, including resources that are in the 'global' region */
  Region = 'REGION',
  /** Sort by the ID that providers give to resources. */
  ProviderId = 'PROVIDER_ID',
  /** Sort by Klarity ID or NID */
  Nid = 'NID'
}

/**
 * Input for sorting.
 *
 * Only one field/order combination can be selected at a time, and both must be selected (e.g. sort by TYPE/SUBTYPE
 * descending).
 */
export type SearchSort = {
  field: SearchSortField;
  order: SearchSortOrder;
};

/** Input for filtering data. */
export type EstateV2Filter = {
  /** Return all resources from a particular provider */
  provider?: Maybe<Array<Provider>>;
  /** Return all resources above given cost */
  costFrom?: Maybe<Scalars['UnsignedFloat']>;
  /** Return all resources below given cost */
  costTo?: Maybe<Scalars['UnsignedFloat']>;
  /**
   * Return all resources assigned to application `default` and environment `default`.
   * `unassigned` can not be used with `application` or `environment` filters
   */
  unassigned?: Maybe<Scalars['Boolean']>;
  /** Return all resources which are marked as cloud waste */
  waste?: Maybe<Scalars['Boolean']>;
  /** Return all resources which belong to given waste policy */
  wastePolicy?: Maybe<Array<Scalars['String']>>;
  /** Return all resources belonging to an account */
  account?: Maybe<Array<Scalars['String']>>;
  /** Return all resources assigned to enviromnents that belong to given applications list */
  application?: Maybe<Array<Scalars['String']>>;
  /** Return all resources assigned to given environments list */
  environment?: Maybe<Array<Scalars['String']>>;
  /** Return all resources from a given regions list */
  region?: Maybe<Array<Scalars['String']>>;
  /** Return all resources belonging to a specific tags */
  tags?: Maybe<Array<KeyValuesInput>>;
  /** Return all resources with specified type */
  type?: Maybe<Array<Scalars['String']>>;
  /** Return all resources with specified subtype */
  subtype?: Maybe<Array<Scalars['String']>>;
  /** Return all resources with specified type/subtype combination */
  typeSubtype?: Maybe<Array<Scalars['String']>>;
};

export type EventsQueryVariables = Exact<{
  from: Scalars['AWSDateTime'];
  nid: Scalars['String'];
  to: Scalars['AWSDateTime'];
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'title' | 'type' | 'content' | 'createdAt'>
  )>> }
);

export type GetTagsByKeyQueryVariables = Exact<{
  key: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetTagsByKeyQuery = (
  { __typename?: 'Query' }
  & { searchTagKeys?: Maybe<(
    { __typename?: 'SearchTagKeysResponse' }
    & Pick<SearchTagKeysResponse, 'keys' | 'next'>
  )> }
);

export type GetTagsByValueQueryVariables = Exact<{
  key: Scalars['String'];
  value: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetTagsByValueQuery = (
  { __typename?: 'Query' }
  & { searchTagValues?: Maybe<(
    { __typename?: 'SearchTagValuesResponse' }
    & Pick<SearchTagValuesResponse, 'values' | 'next'>
  )> }
);

export type EstateResourceCountQueryVariables = Exact<{
  filter?: Maybe<EstateResourceFilter>;
}>;


export type EstateResourceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'estateResourceCount'>
);

export type GoogleCloudProjectsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type GoogleCloudProjectsQuery = (
  { __typename?: 'Query' }
  & { estate?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count'>
    & { resources?: Maybe<Array<{ __typename?: 'CostResource' } | (
      { __typename?: 'CloudResource' }
      & Pick<CloudResource, 'id' | 'name'>
    )>> }
  )> }
);

export type GetAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'provider' | 'name' | 'id' | 'providerId' | 'activated'>
  )>>> }
);

export type GetApplicationsByNameQueryVariables = Exact<{
  phrase: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type GetApplicationsByNameQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResponse' }
    & { results?: Maybe<Array<{ __typename: 'CloudResource' } | { __typename: 'CostResource' } | (
      { __typename: 'Application' }
      & Pick<Application, 'id' | 'name'>
    ) | { __typename: 'Environment' } | { __typename: 'Account' } | { __typename: 'OrgUnitV2' }>> }
  )> }
);

export type GetEnvironmentsFromAppQueryVariables = Exact<{
  appId: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type GetEnvironmentsFromAppQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { environmentsV2?: Maybe<(
      { __typename?: 'EnvironmentResponse' }
      & { environments?: Maybe<Array<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'id' | 'nid' | 'name'>
      )>> }
    )> }
  )> }
);

export type GetApplicationNameQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetApplicationNameQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'name'>
  )> }
);

export type NotificationListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
}>;


export type NotificationListQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationsResponse' }
    & Pick<NotificationsResponse, 'cursor'>
    & { notifications?: Maybe<Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'type' | 'title' | 'details' | 'targetView' | 'read' | 'createdAt'>
    )>> }
  )> }
);

export type MarkNotificationsAsReadMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type MarkNotificationsAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markNotificationsAsRead'>
);

export type TriggerRecalculationMutationVariables = Exact<{ [key: string]: never; }>;


export type TriggerRecalculationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'triggerRecalculation'>
);

export type GetCustomerDirtinessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerDirtinessQuery = (
  { __typename?: 'Query' }
  & { customerDirtiness: (
    { __typename?: 'Dirtiness' }
    & Pick<Dirtiness, 'isDirty'>
  ) }
);

export type GetRecalculationStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecalculationStatusQuery = (
  { __typename?: 'Query' }
  & { recalculations: Array<Maybe<(
    { __typename?: 'Recalculation' }
    & Pick<Recalculation, 'status'>
  )>> }
);

export type SearchQueryVariables = Exact<{
  phrase: Scalars['String'];
  filter?: Maybe<SearchFilter>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResponse' }
    & Pick<SearchResponse, 'count' | 'pages'>
    & { results?: Maybe<Array<(
      { __typename: 'CloudResource' }
      & Pick<CloudResource, 'id' | 'name' | 'providerType' | 'cloudAccountId' | 'provider'>
      & { cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'forecast'>
      )>, cloudWaste?: Maybe<(
        { __typename?: 'CloudWaste' }
        & Pick<CloudWaste, 'isWaste' | 'reason'>
      )> }
    ) | (
      { __typename: 'CostResource' }
      & Pick<CostResource, 'id' | 'providerType' | 'provider'>
    ) | (
      { __typename: 'Application' }
      & Pick<Application, 'id' | 'name'>
    ) | (
      { __typename: 'Environment' }
      & Pick<Environment, 'id' | 'name'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'id' | 'name'>
      ) }
    ) | (
      { __typename: 'Account' }
      & Pick<Account, 'id' | 'name' | 'provider'>
    ) | (
      { __typename: 'OrgUnitV2' }
      & Pick<OrgUnitV2, 'nid' | 'name'>
      & { businessContext: (
        { __typename?: 'BusinessContext' }
        & Pick<BusinessContext, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type CustomerConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerConfigQuery = (
  { __typename?: 'Query' }
  & { customerConfig?: Maybe<(
    { __typename?: 'CustomerConfig' }
    & Pick<CustomerConfig, 'riEnabled' | 'allowExperimental' | 'defaultCurrency' | 'riCoverageTarget' | 'enabledFeatures'>
  )> }
);

export type ResourceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ResourceTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resourceTypes'>
);

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'provider' | 'providerId' | 'activated' | 'description' | 'name'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'name'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>>> }
  )>>> }
);

export type AccountQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'provider' | 'providerId' | 'activated' | 'name' | 'description'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>>> }
  )> }
);

export type AccountV2QueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AccountV2Query = (
  { __typename?: 'Query' }
  & { accountV2?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'provider' | 'status' | 'providerId' | 'providerType' | 'description' | 'activated' | 'metadata'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )> }
  )> }
);

export type AddAccountV2MutationVariables = Exact<{
  input: AddAccountV2;
}>;


export type AddAccountV2Mutation = (
  { __typename?: 'Mutation' }
  & { addAccountV2?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccount'>
);

export type UpdateAccountV2MutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountV2'>
);

export type RemoveAccountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAccount'>
);

export type QueryAccountsV2CountsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryAccountsV2CountsQuery = (
  { __typename?: 'Query' }
  & { AWS?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )>, GCP?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )>, AZURE?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )>, VMWARE?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )>, IBMCLOUD?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )>, KUBERNETES?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )>, OPENSHIFT?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
  )> }
);

export type QueryAccountsV2ByPageAndProviderQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  filter?: Maybe<AccountsFilter>;
}>;


export type QueryAccountsV2ByPageAndProviderQuery = (
  { __typename?: 'Query' }
  & { accountsV2?: Maybe<(
    { __typename?: 'AccountsResponse' }
    & Pick<AccountsResponse, 'count'>
    & { accounts?: Maybe<Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'provider' | 'providerId' | 'providerType' | 'activated' | 'status' | 'description' | 'name'>
      & { owner?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'name'>
      )>, contacts?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'name'>
      )>>> }
    )>> }
  )> }
);

export type ApplicationFormDataQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApplicationFormDataQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'nid'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>, environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'nid' | 'name' | 'environmentType' | 'description'>
      & { budget?: Maybe<(
        { __typename?: 'Budget' }
        & Pick<Budget, 'currency' | 'amount'>
      )>, contacts?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id'>
      )>>> }
    )>>> }
  )> }
);

export type ApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'name'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'forecast' | 'currentMonth' | 'dirty'>
    )>, environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'id' | 'name' | 'description' | 'resourceCount'>
      & { cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'forecast' | 'currentMonth' | 'dirty'>
      )>, budget?: Maybe<(
        { __typename?: 'Budget' }
        & Pick<Budget, 'amount' | 'currency'>
      )>, orgUnitsV2?: Maybe<(
        { __typename?: 'OrgUnitsV2Response' }
        & { orgUnits?: Maybe<Array<(
          { __typename?: 'OrgUnitV2' }
          & Pick<OrgUnitV2, 'nid' | 'name'>
        )>> }
      )> }
    )>>> }
  )>>> }
);

export type AllApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'id'>
  )>>> }
);

export type ApplicationsPaginatedQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ApplicationsPaginatedQuery = (
  { __typename?: 'Query' }
  & { applicationsPaginated?: Maybe<(
    { __typename?: 'ApplicationsPaginatedResponse' }
    & Pick<ApplicationsPaginatedResponse, 'pages' | 'count'>
    & { results?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'id'>
      & { budget?: Maybe<(
        { __typename?: 'Budget' }
        & Pick<Budget, 'currency' | 'amount'>
      )>, cloudWasteAggregate?: Maybe<(
        { __typename?: 'CloudWasteAggregate' }
        & Pick<CloudWasteAggregate, 'hasWaste' | 'cost' | 'count'>
      )>, cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty' | 'currentMonthDaily'>
      )>, environments?: Maybe<Array<Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'id' | 'name' | 'resourceCount'>
        & { budget?: Maybe<(
          { __typename?: 'Budget' }
          & Pick<Budget, 'currency' | 'amount'>
        )>, cloudWasteAggregate?: Maybe<(
          { __typename?: 'CloudWasteAggregate' }
          & Pick<CloudWasteAggregate, 'hasWaste' | 'cost' | 'count'>
        )>, cost?: Maybe<(
          { __typename?: 'Cost' }
          & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
        )>, orgUnitsV2?: Maybe<(
          { __typename?: 'OrgUnitsV2Response' }
          & { orgUnits?: Maybe<Array<(
            { __typename?: 'OrgUnitV2' }
            & Pick<OrgUnitV2, 'nid' | 'name'>
            & { businessContext: (
              { __typename?: 'BusinessContext' }
              & Pick<BusinessContext, 'color' | 'name'>
            ) }
          )>> }
        )> }
      )>>> }
    )>> }
  )> }
);

export type NidEventsQueryVariables = Exact<{
  to: Scalars['AWSDateTime'];
  from: Scalars['AWSDateTime'];
  nid: Scalars['String'];
}>;


export type NidEventsQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'title' | 'type' | 'content' | 'createdAt'>
  )>> }
);

export type ApplicationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApplicationQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'nid' | 'name' | 'displayName' | 'description'>
    & { budget?: Maybe<(
      { __typename?: 'Budget' }
      & Pick<Budget, 'currency' | 'amount'>
    )>, cloudWasteAggregate?: Maybe<(
      { __typename?: 'CloudWasteAggregate' }
      & Pick<CloudWasteAggregate, 'hasWaste' | 'cost' | 'count'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name' | 'email'>
    )>>>, owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>, cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'currentMonthDaily' | 'previousMonth' | 'forecast' | 'dirty'>
    )>, environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'id' | 'nid' | 'name' | 'environmentType' | 'description' | 'resourceCount'>
      & { budget?: Maybe<(
        { __typename?: 'Budget' }
        & Pick<Budget, 'currency' | 'amount'>
      )>, cloudWasteAggregate?: Maybe<(
        { __typename?: 'CloudWasteAggregate' }
        & Pick<CloudWasteAggregate, 'hasWaste' | 'cost' | 'count'>
      )>, contacts?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id'>
      )>>>, orgUnitsV2?: Maybe<(
        { __typename?: 'OrgUnitsV2Response' }
        & { orgUnits?: Maybe<Array<(
          { __typename?: 'OrgUnitV2' }
          & Pick<OrgUnitV2, 'nid' | 'name'>
          & { businessContext: (
            { __typename?: 'BusinessContext' }
            & Pick<BusinessContext, 'color' | 'name'>
          ) }
        )>> }
      )>, cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
      )> }
    )>>> }
  )> }
);

export type AddApplicationMutationVariables = Exact<{
  name: Scalars['String'];
  ownerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  contactIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AddApplicationMutation = (
  { __typename?: 'Mutation' }
  & { addApplication?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplication;
}>;


export type UpdateApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateApplication'>
);

export type UpdateApplicationV2MutationVariables = Exact<{
  nid: Scalars['ID'];
  input: UpdateApplicationV2;
}>;


export type UpdateApplicationV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateApplicationV2'>
);

export type UpdateEnvironmentV2MutationVariables = Exact<{
  nid: Scalars['ID'];
  input: UpdateEnvironmentV2;
}>;


export type UpdateEnvironmentV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEnvironmentV2'>
);

export type RemoveApplicationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeApplication'>
);

export type RemoveApplicationV2MutationVariables = Exact<{
  nid: Scalars['ID'];
}>;


export type RemoveApplicationV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeApplicationV2'>
);

export type AddEnvironmentMutationVariables = Exact<{
  input: AddEnvironment;
}>;


export type AddEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & { addEnvironment?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type RemoveEnvironmentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEnvironment'>
);

export type RemoveEnvironmentV2MutationVariables = Exact<{
  nid: Scalars['ID'];
}>;


export type RemoveEnvironmentV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEnvironmentV2'>
);

export type UpdateEnvironmentMutationVariables = Exact<{
  input: UpdateEnvironment;
}>;


export type UpdateEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEnvironment'>
);

export type ReservedInstancesMutationVariables = Exact<{
  mark?: Maybe<Array<MarkReservedInstance> | MarkReservedInstance>;
  unmark?: Maybe<Array<UnmarkReservedInstance> | UnmarkReservedInstance>;
}>;


export type ReservedInstancesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reservedInstances'>
);

export type ReservableResourcesQueryVariables = Exact<{
  environmentId: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ReservableResourcesQuery = (
  { __typename?: 'Query' }
  & { reservableResources?: Maybe<(
    { __typename?: 'CloudResourcesResponse' }
    & Pick<CloudResourcesResponse, 'pages' | 'count'>
    & { resources?: Maybe<Array<(
      { __typename?: 'CloudResource' }
      & Pick<CloudResource, 'id' | 'provider' | 'providerId' | 'providerType' | 'name' | 'region' | 'cloudAccountId'>
      & { reservation?: Maybe<(
        { __typename?: 'Reservation' }
        & Pick<Reservation, 'from' | 'to'>
      )> }
    )>> }
  )> }
);

export type GetDefaultApplicationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultApplicationQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResponse' }
    & { results?: Maybe<Array<{ __typename?: 'CloudResource' } | { __typename?: 'CostResource' } | (
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'id' | 'nid'>
      & { cloudWasteAggregate?: Maybe<(
        { __typename?: 'CloudWasteAggregate' }
        & Pick<CloudWasteAggregate, 'hasWaste' | 'cost' | 'count'>
      )>, cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
      )>, environments?: Maybe<Array<Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'id' | 'name' | 'resourceCount'>
        & { cost?: Maybe<(
          { __typename?: 'Cost' }
          & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
        )>, orgUnitsV2?: Maybe<(
          { __typename?: 'OrgUnitsV2Response' }
          & { orgUnits?: Maybe<Array<(
            { __typename?: 'OrgUnitV2' }
            & Pick<OrgUnitV2, 'nid' | 'name'>
            & { businessContext: (
              { __typename?: 'BusinessContext' }
              & Pick<BusinessContext, 'color' | 'name'>
            ) }
          )>> }
        )> }
      )>>> }
    ) | { __typename?: 'Environment' } | { __typename?: 'Account' } | { __typename?: 'OrgUnitV2' }>> }
  )> }
);

export type SetApplicationBudgetMutationVariables = Exact<{
  nid: Scalars['ID'];
  budget: BudgetInput;
}>;


export type SetApplicationBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setApplicationBudget'>
);

export type SetEnvironmentBudgetMutationVariables = Exact<{
  nid: Scalars['ID'];
  budget: BudgetInput;
}>;


export type SetEnvironmentBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setEnvironmentBudget'>
);

export type UnsetApplicationBudgetMutationVariables = Exact<{
  nid: Scalars['ID'];
}>;


export type UnsetApplicationBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unsetApplicationBudget'>
);

export type GetMonthlyReportQueryVariables = Exact<{
  period: Scalars['String'];
  reportType: ReportType;
  businessContextId?: Maybe<Scalars['String']>;
}>;


export type GetMonthlyReportQuery = (
  { __typename?: 'Query' }
  & { monthlyReport?: Maybe<(
    { __typename?: 'MonthlyReportResponse' }
    & Pick<MonthlyReportResponse, 'status' | 'fileUrl' | 'error' | 'jobId' | 'reportType'>
  )> }
);

export type CustomerCreatedAtQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerCreatedAtQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'createdAt'>
  )> }
);

export type BusinessContextsMinimalQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type BusinessContextsMinimalQuery = (
  { __typename?: 'Query' }
  & { businessContexts?: Maybe<(
    { __typename?: 'BusinessContextResponse' }
    & Pick<BusinessContextResponse, 'pages' | 'count'>
    & { businessContexts?: Maybe<Array<(
      { __typename?: 'BusinessContext' }
      & Pick<BusinessContext, 'id' | 'name'>
    )>> }
  )> }
);

export type OrgUnitTypesV2QueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type OrgUnitTypesV2Query = (
  { __typename?: 'Query' }
  & { orgUnitTypesV2?: Maybe<(
    { __typename?: 'OrgUnitTypesV2Response' }
    & Pick<OrgUnitTypesV2Response, 'pages' | 'count'>
    & { types?: Maybe<Array<(
      { __typename?: 'OrgUnitTypeV2' }
      & Pick<OrgUnitTypeV2, 'id' | 'name' | 'description'>
    )>> }
  )> }
);

export type AddOrgUnitTypeV2MutationVariables = Exact<{
  input: AddOrgUnitTypeV2;
}>;


export type AddOrgUnitTypeV2Mutation = (
  { __typename?: 'Mutation' }
  & { addOrgUnitTypeV2?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type RemoveOrgUnitTypeV2MutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveOrgUnitTypeV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrgUnitTypeV2'>
);

export type UpdateOrgUnitTypeV2MutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateOrgUnitTypeV2;
}>;


export type UpdateOrgUnitTypeV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrgUnitTypeV2'>
);

export type GetOrgUnitWithChildrenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrgUnitWithChildrenQuery = (
  { __typename?: 'Query' }
  & { orgUnitWithChildren?: Maybe<(
    { __typename?: 'OrgUnitWithChildren' }
    & Pick<OrgUnitWithChildren, 'nid' | 'name' | 'description'>
    & { businessContext: (
      { __typename?: 'BusinessContext' }
      & Pick<BusinessContext, 'id' | 'name'>
    ), type?: Maybe<(
      { __typename?: 'OrgUnitTypeV2' }
      & Pick<OrgUnitTypeV2, 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'name' | 'email'>
    )>, cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
    )>, environments?: Maybe<(
      { __typename?: 'EnvironmentResponse' }
      & Pick<EnvironmentResponse, 'count' | 'pages'>
      & { environments?: Maybe<Array<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'id' | 'nid' | 'name' | 'description'>
        & { cost?: Maybe<(
          { __typename?: 'Cost' }
          & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
        )>, budget?: Maybe<(
          { __typename?: 'Budget' }
          & Pick<Budget, 'amount' | 'currency'>
        )>, application: (
          { __typename?: 'Application' }
          & Pick<Application, 'id' | 'nid' | 'name'>
          & { cost?: Maybe<(
            { __typename?: 'Cost' }
            & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast'>
          )>, environmentsV2?: Maybe<(
            { __typename?: 'EnvironmentResponse' }
            & { environments?: Maybe<Array<(
              { __typename?: 'Environment' }
              & Pick<Environment, 'id' | 'name' | 'resourceCount'>
              & { budget?: Maybe<(
                { __typename?: 'Budget' }
                & Pick<Budget, 'currency' | 'amount'>
              )>, cost?: Maybe<(
                { __typename?: 'Cost' }
                & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
              )>, orgUnitsV2?: Maybe<(
                { __typename?: 'OrgUnitsV2Response' }
                & { orgUnits?: Maybe<Array<(
                  { __typename?: 'OrgUnitV2' }
                  & Pick<OrgUnitV2, 'nid' | 'name'>
                )>> }
              )> }
            )>> }
          )> }
        ) }
      )>> }
    )>, children?: Maybe<Array<(
      { __typename?: 'OrgUnitWithChildren' }
      & Pick<OrgUnitWithChildren, 'nid' | 'name'>
      & { businessContext: (
        { __typename?: 'BusinessContext' }
        & Pick<BusinessContext, 'id'>
      ), cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
      )>, environments?: Maybe<(
        { __typename?: 'EnvironmentResponse' }
        & Pick<EnvironmentResponse, 'count'>
        & { environments?: Maybe<Array<(
          { __typename?: 'Environment' }
          & { cost?: Maybe<(
            { __typename?: 'Cost' }
            & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
          )>, budget?: Maybe<(
            { __typename?: 'Budget' }
            & Pick<Budget, 'amount' | 'currency'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type GetAppsWithEnvironmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppsWithEnvironmentsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'id' | 'nid'>
    & { environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'name' | 'id' | 'nid' | 'resourceCount'>
      & { orgUnitsV2?: Maybe<(
        { __typename?: 'OrgUnitsV2Response' }
        & { orgUnits?: Maybe<Array<(
          { __typename?: 'OrgUnitV2' }
          & Pick<OrgUnitV2, 'nid' | 'name'>
          & { businessContext: (
            { __typename?: 'BusinessContext' }
            & Pick<BusinessContext, 'id' | 'name'>
          ) }
        )>> }
      )> }
    )>>> }
  )>>> }
);

export type OrgUnitBaseDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrgUnitBaseDetailsQuery = (
  { __typename?: 'Query' }
  & { orgUnitV2?: Maybe<(
    { __typename?: 'OrgUnitV2' }
    & Pick<OrgUnitV2, 'nid' | 'name' | 'description'>
    & { type?: Maybe<(
      { __typename?: 'OrgUnitTypeV2' }
      & Pick<OrgUnitTypeV2, 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )> }
  )> }
);

export type GetOrgUnitTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrgUnitTypeQuery = (
  { __typename?: 'Query' }
  & { orgUnitV2?: Maybe<(
    { __typename?: 'OrgUnitV2' }
    & { type?: Maybe<(
      { __typename?: 'OrgUnitTypeV2' }
      & Pick<OrgUnitTypeV2, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateBusinessContextMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateBusinessContext;
}>;


export type UpdateBusinessContextMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBusinessContext'>
);

export type RemoveBusinessContextMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBusinessContextMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBusinessContext'>
);

export type AddOrgUnitV2MutationVariables = Exact<{
  input: AddOrgUnitV2;
}>;


export type AddOrgUnitV2Mutation = (
  { __typename?: 'Mutation' }
  & { addOrgUnitV2?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type RemoveOrgUnitV2MutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveOrgUnitV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrgUnitV2'>
);

export type UpdateOrgUnitV2MutationVariables = Exact<{
  nid: Scalars['ID'];
  input: UpdateOrgUnitV2;
}>;


export type UpdateOrgUnitV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrgUnitV2'>
);

export type AddBusinessContextMutationVariables = Exact<{
  input: AddBusinessContext;
}>;


export type AddBusinessContextMutation = (
  { __typename?: 'Mutation' }
  & { addBusinessContext?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type BusinessContextsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type BusinessContextsQuery = (
  { __typename?: 'Query' }
  & { businessContexts?: Maybe<(
    { __typename?: 'BusinessContextResponse' }
    & Pick<BusinessContextResponse, 'pages' | 'count'>
    & { businessContexts?: Maybe<Array<(
      { __typename?: 'BusinessContext' }
      & Pick<BusinessContext, 'id' | 'name' | 'color'>
    )>> }
  )> }
);

export type BusinessContextQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessContextQuery = (
  { __typename?: 'Query' }
  & { businessContext?: Maybe<(
    { __typename?: 'BusinessContext' }
    & Pick<BusinessContext, 'id' | 'name' | 'description' | 'color'>
    & { costAllocation: (
      { __typename?: 'CostAllocation' }
      & Pick<CostAllocation, 'allocated' | 'unallocated'>
    ), orgUnits?: Maybe<(
      { __typename?: 'OrgUnitsV2NonPaginatedResponse' }
      & Pick<OrgUnitsV2NonPaginatedResponse, 'count'>
      & { orgUnits?: Maybe<Array<(
        { __typename?: 'OrgUnitV2' }
        & Pick<OrgUnitV2, 'nid' | 'name' | 'description' | 'parentNid'>
        & { environments?: Maybe<(
          { __typename?: 'EnvironmentResponse' }
          & Pick<EnvironmentResponse, 'count'>
        )>, businessContext: (
          { __typename?: 'BusinessContext' }
          & Pick<BusinessContext, 'id'>
        ), cost?: Maybe<(
          { __typename?: 'Cost' }
          & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
        )>, type?: Maybe<(
          { __typename?: 'OrgUnitTypeV2' }
          & Pick<OrgUnitTypeV2, 'id' | 'name'>
        )>, contact?: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'id' | 'name'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetOrgUnitTypesV2QueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgUnitTypesV2Query = (
  { __typename?: 'Query' }
  & { orgUnitTypesV2?: Maybe<(
    { __typename?: 'OrgUnitTypesV2Response' }
    & { types?: Maybe<Array<(
      { __typename?: 'OrgUnitTypeV2' }
      & Pick<OrgUnitTypeV2, 'id' | 'name'>
    )>> }
  )> }
);

export type DriftRecordsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type DriftRecordsQuery = (
  { __typename?: 'Query' }
  & { estate?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count' | 'pages'>
    & { resources?: Maybe<Array<{ __typename?: 'CostResource' } | (
      { __typename?: 'CloudResource' }
      & Pick<CloudResource, 'id' | 'provider' | 'name' | 'providerType' | 'drifted'>
      & { environment?: Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'name' | 'id'>
        & { application: (
          { __typename?: 'Application' }
          & Pick<Application, 'name' | 'id'>
        ) }
      )>, pendingAssignment?: Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'name' | 'id'>
        & { application: (
          { __typename?: 'Application' }
          & Pick<Application, 'name' | 'id'>
        ) }
      )> }
    )>> }
  )> }
);

export type UpdateDriftsMutationVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  accept?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDriftsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDriftStatuses'>
);

export type GetDiscoveryRulesQueryVariables = Exact<{
  envId: Scalars['String'];
}>;


export type GetDiscoveryRulesQuery = (
  { __typename?: 'Query' }
  & { discoveryRules?: Maybe<Array<Maybe<(
    { __typename?: 'DiscoveryRule' }
    & Pick<DiscoveryRule, 'id' | 'name' | 'exclusive'>
    & { match: (
      { __typename?: 'DiscoveryRuleMatch' }
      & Pick<DiscoveryRuleMatch, 'nid' | 'cloudAccount' | 'resourceGroup' | 'projectId' | 'type' | 'subtype'>
      & { tags?: Maybe<Array<(
        { __typename?: 'KeyValue' }
        & Pick<KeyValue, 'key' | 'value'>
      )>> }
    ) }
  )>>> }
);

export type AddDiscoveryRuleMutationVariables = Exact<{
  input: AddDiscoveryRule;
}>;


export type AddDiscoveryRuleMutation = (
  { __typename?: 'Mutation' }
  & { addDiscoveryRule?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type RemoveDiscoveryRuleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveDiscoveryRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDiscoveryRule'>
);

export type UpdateDiscoveryRuleMutationVariables = Exact<{
  input: UpdateDiscoveryRule;
}>;


export type UpdateDiscoveryRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDiscoveryRule'>
);

export type EnvironmentQueryVariables = Exact<{
  id: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<ResourceFilter>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type EnvironmentQuery = (
  { __typename?: 'Query' }
  & { environment?: Maybe<(
    { __typename?: 'Environment' }
    & Pick<Environment, 'id' | 'nid' | 'name' | 'environmentType' | 'description'>
    & { budget?: Maybe<(
      { __typename?: 'Budget' }
      & Pick<Budget, 'currency' | 'amount'>
    )>, cloudWasteAggregate?: Maybe<(
      { __typename?: 'CloudWasteAggregate' }
      & Pick<CloudWasteAggregate, 'hasWaste' | 'cost' | 'count'>
    )>, cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty' | 'currentMonthDaily'>
    )>, owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    )>, application: (
      { __typename?: 'Application' }
      & Pick<Application, 'nid' | 'name'>
    ), contacts?: Maybe<Array<Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name' | 'email'>
    )>>>, estate?: Maybe<(
      { __typename?: 'ResourceResponse' }
      & Pick<ResourceResponse, 'count'>
      & { resources?: Maybe<Array<(
        { __typename?: 'CostResource' }
        & Pick<CostResource, 'id' | 'provider' | 'providerType' | 'region'>
        & { environmentsPaginated: (
          { __typename?: 'EnvironmentAndSpendsPaginated' }
          & { environments?: Maybe<Array<(
            { __typename?: 'EnvironmentAndSpends' }
            & { cost?: Maybe<(
              { __typename?: 'Cost' }
              & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
            )> }
          )>> }
        ) }
      ) | (
        { __typename?: 'CloudResource' }
        & Pick<CloudResource, 'id' | 'name' | 'providerId' | 'provider' | 'region' | 'providerType'>
        & { cloudWaste?: Maybe<(
          { __typename?: 'CloudWaste' }
          & Pick<CloudWaste, 'isWaste' | 'reason'>
        )>, cost?: Maybe<(
          { __typename?: 'Cost' }
          & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
        )> }
      )>> }
    )> }
  )> }
);

export type EnvironmentContactsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnvironmentContactsQuery = (
  { __typename?: 'Query' }
  & { environment?: Maybe<(
    { __typename?: 'Environment' }
    & Pick<Environment, 'id' | 'name' | 'description'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>, contacts?: Maybe<Array<Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'name'>
    )>>> }
  )> }
);

export type AccountOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountOptionsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'provider' | 'providerId' | 'name'>
  )>>> }
);

export type AddEnvToOrgUnitV2MutationVariables = Exact<{
  envId: Scalars['ID'];
  orgUnitId: Scalars['ID'];
}>;


export type AddEnvToOrgUnitV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addEnvToOrgUnitV2'>
);

export type RemoveEnvFromOrgUnitV2MutationVariables = Exact<{
  envId: Scalars['ID'];
  orgUnitId: Scalars['ID'];
}>;


export type RemoveEnvFromOrgUnitV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEnvFromOrgUnitV2'>
);

export type GetEnvironmentOrgUnitsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEnvironmentOrgUnitsQuery = (
  { __typename?: 'Query' }
  & { environment?: Maybe<(
    { __typename?: 'Environment' }
    & Pick<Environment, 'nid' | 'id' | 'name'>
    & { orgUnitsV2?: Maybe<(
      { __typename?: 'OrgUnitsV2Response' }
      & { orgUnits?: Maybe<Array<(
        { __typename?: 'OrgUnitV2' }
        & Pick<OrgUnitV2, 'nid' | 'name'>
        & { businessContext: (
          { __typename?: 'BusinessContext' }
          & Pick<BusinessContext, 'id' | 'color' | 'name'>
        ) }
      )>> }
    )> }
  )> }
);

export type UnsetEnvironmentBudgetMutationVariables = Exact<{
  nid: Scalars['ID'];
}>;


export type UnsetEnvironmentBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unsetEnvironmentBudget'>
);

export type CostResourceEnvironmentsQueryVariables = Exact<{
  id: Scalars['String'];
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type CostResourceEnvironmentsQuery = (
  { __typename?: 'Query' }
  & { estateRecord?: Maybe<(
    { __typename?: 'CostResource' }
    & { environmentsPaginated: (
      { __typename?: 'EnvironmentAndSpendsPaginated' }
      & Pick<EnvironmentAndSpendsPaginated, 'count' | 'pages'>
      & { environments?: Maybe<Array<(
        { __typename?: 'EnvironmentAndSpends' }
        & { cost?: Maybe<(
          { __typename?: 'Cost' }
          & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
        )>, environment?: Maybe<(
          { __typename?: 'Environment' }
          & Pick<Environment, 'name' | 'id'>
          & { application: (
            { __typename?: 'Application' }
            & Pick<Application, 'name' | 'id'>
          ) }
        )> }
      )>> }
    ) }
  ) | { __typename?: 'CloudResource' }> }
);

export type ResourceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResourceQuery = (
  { __typename?: 'Query' }
  & { estateRecord?: Maybe<(
    { __typename?: 'CostResource' }
    & Pick<CostResource, 'id' | 'provider' | 'providerType'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
    )>, metadataAndTags?: Maybe<(
      { __typename?: 'MetadataAndTags' }
      & { tags?: Maybe<Array<(
        { __typename?: 'KeyValueSource' }
        & Pick<KeyValueSource, 'key' | 'value'>
      )>>, metadata?: Maybe<Array<(
        { __typename?: 'KeyValueSource' }
        & Pick<KeyValueSource, 'key' | 'value'>
      )>> }
    )> }
  ) | (
    { __typename?: 'CloudResource' }
    & Pick<CloudResource, 'id' | 'provider' | 'providerId' | 'providerType' | 'name' | 'region' | 'drifted' | 'cloudAccountId'>
    & { cloudWaste?: Maybe<(
      { __typename?: 'CloudWaste' }
      & Pick<CloudWaste, 'isWaste' | 'reason'>
    )>, pendingAssignment?: Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'id' | 'name'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'id'>
      ) }
    )>, cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
    )>, environment?: Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'name' | 'id'>
      & { application: (
        { __typename?: 'Application' }
        & Pick<Application, 'name' | 'id'>
      ) }
    )>, metadataAndTags?: Maybe<(
      { __typename?: 'MetadataAndTags' }
      & { tags?: Maybe<Array<(
        { __typename?: 'KeyValueSource' }
        & Pick<KeyValueSource, 'key' | 'value'>
      )>>, metadata?: Maybe<Array<(
        { __typename?: 'KeyValueSource' }
        & Pick<KeyValueSource, 'key' | 'value'>
      )>> }
    )>, reservation?: Maybe<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'from' | 'to'>
    )> }
  )> }
);

export type ReservationDetailsQueryVariables = Exact<{
  reservationId: Scalars['String'];
}>;


export type ReservationDetailsQuery = (
  { __typename?: 'Query' }
  & { reservationDetails?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'from' | 'to' | 'instanceType' | 'purchaseOption' | 'paymentOption' | 'upfrontCost' | 'monthlyCost'>
    & { dailyUtilization?: Maybe<(
      { __typename?: 'ReservationUtilization' }
      & Pick<ReservationUtilization, 'utilization' | 'benefit' | 'forecast'>
      & { timeSeries?: Maybe<Array<Maybe<(
        { __typename?: 'TimeSeries' }
        & Pick<TimeSeries, 'date' | 'value' | 'benefit'>
      )>>> }
    )>, monthlyUtilization?: Maybe<(
      { __typename?: 'ReservationUtilization' }
      & Pick<ReservationUtilization, 'utilization' | 'benefit' | 'forecast'>
      & { timeSeries?: Maybe<Array<Maybe<(
        { __typename?: 'TimeSeries' }
        & Pick<TimeSeries, 'date' | 'value' | 'benefit'>
      )>>> }
    )> }
  )> }
);

export type ListReservationCoveredInstancesQueryVariables = Exact<{
  reservationId: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListReservationCoveredInstancesQuery = (
  { __typename?: 'Query' }
  & { listReservationCoveredInstances?: Maybe<(
    { __typename?: 'ReservationCoveredInstancesPaginated' }
    & Pick<ReservationCoveredInstancesPaginated, 'pages' | 'count'>
    & { instances?: Maybe<Array<(
      { __typename?: 'ReservationCoveredInstance' }
      & Pick<ReservationCoveredInstance, 'id' | 'nid' | 'provider' | 'type' | 'name' | 'account' | 'location' | 'benefit'>
      & { environment?: Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'nid' | 'name'>
        & { application: (
          { __typename?: 'Application' }
          & Pick<Application, 'nid' | 'name'>
        ) }
      )> }
    )>> }
  )> }
);

export type ResourcesQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  filter?: Maybe<ResourceFilter>;
  query?: Maybe<Scalars['String']>;
}>;


export type ResourcesQuery = (
  { __typename?: 'Query' }
  & { estate?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count'>
    & { resources?: Maybe<Array<(
      { __typename?: 'CostResource' }
      & Pick<CostResource, 'id' | 'provider' | 'providerType'>
      & { cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'forecast' | 'dirty'>
      )> }
    ) | (
      { __typename?: 'CloudResource' }
      & Pick<CloudResource, 'id' | 'provider' | 'providerId' | 'providerType' | 'name' | 'region' | 'cloudAccountId'>
      & { cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'forecast' | 'dirty'>
      )>, environment?: Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'name' | 'id' | 'nid'>
        & { application: (
          { __typename?: 'Application' }
          & Pick<Application, 'name' | 'id' | 'nid'>
        ) }
      )>, cloudWaste?: Maybe<(
        { __typename?: 'CloudWaste' }
        & Pick<CloudWaste, 'isWaste' | 'reason'>
      )> }
    )>> }
  )> }
);

export type ResourcesNewQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  sort?: Maybe<SearchSort>;
  filter?: Maybe<EstateV2Filter>;
}>;


export type ResourcesNewQuery = (
  { __typename?: 'Query' }
  & { estateV2?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count'>
    & { cost?: Maybe<(
      { __typename?: 'ResourcesCost' }
      & Pick<ResourcesCost, 'currentMonthTotal'>
    )>, resources?: Maybe<Array<(
      { __typename?: 'CostResource' }
      & Pick<CostResource, 'id' | 'provider' | 'providerType'>
      & { assignments?: Maybe<(
        { __typename?: 'Assignments' }
        & Pick<Assignments, 'appCount' | 'envCount'>
      )>, cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'forecast' | 'dirty'>
      )> }
    ) | (
      { __typename?: 'CloudResource' }
      & Pick<CloudResource, 'id' | 'provider' | 'providerId' | 'providerType' | 'name' | 'region' | 'cloudAccountId'>
      & { cost?: Maybe<(
        { __typename?: 'Cost' }
        & Pick<Cost, 'currentMonth' | 'forecast' | 'dirty'>
      )>, environment?: Maybe<(
        { __typename?: 'Environment' }
        & Pick<Environment, 'name' | 'id' | 'nid'>
        & { application: (
          { __typename?: 'Application' }
          & Pick<Application, 'name' | 'id' | 'nid'>
        ) }
      )>, cloudWaste?: Maybe<(
        { __typename?: 'CloudWaste' }
        & Pick<CloudWaste, 'isWaste' | 'reason'>
      )> }
    )>> }
  )> }
);

export type AttachResourceMutationVariables = Exact<{
  environmentNid: Scalars['String'];
  resourceNid: Scalars['String'];
}>;


export type AttachResourceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'attachResource'>
);

export type EstateExportMutationVariables = Exact<{
  input: EstateRecordsExportInput;
}>;


export type EstateExportMutation = (
  { __typename?: 'Mutation' }
  & { requestEstateRecordsExport?: Maybe<(
    { __typename?: 'RequestExportResponse' }
    & Pick<RequestExportResponse, 'exportJobId'>
  )> }
);

export type EstateDownloadQueryVariables = Exact<{
  exportJobId: Scalars['ID'];
}>;


export type EstateDownloadQuery = (
  { __typename?: 'Query' }
  & { downloadExport?: Maybe<(
    { __typename?: 'DownloadExportResponse' }
    & Pick<DownloadExportResponse, 'fileUrl'>
  )> }
);

export type GetAppsWithEnvsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppsWithEnvsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'id' | 'nid'>
    & { environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'name' | 'id' | 'nid'>
    )>>> }
  )>>> }
);

export type CloudWastePoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type CloudWastePoliciesQuery = (
  { __typename?: 'Query' }
  & { cloudWasteSummary?: Maybe<(
    { __typename?: 'CloudWasteSummaryResponse' }
    & { policies?: Maybe<Array<Maybe<(
      { __typename?: 'CloudWastePolicySummary' }
      & Pick<CloudWastePolicySummary, 'id' | 'title'>
    )>>> }
  )> }
);

export type GetAppsByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAppsByIdsQuery = (
  { __typename?: 'Query' }
  & { applicationsByIds?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'id' | 'nid'>
  )>>> }
);

export type GetEnvsByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetEnvsByIdsQuery = (
  { __typename?: 'Query' }
  & { environmentsByIds?: Maybe<Array<Maybe<(
    { __typename?: 'Environment' }
    & Pick<Environment, 'name' | 'id' | 'nid'>
  )>>> }
);

export type TeamLeaderDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamLeaderDashboardQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'nid' | 'name' | 'displayName'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'currentMonthDaily' | 'previousMonth' | 'forecast' | 'dirty'>
    )>, environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'resourceCount'>
    )>>> }
  )>>> }
);

export type CloudWasteSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type CloudWasteSummaryQuery = (
  { __typename?: 'Query' }
  & { cloudWasteSummary?: Maybe<(
    { __typename?: 'CloudWasteSummaryResponse' }
    & Pick<CloudWasteSummaryResponse, 'totalCost'>
    & { policies?: Maybe<Array<Maybe<(
      { __typename?: 'CloudWastePolicySummary' }
      & Pick<CloudWastePolicySummary, 'id' | 'title' | 'description' | 'cost' | 'count'>
    )>>> }
  )> }
);

export type EstateRecordsAccountStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type EstateRecordsAccountStatusQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'activated'>
  )>>> }
);

export type SpendsFragment = (
  { __typename?: 'DashboardApplicationCost' }
  & Pick<DashboardApplicationCost, 'nid' | 'name' | 'currentMonth' | 'previousMonth' | 'forecast'>
);

export type TopSpendingApplicationsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type TopSpendingApplicationsQuery = (
  { __typename?: 'Query' }
  & { topSpendingApplications: (
    { __typename?: 'TopSpendingApplicationsResponse' }
    & { topSpending?: Maybe<Array<(
      { __typename?: 'DashboardApplicationCost' }
      & SpendsFragment
    )>>, defaultApplication?: Maybe<(
      { __typename?: 'DashboardApplicationCost' }
      & SpendsFragment
    )>, other: (
      { __typename?: 'DashboardApplicationCost' }
      & SpendsFragment
    ) }
  ) }
);

export type ApplicationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationsCountQuery = (
  { __typename?: 'Query' }
  & { applicationsPaginated?: Maybe<(
    { __typename?: 'ApplicationsPaginatedResponse' }
    & Pick<ApplicationsPaginatedResponse, 'count'>
  )> }
);

export type OrganizationCostQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationCostQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'previousMonthDayToDay' | 'forecast' | 'dirty'>
    )> }
  )> }
);

export type GlobalCostAwsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalCostAwsQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
    )> }
  )> }
);

export type GlobalCostAzureQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalCostAzureQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
    )> }
  )> }
);

export type GlobalCostGcpQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalCostGcpQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'currentMonth' | 'previousMonth' | 'forecast' | 'dirty'>
    )> }
  )> }
);

export type BusinessContextsAllocationQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessContextsAllocationQuery = (
  { __typename?: 'Query' }
  & { businessContexts?: Maybe<(
    { __typename?: 'BusinessContextResponse' }
    & { businessContexts?: Maybe<Array<(
      { __typename?: 'BusinessContext' }
      & Pick<BusinessContext, 'id' | 'name'>
      & { costAllocation: (
        { __typename?: 'CostAllocation' }
        & Pick<CostAllocation, 'allocated' | 'unallocated'>
      ) }
    )>> }
  )> }
);

export type EstateRecordsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type EstateRecordsCountQuery = (
  { __typename?: 'Query' }
  & { estate?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count'>
  )> }
);

export type DriftedResourcesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type DriftedResourcesCountQuery = (
  { __typename?: 'Query' }
  & { estate?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count'>
  )> }
);

export type UnassignedResourcesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnassignedResourcesCountQuery = (
  { __typename?: 'Query' }
  & { estate?: Maybe<(
    { __typename?: 'ResourceResponse' }
    & Pick<ResourceResponse, 'count'>
  )> }
);

export type PeopleQueryVariables = Exact<{ [key: string]: never; }>;


export type PeopleQuery = (
  { __typename?: 'Query' }
  & { people?: Maybe<Array<Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'name' | 'email'>
  )>>> }
);

export type AddPersonMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
}>;


export type AddPersonMutation = (
  { __typename?: 'Mutation' }
  & { addPerson?: Maybe<(
    { __typename?: 'MutationResponse' }
    & Pick<MutationResponse, 'id'>
  )> }
);

export type RemovePersonMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemovePersonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePerson'>
);

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}>;


export type UpdatePersonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePerson'>
);

export type ListReservationsQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  start: Scalars['String'];
  end: Scalars['String'];
}>;


export type ListReservationsQuery = (
  { __typename?: 'Query' }
  & { listReservations?: Maybe<(
    { __typename?: 'ReservationsPaginated' }
    & Pick<ReservationsPaginated, 'pages' | 'count'>
    & { reservations?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'from' | 'to' | 'upfrontCost' | 'instanceType' | 'monthlyCost' | 'provider'>
      & { nid: Reservation['reservationId'], purchaseOption: Reservation['paymentOption'], description: Reservation['productDescription'], location: Reservation['region'], account: Reservation['cloudAccount'] }
      & { monthlyUtilization?: Maybe<(
        { __typename?: 'ReservationUtilization' }
        & Pick<ReservationUtilization, 'benefit'>
        & { totalUtilisation: ReservationUtilization['utilization'] }
      )>, dailyUtilization?: Maybe<(
        { __typename?: 'ReservationUtilization' }
        & Pick<ReservationUtilization, 'benefit'>
        & { monthlyUtilisation: ReservationUtilization['utilization'] }
      )> }
    )>> }
  )> }
);

export type ListReservationCoverageByInstanceTypeQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
  start: Scalars['String'];
  end: Scalars['String'];
}>;


export type ListReservationCoverageByInstanceTypeQuery = (
  { __typename?: 'Query' }
  & { listReservationCoverageByInstanceType?: Maybe<(
    { __typename?: 'ReservationCoveredByTypeInstancesPaginated' }
    & Pick<ReservationCoveredByTypeInstancesPaginated, 'pages' | 'count'>
    & { resources?: Maybe<Array<(
      { __typename?: 'ReservationCoveredByType' }
      & Pick<ReservationCoveredByType, 'instanceType' | 'platform' | 'region' | 'provider' | 'averageCoverage' | 'totalCoveredHours' | 'totalOnDemandHours' | 'totalOnDemandCost'>
    )>> }
  )> }
);

export type ListReservationsRecommendationsQueryVariables = Exact<{
  page: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ListReservationsRecommendationsQuery = (
  { __typename?: 'Query' }
  & { listReservationsRecommendations?: Maybe<(
    { __typename?: 'ReservationsRecommendationsPaginated' }
    & Pick<ReservationsRecommendationsPaginated, 'pages' | 'count'>
    & { recommendations?: Maybe<Array<(
      { __typename?: 'ReservationRecommendation' }
      & Pick<ReservationRecommendation, 'numberOfInstances' | 'termInYears' | 'instanceType' | 'platform' | 'provider' | 'account' | 'monthlySavings' | 'monthlySavingsPercentage' | 'upfrontCost' | 'recurringMonthlyCost' | 'expectedUtilisation'>
    )>> }
  )> }
);

export type ReservationsGlobalUtilisationQueryVariables = Exact<{ [key: string]: never; }>;


export type ReservationsGlobalUtilisationQuery = (
  { __typename?: 'Query' }
  & { reservationsGlobalUtilisation: (
    { __typename?: 'ReservationsGlobalUtilisation' }
    & Pick<ReservationsGlobalUtilisation, 'totalReservationCost' | 'totalOnDemandCostEquivalent' | 'totalBenefit'>
    & { timeSeries?: Maybe<Array<Maybe<(
      { __typename?: 'TimeSeries' }
      & Pick<TimeSeries, 'date' | 'value' | 'benefit'>
    )>>> }
  ) }
);

export type ReservationsGlobalCoverageQueryVariables = Exact<{ [key: string]: never; }>;


export type ReservationsGlobalCoverageQuery = (
  { __typename?: 'Query' }
  & { reservationsGlobalCoverage: (
    { __typename?: 'ReservationsGlobalCoverage' }
    & Pick<ReservationsGlobalCoverage, 'averageCoverage' | 'totalCoveredHours' | 'totalOnDemandHours' | 'totalOnDemandCost'>
    & { timeSeries?: Maybe<Array<Maybe<(
      { __typename?: 'TimePoint' }
      & Pick<TimePoint, 'date' | 'value'>
    )>>> }
  ) }
);

export type UpdateRiCoverageTargetMutationVariables = Exact<{
  target: Scalars['Float'];
}>;


export type UpdateRiCoverageTargetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRICoverageTarget'>
);

export type SearchFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchFiltersQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'id' | 'nid'>
    & { environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'name' | 'id' | 'nid'>
    )>>> }
  )>>>, accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'provider' | 'name' | 'id' | 'providerId' | 'activated' | 'providerType' | 'status'>
  )>>> }
);

export type CloudWasteSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CloudWasteSettingsQuery = (
  { __typename?: 'Query' }
  & { cloudWasteSettings: Array<(
    { __typename?: 'CloudWasteSetting' }
    & Pick<CloudWasteSetting, 'id' | 'name' | 'description' | 'status'>
    & { parameters: Array<(
      { __typename?: 'CloudWasteSettingParameterInt' }
      & Pick<CloudWasteSettingParameterInt, 'id' | 'description' | 'value' | 'min' | 'max'>
    )> }
  )> }
);

export type UpdateCloudWasteSettingMutationVariables = Exact<{
  input: UpdateCloudWasteSetting;
}>;


export type UpdateCloudWasteSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCloudWasteSetting'>
);

export type GlobalTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalTagsQuery = (
  { __typename?: 'Query' }
  & { globalTagKeys?: Maybe<(
    { __typename?: 'GlobalTagKeys' }
    & Pick<GlobalTagKeys, 'application' | 'environment'>
  )> }
);

export type UpdateGlobalKeysMutationVariables = Exact<{
  application?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  environment?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateGlobalKeysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGlobalTagKeys'>
);

export type CostMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CostMappingsQuery = (
  { __typename?: 'Query' }
  & { costMappings?: Maybe<Array<Maybe<(
    { __typename?: 'CostCategoryMapping' }
    & Pick<CostCategoryMapping, 'id' | 'type' | 'provider' | 'algorithm'>
    & { settings?: Maybe<(
      { __typename?: 'CostSettings' }
      & Pick<CostSettings, 'allEnvironments'>
      & { environments?: Maybe<Array<(
        { __typename?: 'EnvCostShare' }
        & Pick<EnvCostShare, 'id' | 'splitValue'>
      )>> }
    )> }
  )>>> }
);

export type UpdateCostMappingRuleAlgorithmMutationVariables = Exact<{
  id: Scalars['String'];
  algorithm?: Maybe<CostMappingAlgorithm>;
  settings?: Maybe<CostSettingsInput>;
}>;


export type UpdateCostMappingRuleAlgorithmMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCostCategoryMapping'>
);

export type DefaultApplicationQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultApplicationQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'name' | 'id' | 'nid'>
    & { environments?: Maybe<Array<Maybe<(
      { __typename?: 'Environment' }
      & Pick<Environment, 'name' | 'id' | 'nid' | 'resourceCount'>
    )>>> }
  )>>> }
);

export type GetUsersPermissionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type GetUsersPermissionsQuery = (
  { __typename?: 'Query' }
  & { usersPermissions?: Maybe<(
    { __typename?: 'UsersPermissionsResponse' }
    & Pick<UsersPermissionsResponse, 'pages' | 'count'>
    & { usersPermissions?: Maybe<Array<(
      { __typename?: 'UserPermissions' }
      & Pick<UserPermissions, 'email'>
      & { access: (
        { __typename?: 'Access' }
        & Pick<Access, 'role' | 'applications'>
        & { applicationsWithNames?: Maybe<Array<(
          { __typename?: 'ApplicationName' }
          & Pick<ApplicationName, 'nid' | 'name' | 'displayName'>
        )>> }
      ) }
    )>> }
  )> }
);

export type RemoveUserPermissionsMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RemoveUserPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUserPermissions'>
);

export type SetUserPermissionsMutationVariables = Exact<{
  email: Scalars['String'];
  input: AccessInput;
}>;


export type SetUserPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUserPermissions'>
);

export type ApplicationsPaginatedListQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ApplicationsPaginatedListQuery = (
  { __typename?: 'Query' }
  & { applicationsPaginated?: Maybe<(
    { __typename?: 'ApplicationsPaginatedResponse' }
    & Pick<ApplicationsPaginatedResponse, 'pages' | 'count'>
    & { results?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'name' | 'description' | 'nid'>
    )>> }
  )> }
);

export const SpendsFragmentDoc = gql`
    fragment Spends on DashboardApplicationCost {
  nid
  name
  currentMonth
  previousMonth
  forecast
}
    `;
export const EventsDocument = gql`
    query Events($from: AWSDateTime!, $nid: String!, $to: AWSDateTime!) {
  events(from: $from, nid: $nid, to: $to) {
    title
    type
    content
    createdAt
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      nid: // value for 'nid'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const GetTagsByKeyDocument = gql`
    query GetTagsByKey($key: String!, $limit: Int!, $after: String) {
  searchTagKeys(keyPrefix: $key, limit: $limit, after: $after) {
    keys
    next
  }
}
    `;

/**
 * __useGetTagsByKeyQuery__
 *
 * To run a query within a React component, call `useGetTagsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTagsByKeyQuery(baseOptions: Apollo.QueryHookOptions<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>(GetTagsByKeyDocument, options);
      }
export function useGetTagsByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>(GetTagsByKeyDocument, options);
        }
export type GetTagsByKeyQueryHookResult = ReturnType<typeof useGetTagsByKeyQuery>;
export type GetTagsByKeyLazyQueryHookResult = ReturnType<typeof useGetTagsByKeyLazyQuery>;
export type GetTagsByKeyQueryResult = Apollo.QueryResult<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>;
export const GetTagsByValueDocument = gql`
    query GetTagsByValue($key: String!, $value: String!, $limit: Int!, $after: String) {
  searchTagValues(key: $key, valuePrefix: $value, limit: $limit, after: $after) {
    values
    next
  }
}
    `;

/**
 * __useGetTagsByValueQuery__
 *
 * To run a query within a React component, call `useGetTagsByValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByValueQuery({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTagsByValueQuery(baseOptions: Apollo.QueryHookOptions<GetTagsByValueQuery, GetTagsByValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsByValueQuery, GetTagsByValueQueryVariables>(GetTagsByValueDocument, options);
      }
export function useGetTagsByValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsByValueQuery, GetTagsByValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsByValueQuery, GetTagsByValueQueryVariables>(GetTagsByValueDocument, options);
        }
export type GetTagsByValueQueryHookResult = ReturnType<typeof useGetTagsByValueQuery>;
export type GetTagsByValueLazyQueryHookResult = ReturnType<typeof useGetTagsByValueLazyQuery>;
export type GetTagsByValueQueryResult = Apollo.QueryResult<GetTagsByValueQuery, GetTagsByValueQueryVariables>;
export const EstateResourceCountDocument = gql`
    query EstateResourceCount($filter: EstateResourceFilter) {
  estateResourceCount(filter: $filter)
}
    `;

/**
 * __useEstateResourceCountQuery__
 *
 * To run a query within a React component, call `useEstateResourceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateResourceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateResourceCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEstateResourceCountQuery(baseOptions?: Apollo.QueryHookOptions<EstateResourceCountQuery, EstateResourceCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstateResourceCountQuery, EstateResourceCountQueryVariables>(EstateResourceCountDocument, options);
      }
export function useEstateResourceCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateResourceCountQuery, EstateResourceCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstateResourceCountQuery, EstateResourceCountQueryVariables>(EstateResourceCountDocument, options);
        }
export type EstateResourceCountQueryHookResult = ReturnType<typeof useEstateResourceCountQuery>;
export type EstateResourceCountLazyQueryHookResult = ReturnType<typeof useEstateResourceCountLazyQuery>;
export type EstateResourceCountQueryResult = Apollo.QueryResult<EstateResourceCountQuery, EstateResourceCountQueryVariables>;
export const GoogleCloudProjectsDocument = gql`
    query GoogleCloudProjects($limit: Int!, $page: Int!) {
  estate(
    limit: $limit
    page: $page
    filter: {provider: GCP, type: "compute.googleapis.com/projects"}
  ) {
    count
    resources {
      ... on CloudResource {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGoogleCloudProjectsQuery__
 *
 * To run a query within a React component, call `useGoogleCloudProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleCloudProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleCloudProjectsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGoogleCloudProjectsQuery(baseOptions: Apollo.QueryHookOptions<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>(GoogleCloudProjectsDocument, options);
      }
export function useGoogleCloudProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>(GoogleCloudProjectsDocument, options);
        }
export type GoogleCloudProjectsQueryHookResult = ReturnType<typeof useGoogleCloudProjectsQuery>;
export type GoogleCloudProjectsLazyQueryHookResult = ReturnType<typeof useGoogleCloudProjectsLazyQuery>;
export type GoogleCloudProjectsQueryResult = Apollo.QueryResult<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts {
  accounts {
    provider
    name
    id
    providerId
    activated
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetApplicationsByNameDocument = gql`
    query GetApplicationsByName($phrase: String!, $limit: Int!) {
  search(phrase: $phrase, filter: {type: APPLICATION}, limit: $limit, page: 0) {
    results {
      __typename
      ... on Application {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetApplicationsByNameQuery__
 *
 * To run a query within a React component, call `useGetApplicationsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsByNameQuery({
 *   variables: {
 *      phrase: // value for 'phrase'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetApplicationsByNameQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationsByNameQuery, GetApplicationsByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationsByNameQuery, GetApplicationsByNameQueryVariables>(GetApplicationsByNameDocument, options);
      }
export function useGetApplicationsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsByNameQuery, GetApplicationsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationsByNameQuery, GetApplicationsByNameQueryVariables>(GetApplicationsByNameDocument, options);
        }
export type GetApplicationsByNameQueryHookResult = ReturnType<typeof useGetApplicationsByNameQuery>;
export type GetApplicationsByNameLazyQueryHookResult = ReturnType<typeof useGetApplicationsByNameLazyQuery>;
export type GetApplicationsByNameQueryResult = Apollo.QueryResult<GetApplicationsByNameQuery, GetApplicationsByNameQueryVariables>;
export const GetEnvironmentsFromAppDocument = gql`
    query GetEnvironmentsFromApp($appId: String!, $limit: Int!) {
  application(id: $appId) {
    id
    environmentsV2(limit: $limit, page: 0) {
      environments {
        id
        nid
        name
      }
    }
  }
}
    `;

/**
 * __useGetEnvironmentsFromAppQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentsFromAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentsFromAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentsFromAppQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEnvironmentsFromAppQuery(baseOptions: Apollo.QueryHookOptions<GetEnvironmentsFromAppQuery, GetEnvironmentsFromAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvironmentsFromAppQuery, GetEnvironmentsFromAppQueryVariables>(GetEnvironmentsFromAppDocument, options);
      }
export function useGetEnvironmentsFromAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentsFromAppQuery, GetEnvironmentsFromAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvironmentsFromAppQuery, GetEnvironmentsFromAppQueryVariables>(GetEnvironmentsFromAppDocument, options);
        }
export type GetEnvironmentsFromAppQueryHookResult = ReturnType<typeof useGetEnvironmentsFromAppQuery>;
export type GetEnvironmentsFromAppLazyQueryHookResult = ReturnType<typeof useGetEnvironmentsFromAppLazyQuery>;
export type GetEnvironmentsFromAppQueryResult = Apollo.QueryResult<GetEnvironmentsFromAppQuery, GetEnvironmentsFromAppQueryVariables>;
export const GetApplicationNameDocument = gql`
    query GetApplicationName($id: String!) {
  application(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetApplicationNameQuery__
 *
 * To run a query within a React component, call `useGetApplicationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationNameQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationNameQuery, GetApplicationNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationNameQuery, GetApplicationNameQueryVariables>(GetApplicationNameDocument, options);
      }
export function useGetApplicationNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationNameQuery, GetApplicationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationNameQuery, GetApplicationNameQueryVariables>(GetApplicationNameDocument, options);
        }
export type GetApplicationNameQueryHookResult = ReturnType<typeof useGetApplicationNameQuery>;
export type GetApplicationNameLazyQueryHookResult = ReturnType<typeof useGetApplicationNameLazyQuery>;
export type GetApplicationNameQueryResult = Apollo.QueryResult<GetApplicationNameQuery, GetApplicationNameQueryVariables>;
export const NotificationListDocument = gql`
    query NotificationList($cursor: String, $limit: Int!) {
  notifications(limit: $limit, cursor: $cursor) {
    notifications {
      id
      type
      title
      details
      targetView
      read
      createdAt
    }
    cursor
  }
}
    `;

/**
 * __useNotificationListQuery__
 *
 * To run a query within a React component, call `useNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotificationListQuery(baseOptions: Apollo.QueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
      }
export function useNotificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
        }
export type NotificationListQueryHookResult = ReturnType<typeof useNotificationListQuery>;
export type NotificationListLazyQueryHookResult = ReturnType<typeof useNotificationListLazyQuery>;
export type NotificationListQueryResult = Apollo.QueryResult<NotificationListQuery, NotificationListQueryVariables>;
export const MarkNotificationsAsReadDocument = gql`
    mutation markNotificationsAsRead($ids: [String!]!) {
  markNotificationsAsRead(ids: $ids)
}
    `;
export type MarkNotificationsAsReadMutationFn = Apollo.MutationFunction<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>(MarkNotificationsAsReadDocument, options);
      }
export type MarkNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationResult = Apollo.MutationResult<MarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>;
export const TriggerRecalculationDocument = gql`
    mutation TriggerRecalculation {
  triggerRecalculation
}
    `;
export type TriggerRecalculationMutationFn = Apollo.MutationFunction<TriggerRecalculationMutation, TriggerRecalculationMutationVariables>;

/**
 * __useTriggerRecalculationMutation__
 *
 * To run a mutation, you first call `useTriggerRecalculationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRecalculationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRecalculationMutation, { data, loading, error }] = useTriggerRecalculationMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerRecalculationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerRecalculationMutation, TriggerRecalculationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerRecalculationMutation, TriggerRecalculationMutationVariables>(TriggerRecalculationDocument, options);
      }
export type TriggerRecalculationMutationHookResult = ReturnType<typeof useTriggerRecalculationMutation>;
export type TriggerRecalculationMutationResult = Apollo.MutationResult<TriggerRecalculationMutation>;
export type TriggerRecalculationMutationOptions = Apollo.BaseMutationOptions<TriggerRecalculationMutation, TriggerRecalculationMutationVariables>;
export const GetCustomerDirtinessDocument = gql`
    query GetCustomerDirtiness {
  customerDirtiness {
    isDirty
  }
}
    `;

/**
 * __useGetCustomerDirtinessQuery__
 *
 * To run a query within a React component, call `useGetCustomerDirtinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDirtinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDirtinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerDirtinessQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>(GetCustomerDirtinessDocument, options);
      }
export function useGetCustomerDirtinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>(GetCustomerDirtinessDocument, options);
        }
export type GetCustomerDirtinessQueryHookResult = ReturnType<typeof useGetCustomerDirtinessQuery>;
export type GetCustomerDirtinessLazyQueryHookResult = ReturnType<typeof useGetCustomerDirtinessLazyQuery>;
export type GetCustomerDirtinessQueryResult = Apollo.QueryResult<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>;
export const GetRecalculationStatusDocument = gql`
    query GetRecalculationStatus {
  recalculations {
    status
  }
}
    `;

/**
 * __useGetRecalculationStatusQuery__
 *
 * To run a query within a React component, call `useGetRecalculationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecalculationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecalculationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecalculationStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>(GetRecalculationStatusDocument, options);
      }
export function useGetRecalculationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>(GetRecalculationStatusDocument, options);
        }
export type GetRecalculationStatusQueryHookResult = ReturnType<typeof useGetRecalculationStatusQuery>;
export type GetRecalculationStatusLazyQueryHookResult = ReturnType<typeof useGetRecalculationStatusLazyQuery>;
export type GetRecalculationStatusQueryResult = Apollo.QueryResult<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>;
export const SearchDocument = gql`
    query Search($phrase: String!, $filter: SearchFilter, $limit: Int!, $page: Int!) {
  search(phrase: $phrase, filter: $filter, limit: $limit, page: $page) {
    count
    pages
    results {
      __typename
      ... on CloudResource {
        id
        name
        providerType
        cloudAccountId
        cost {
          currentMonth
          forecast
        }
        provider
        cloudWaste {
          isWaste
          reason
        }
      }
      ... on CostResource {
        id
        providerType
        provider
      }
      ... on Application {
        id
        name
      }
      ... on Environment {
        id
        name
        application {
          id
          name
        }
      }
      ... on Account {
        id
        name
        provider
      }
      ... on OrgUnitV2 {
        nid
        name
        businessContext {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      phrase: // value for 'phrase'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const CustomerConfigDocument = gql`
    query CustomerConfig {
  customerConfig {
    riEnabled
    allowExperimental
    defaultCurrency
    riCoverageTarget
    enabledFeatures
  }
}
    `;

/**
 * __useCustomerConfigQuery__
 *
 * To run a query within a React component, call `useCustomerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerConfigQuery(baseOptions?: Apollo.QueryHookOptions<CustomerConfigQuery, CustomerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerConfigQuery, CustomerConfigQueryVariables>(CustomerConfigDocument, options);
      }
export function useCustomerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerConfigQuery, CustomerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerConfigQuery, CustomerConfigQueryVariables>(CustomerConfigDocument, options);
        }
export type CustomerConfigQueryHookResult = ReturnType<typeof useCustomerConfigQuery>;
export type CustomerConfigLazyQueryHookResult = ReturnType<typeof useCustomerConfigLazyQuery>;
export type CustomerConfigQueryResult = Apollo.QueryResult<CustomerConfigQuery, CustomerConfigQueryVariables>;
export const ResourceTypesDocument = gql`
    query ResourceTypes {
  resourceTypes
}
    `;

/**
 * __useResourceTypesQuery__
 *
 * To run a query within a React component, call `useResourceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResourceTypesQuery(baseOptions?: Apollo.QueryHookOptions<ResourceTypesQuery, ResourceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceTypesQuery, ResourceTypesQueryVariables>(ResourceTypesDocument, options);
      }
export function useResourceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceTypesQuery, ResourceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceTypesQuery, ResourceTypesQueryVariables>(ResourceTypesDocument, options);
        }
export type ResourceTypesQueryHookResult = ReturnType<typeof useResourceTypesQuery>;
export type ResourceTypesLazyQueryHookResult = ReturnType<typeof useResourceTypesLazyQuery>;
export type ResourceTypesQueryResult = Apollo.QueryResult<ResourceTypesQuery, ResourceTypesQueryVariables>;
export const AccountsDocument = gql`
    query Accounts {
  accounts {
    id
    provider
    providerId
    activated
    description
    name
    owner {
      name
    }
    contacts {
      id
      name
    }
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const AccountDocument = gql`
    query Account($id: String!) {
  account(id: $id) {
    id
    provider
    providerId
    activated
    name
    description
    owner {
      id
      name
    }
    contacts {
      id
      name
    }
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const AccountV2Document = gql`
    query AccountV2($id: String!) {
  accountV2(id: $id) {
    id
    name
    provider
    status
    providerId
    providerType
    description
    owner {
      id
      name
    }
    activated
    metadata
  }
}
    `;

/**
 * __useAccountV2Query__
 *
 * To run a query within a React component, call `useAccountV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAccountV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountV2Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountV2Query(baseOptions: Apollo.QueryHookOptions<AccountV2Query, AccountV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountV2Query, AccountV2QueryVariables>(AccountV2Document, options);
      }
export function useAccountV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountV2Query, AccountV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountV2Query, AccountV2QueryVariables>(AccountV2Document, options);
        }
export type AccountV2QueryHookResult = ReturnType<typeof useAccountV2Query>;
export type AccountV2LazyQueryHookResult = ReturnType<typeof useAccountV2LazyQuery>;
export type AccountV2QueryResult = Apollo.QueryResult<AccountV2Query, AccountV2QueryVariables>;
export const AddAccountV2Document = gql`
    mutation AddAccountV2($input: AddAccountV2!) {
  addAccountV2(input: $input) {
    id
  }
}
    `;
export type AddAccountV2MutationFn = Apollo.MutationFunction<AddAccountV2Mutation, AddAccountV2MutationVariables>;

/**
 * __useAddAccountV2Mutation__
 *
 * To run a mutation, you first call `useAddAccountV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountV2Mutation, { data, loading, error }] = useAddAccountV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountV2Mutation(baseOptions?: Apollo.MutationHookOptions<AddAccountV2Mutation, AddAccountV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountV2Mutation, AddAccountV2MutationVariables>(AddAccountV2Document, options);
      }
export type AddAccountV2MutationHookResult = ReturnType<typeof useAddAccountV2Mutation>;
export type AddAccountV2MutationResult = Apollo.MutationResult<AddAccountV2Mutation>;
export type AddAccountV2MutationOptions = Apollo.BaseMutationOptions<AddAccountV2Mutation, AddAccountV2MutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($id: String!, $name: String, $description: String, $ownerId: String) {
  updateAccount(
    input: {id: $id, name: $name, description: $description, ownerId: $ownerId}
  )
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdateAccountV2Document = gql`
    mutation updateAccountV2($id: ID!, $name: String, $ownerId: ID, $status: String) {
  updateAccountV2(
    nid: $id
    input: {name: $name, ownerId: $ownerId, status: $status}
  )
}
    `;
export type UpdateAccountV2MutationFn = Apollo.MutationFunction<UpdateAccountV2Mutation, UpdateAccountV2MutationVariables>;

/**
 * __useUpdateAccountV2Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountV2Mutation, { data, loading, error }] = useUpdateAccountV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      ownerId: // value for 'ownerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAccountV2Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountV2Mutation, UpdateAccountV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountV2Mutation, UpdateAccountV2MutationVariables>(UpdateAccountV2Document, options);
      }
export type UpdateAccountV2MutationHookResult = ReturnType<typeof useUpdateAccountV2Mutation>;
export type UpdateAccountV2MutationResult = Apollo.MutationResult<UpdateAccountV2Mutation>;
export type UpdateAccountV2MutationOptions = Apollo.BaseMutationOptions<UpdateAccountV2Mutation, UpdateAccountV2MutationVariables>;
export const RemoveAccountDocument = gql`
    mutation removeAccount($id: String!) {
  removeAccount(id: $id)
}
    `;
export type RemoveAccountMutationFn = Apollo.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>;

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountMutation, RemoveAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAccountMutation, RemoveAccountMutationVariables>(RemoveAccountDocument, options);
      }
export type RemoveAccountMutationHookResult = ReturnType<typeof useRemoveAccountMutation>;
export type RemoveAccountMutationResult = Apollo.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = Apollo.BaseMutationOptions<RemoveAccountMutation, RemoveAccountMutationVariables>;
export const QueryAccountsV2CountsDocument = gql`
    query queryAccountsV2Counts {
  AWS: accountsV2(limit: 1, filter: {providers: [AWS]}) {
    count
  }
  GCP: accountsV2(limit: 1, filter: {providers: [GCP]}) {
    count
  }
  AZURE: accountsV2(limit: 1, filter: {providers: [AZURE]}) {
    count
  }
  VMWARE: accountsV2(
    limit: 1
    filter: {providers: [HYBRID, VMWARE], providerTypes: [VMWARE]}
  ) {
    count
  }
  IBMCLOUD: accountsV2(limit: 1, filter: {providers: [IBMCLOUD]}) {
    count
  }
  KUBERNETES: accountsV2(
    limit: 1
    filter: {providers: [HYBRID, KUBERNETES], providerTypes: [KUBERNETES]}
  ) {
    count
  }
  OPENSHIFT: accountsV2(
    limit: 1
    filter: {providers: [HYBRID, OPENSHIFT], providerTypes: [OPENSHIFT]}
  ) {
    count
  }
}
    `;

/**
 * __useQueryAccountsV2CountsQuery__
 *
 * To run a query within a React component, call `useQueryAccountsV2CountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountsV2CountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountsV2CountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryAccountsV2CountsQuery(baseOptions?: Apollo.QueryHookOptions<QueryAccountsV2CountsQuery, QueryAccountsV2CountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryAccountsV2CountsQuery, QueryAccountsV2CountsQueryVariables>(QueryAccountsV2CountsDocument, options);
      }
export function useQueryAccountsV2CountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryAccountsV2CountsQuery, QueryAccountsV2CountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryAccountsV2CountsQuery, QueryAccountsV2CountsQueryVariables>(QueryAccountsV2CountsDocument, options);
        }
export type QueryAccountsV2CountsQueryHookResult = ReturnType<typeof useQueryAccountsV2CountsQuery>;
export type QueryAccountsV2CountsLazyQueryHookResult = ReturnType<typeof useQueryAccountsV2CountsLazyQuery>;
export type QueryAccountsV2CountsQueryResult = Apollo.QueryResult<QueryAccountsV2CountsQuery, QueryAccountsV2CountsQueryVariables>;
export const QueryAccountsV2ByPageAndProviderDocument = gql`
    query queryAccountsV2ByPageAndProvider($limit: Int!, $page: Int!, $filter: AccountsFilter) {
  accountsV2(limit: $limit, page: $page, filter: $filter) {
    accounts {
      id
      provider
      providerId
      providerType
      activated
      status
      description
      name
      owner {
        name
      }
      contacts {
        id
        name
      }
    }
    count
  }
}
    `;

/**
 * __useQueryAccountsV2ByPageAndProviderQuery__
 *
 * To run a query within a React component, call `useQueryAccountsV2ByPageAndProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountsV2ByPageAndProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountsV2ByPageAndProviderQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryAccountsV2ByPageAndProviderQuery(baseOptions: Apollo.QueryHookOptions<QueryAccountsV2ByPageAndProviderQuery, QueryAccountsV2ByPageAndProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryAccountsV2ByPageAndProviderQuery, QueryAccountsV2ByPageAndProviderQueryVariables>(QueryAccountsV2ByPageAndProviderDocument, options);
      }
export function useQueryAccountsV2ByPageAndProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryAccountsV2ByPageAndProviderQuery, QueryAccountsV2ByPageAndProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryAccountsV2ByPageAndProviderQuery, QueryAccountsV2ByPageAndProviderQueryVariables>(QueryAccountsV2ByPageAndProviderDocument, options);
        }
export type QueryAccountsV2ByPageAndProviderQueryHookResult = ReturnType<typeof useQueryAccountsV2ByPageAndProviderQuery>;
export type QueryAccountsV2ByPageAndProviderLazyQueryHookResult = ReturnType<typeof useQueryAccountsV2ByPageAndProviderLazyQuery>;
export type QueryAccountsV2ByPageAndProviderQueryResult = Apollo.QueryResult<QueryAccountsV2ByPageAndProviderQuery, QueryAccountsV2ByPageAndProviderQueryVariables>;
export const ApplicationFormDataDocument = gql`
    query ApplicationFormData($id: String!) {
  application(id: $id) {
    nid
    owner {
      id
      name
    }
    environments {
      nid
      name
      environmentType
      budget {
        currency
        amount
      }
      description
      contacts {
        id
      }
    }
  }
}
    `;

/**
 * __useApplicationFormDataQuery__
 *
 * To run a query within a React component, call `useApplicationFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationFormDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationFormDataQuery(baseOptions: Apollo.QueryHookOptions<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>(ApplicationFormDataDocument, options);
      }
export function useApplicationFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>(ApplicationFormDataDocument, options);
        }
export type ApplicationFormDataQueryHookResult = ReturnType<typeof useApplicationFormDataQuery>;
export type ApplicationFormDataLazyQueryHookResult = ReturnType<typeof useApplicationFormDataLazyQuery>;
export type ApplicationFormDataQueryResult = Apollo.QueryResult<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>;
export const ApplicationsDocument = gql`
    query Applications {
  applications {
    id
    name
    cost {
      forecast
      currentMonth
      dirty
    }
    environments {
      id
      cost {
        forecast
        currentMonth
        dirty
      }
      budget {
        amount
        currency
      }
      name
      description
      resourceCount
      orgUnitsV2(limit: 50, page: 0) {
        orgUnits {
          nid
          name
        }
      }
    }
  }
}
    `;

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
      }
export function useApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
        }
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>;
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>;
export type ApplicationsQueryResult = Apollo.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>;
export const AllApplicationsDocument = gql`
    query AllApplications {
  applications {
    name
    id
  }
}
    `;

/**
 * __useAllApplicationsQuery__
 *
 * To run a query within a React component, call `useAllApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<AllApplicationsQuery, AllApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllApplicationsQuery, AllApplicationsQueryVariables>(AllApplicationsDocument, options);
      }
export function useAllApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllApplicationsQuery, AllApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllApplicationsQuery, AllApplicationsQueryVariables>(AllApplicationsDocument, options);
        }
export type AllApplicationsQueryHookResult = ReturnType<typeof useAllApplicationsQuery>;
export type AllApplicationsLazyQueryHookResult = ReturnType<typeof useAllApplicationsLazyQuery>;
export type AllApplicationsQueryResult = Apollo.QueryResult<AllApplicationsQuery, AllApplicationsQueryVariables>;
export const ApplicationsPaginatedDocument = gql`
    query ApplicationsPaginated($limit: Int!, $page: Int!) {
  applicationsPaginated(limit: $limit, page: $page) {
    pages
    count
    results {
      name
      id
      budget {
        currency
        amount
      }
      cloudWasteAggregate {
        hasWaste
        cost
        count
      }
      cost {
        currentMonth
        previousMonth
        forecast
        dirty
        currentMonthDaily
      }
      environments {
        id
        name
        budget {
          currency
          amount
        }
        cloudWasteAggregate {
          hasWaste
          cost
          count
        }
        cost {
          currentMonth
          previousMonth
          forecast
          dirty
        }
        resourceCount
        orgUnitsV2(limit: 50, page: 0) {
          orgUnits {
            nid
            name
            businessContext {
              color
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useApplicationsPaginatedQuery__
 *
 * To run a query within a React component, call `useApplicationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useApplicationsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>(ApplicationsPaginatedDocument, options);
      }
export function useApplicationsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>(ApplicationsPaginatedDocument, options);
        }
export type ApplicationsPaginatedQueryHookResult = ReturnType<typeof useApplicationsPaginatedQuery>;
export type ApplicationsPaginatedLazyQueryHookResult = ReturnType<typeof useApplicationsPaginatedLazyQuery>;
export type ApplicationsPaginatedQueryResult = Apollo.QueryResult<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>;
export const NidEventsDocument = gql`
    query NidEvents($to: AWSDateTime!, $from: AWSDateTime!, $nid: String!) {
  events(to: $to, from: $from, nid: $nid) {
    title
    type
    content
    createdAt
  }
}
    `;

/**
 * __useNidEventsQuery__
 *
 * To run a query within a React component, call `useNidEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNidEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNidEventsQuery({
 *   variables: {
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useNidEventsQuery(baseOptions: Apollo.QueryHookOptions<NidEventsQuery, NidEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NidEventsQuery, NidEventsQueryVariables>(NidEventsDocument, options);
      }
export function useNidEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NidEventsQuery, NidEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NidEventsQuery, NidEventsQueryVariables>(NidEventsDocument, options);
        }
export type NidEventsQueryHookResult = ReturnType<typeof useNidEventsQuery>;
export type NidEventsLazyQueryHookResult = ReturnType<typeof useNidEventsLazyQuery>;
export type NidEventsQueryResult = Apollo.QueryResult<NidEventsQuery, NidEventsQueryVariables>;
export const ApplicationDocument = gql`
    query Application($id: String!) {
  application(id: $id) {
    id
    nid
    name
    displayName
    budget {
      currency
      amount
    }
    cloudWasteAggregate {
      hasWaste
      cost
      count
    }
    contacts {
      id
      name
      email
    }
    description
    owner {
      id
      name
    }
    cost {
      currentMonth
      currentMonthDaily
      previousMonth
      forecast
      dirty
    }
    environments {
      id
      nid
      name
      environmentType
      budget {
        currency
        amount
      }
      cloudWasteAggregate {
        hasWaste
        cost
        count
      }
      description
      contacts {
        id
      }
      orgUnitsV2(limit: 50, page: 0) {
        orgUnits {
          nid
          name
          businessContext {
            color
            name
          }
        }
      }
      resourceCount
      cost {
        currentMonth
        previousMonth
        forecast
        dirty
      }
    }
  }
}
    `;

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationQuery(baseOptions: Apollo.QueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options);
      }
export function useApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options);
        }
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>;
export type ApplicationLazyQueryHookResult = ReturnType<typeof useApplicationLazyQuery>;
export type ApplicationQueryResult = Apollo.QueryResult<ApplicationQuery, ApplicationQueryVariables>;
export const AddApplicationDocument = gql`
    mutation addApplication($name: String!, $ownerId: String!, $description: String, $contactIds: [String!]) {
  addApplication(
    input: {name: $name, ownerId: $ownerId, description: $description, contactIds: $contactIds}
  ) {
    id
  }
}
    `;
export type AddApplicationMutationFn = Apollo.MutationFunction<AddApplicationMutation, AddApplicationMutationVariables>;

/**
 * __useAddApplicationMutation__
 *
 * To run a mutation, you first call `useAddApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicationMutation, { data, loading, error }] = useAddApplicationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ownerId: // value for 'ownerId'
 *      description: // value for 'description'
 *      contactIds: // value for 'contactIds'
 *   },
 * });
 */
export function useAddApplicationMutation(baseOptions?: Apollo.MutationHookOptions<AddApplicationMutation, AddApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApplicationMutation, AddApplicationMutationVariables>(AddApplicationDocument, options);
      }
export type AddApplicationMutationHookResult = ReturnType<typeof useAddApplicationMutation>;
export type AddApplicationMutationResult = Apollo.MutationResult<AddApplicationMutation>;
export type AddApplicationMutationOptions = Apollo.BaseMutationOptions<AddApplicationMutation, AddApplicationMutationVariables>;
export const UpdateApplicationDocument = gql`
    mutation updateApplication($input: UpdateApplication!) {
  updateApplication(input: $input)
}
    `;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<UpdateApplicationMutation, UpdateApplicationMutationVariables>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument, options);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export const UpdateApplicationV2Document = gql`
    mutation updateApplicationV2($nid: ID!, $input: UpdateApplicationV2!) {
  updateApplicationV2(nid: $nid, input: $input)
}
    `;
export type UpdateApplicationV2MutationFn = Apollo.MutationFunction<UpdateApplicationV2Mutation, UpdateApplicationV2MutationVariables>;

/**
 * __useUpdateApplicationV2Mutation__
 *
 * To run a mutation, you first call `useUpdateApplicationV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationV2Mutation, { data, loading, error }] = useUpdateApplicationV2Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationV2Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationV2Mutation, UpdateApplicationV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationV2Mutation, UpdateApplicationV2MutationVariables>(UpdateApplicationV2Document, options);
      }
export type UpdateApplicationV2MutationHookResult = ReturnType<typeof useUpdateApplicationV2Mutation>;
export type UpdateApplicationV2MutationResult = Apollo.MutationResult<UpdateApplicationV2Mutation>;
export type UpdateApplicationV2MutationOptions = Apollo.BaseMutationOptions<UpdateApplicationV2Mutation, UpdateApplicationV2MutationVariables>;
export const UpdateEnvironmentV2Document = gql`
    mutation updateEnvironmentV2($nid: ID!, $input: UpdateEnvironmentV2!) {
  updateEnvironmentV2(nid: $nid, input: $input)
}
    `;
export type UpdateEnvironmentV2MutationFn = Apollo.MutationFunction<UpdateEnvironmentV2Mutation, UpdateEnvironmentV2MutationVariables>;

/**
 * __useUpdateEnvironmentV2Mutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentV2Mutation, { data, loading, error }] = useUpdateEnvironmentV2Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnvironmentV2Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentV2Mutation, UpdateEnvironmentV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentV2Mutation, UpdateEnvironmentV2MutationVariables>(UpdateEnvironmentV2Document, options);
      }
export type UpdateEnvironmentV2MutationHookResult = ReturnType<typeof useUpdateEnvironmentV2Mutation>;
export type UpdateEnvironmentV2MutationResult = Apollo.MutationResult<UpdateEnvironmentV2Mutation>;
export type UpdateEnvironmentV2MutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentV2Mutation, UpdateEnvironmentV2MutationVariables>;
export const RemoveApplicationDocument = gql`
    mutation removeApplication($id: String!) {
  removeApplication(id: $id)
}
    `;
export type RemoveApplicationMutationFn = Apollo.MutationFunction<RemoveApplicationMutation, RemoveApplicationMutationVariables>;

/**
 * __useRemoveApplicationMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationMutation, { data, loading, error }] = useRemoveApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApplicationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveApplicationMutation, RemoveApplicationMutationVariables>(RemoveApplicationDocument, options);
      }
export type RemoveApplicationMutationHookResult = ReturnType<typeof useRemoveApplicationMutation>;
export type RemoveApplicationMutationResult = Apollo.MutationResult<RemoveApplicationMutation>;
export type RemoveApplicationMutationOptions = Apollo.BaseMutationOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>;
export const RemoveApplicationV2Document = gql`
    mutation removeApplicationV2($nid: ID!) {
  removeApplicationV2(nid: $nid)
}
    `;
export type RemoveApplicationV2MutationFn = Apollo.MutationFunction<RemoveApplicationV2Mutation, RemoveApplicationV2MutationVariables>;

/**
 * __useRemoveApplicationV2Mutation__
 *
 * To run a mutation, you first call `useRemoveApplicationV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationV2Mutation, { data, loading, error }] = useRemoveApplicationV2Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useRemoveApplicationV2Mutation(baseOptions?: Apollo.MutationHookOptions<RemoveApplicationV2Mutation, RemoveApplicationV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveApplicationV2Mutation, RemoveApplicationV2MutationVariables>(RemoveApplicationV2Document, options);
      }
export type RemoveApplicationV2MutationHookResult = ReturnType<typeof useRemoveApplicationV2Mutation>;
export type RemoveApplicationV2MutationResult = Apollo.MutationResult<RemoveApplicationV2Mutation>;
export type RemoveApplicationV2MutationOptions = Apollo.BaseMutationOptions<RemoveApplicationV2Mutation, RemoveApplicationV2MutationVariables>;
export const AddEnvironmentDocument = gql`
    mutation AddEnvironment($input: AddEnvironment!) {
  addEnvironment(input: $input) {
    id
  }
}
    `;
export type AddEnvironmentMutationFn = Apollo.MutationFunction<AddEnvironmentMutation, AddEnvironmentMutationVariables>;

/**
 * __useAddEnvironmentMutation__
 *
 * To run a mutation, you first call `useAddEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnvironmentMutation, { data, loading, error }] = useAddEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<AddEnvironmentMutation, AddEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEnvironmentMutation, AddEnvironmentMutationVariables>(AddEnvironmentDocument, options);
      }
export type AddEnvironmentMutationHookResult = ReturnType<typeof useAddEnvironmentMutation>;
export type AddEnvironmentMutationResult = Apollo.MutationResult<AddEnvironmentMutation>;
export type AddEnvironmentMutationOptions = Apollo.BaseMutationOptions<AddEnvironmentMutation, AddEnvironmentMutationVariables>;
export const RemoveEnvironmentDocument = gql`
    mutation removeEnvironment($id: String!) {
  removeEnvironment(id: $id)
}
    `;
export type RemoveEnvironmentMutationFn = Apollo.MutationFunction<RemoveEnvironmentMutation, RemoveEnvironmentMutationVariables>;

/**
 * __useRemoveEnvironmentMutation__
 *
 * To run a mutation, you first call `useRemoveEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnvironmentMutation, { data, loading, error }] = useRemoveEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEnvironmentMutation, RemoveEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEnvironmentMutation, RemoveEnvironmentMutationVariables>(RemoveEnvironmentDocument, options);
      }
export type RemoveEnvironmentMutationHookResult = ReturnType<typeof useRemoveEnvironmentMutation>;
export type RemoveEnvironmentMutationResult = Apollo.MutationResult<RemoveEnvironmentMutation>;
export type RemoveEnvironmentMutationOptions = Apollo.BaseMutationOptions<RemoveEnvironmentMutation, RemoveEnvironmentMutationVariables>;
export const RemoveEnvironmentV2Document = gql`
    mutation removeEnvironmentV2($nid: ID!) {
  removeEnvironmentV2(nid: $nid)
}
    `;
export type RemoveEnvironmentV2MutationFn = Apollo.MutationFunction<RemoveEnvironmentV2Mutation, RemoveEnvironmentV2MutationVariables>;

/**
 * __useRemoveEnvironmentV2Mutation__
 *
 * To run a mutation, you first call `useRemoveEnvironmentV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnvironmentV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnvironmentV2Mutation, { data, loading, error }] = useRemoveEnvironmentV2Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useRemoveEnvironmentV2Mutation(baseOptions?: Apollo.MutationHookOptions<RemoveEnvironmentV2Mutation, RemoveEnvironmentV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEnvironmentV2Mutation, RemoveEnvironmentV2MutationVariables>(RemoveEnvironmentV2Document, options);
      }
export type RemoveEnvironmentV2MutationHookResult = ReturnType<typeof useRemoveEnvironmentV2Mutation>;
export type RemoveEnvironmentV2MutationResult = Apollo.MutationResult<RemoveEnvironmentV2Mutation>;
export type RemoveEnvironmentV2MutationOptions = Apollo.BaseMutationOptions<RemoveEnvironmentV2Mutation, RemoveEnvironmentV2MutationVariables>;
export const UpdateEnvironmentDocument = gql`
    mutation updateEnvironment($input: UpdateEnvironment!) {
  updateEnvironment(input: $input)
}
    `;
export type UpdateEnvironmentMutationFn = Apollo.MutationFunction<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;

/**
 * __useUpdateEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentMutation, { data, loading, error }] = useUpdateEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>(UpdateEnvironmentDocument, options);
      }
export type UpdateEnvironmentMutationHookResult = ReturnType<typeof useUpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationResult = Apollo.MutationResult<UpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;
export const ReservedInstancesDocument = gql`
    mutation reservedInstances($mark: [MarkReservedInstance!], $unmark: [UnmarkReservedInstance!]) {
  reservedInstances(mark: $mark, unmark: $unmark)
}
    `;
export type ReservedInstancesMutationFn = Apollo.MutationFunction<ReservedInstancesMutation, ReservedInstancesMutationVariables>;

/**
 * __useReservedInstancesMutation__
 *
 * To run a mutation, you first call `useReservedInstancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReservedInstancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reservedInstancesMutation, { data, loading, error }] = useReservedInstancesMutation({
 *   variables: {
 *      mark: // value for 'mark'
 *      unmark: // value for 'unmark'
 *   },
 * });
 */
export function useReservedInstancesMutation(baseOptions?: Apollo.MutationHookOptions<ReservedInstancesMutation, ReservedInstancesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReservedInstancesMutation, ReservedInstancesMutationVariables>(ReservedInstancesDocument, options);
      }
export type ReservedInstancesMutationHookResult = ReturnType<typeof useReservedInstancesMutation>;
export type ReservedInstancesMutationResult = Apollo.MutationResult<ReservedInstancesMutation>;
export type ReservedInstancesMutationOptions = Apollo.BaseMutationOptions<ReservedInstancesMutation, ReservedInstancesMutationVariables>;
export const ReservableResourcesDocument = gql`
    query ReservableResources($environmentId: String!, $limit: Int!, $page: Int!) {
  reservableResources(environmentId: $environmentId, limit: $limit, page: $page) {
    pages
    count
    resources {
      id
      provider
      providerId
      providerType
      name
      region
      cloudAccountId
      reservation {
        from
        to
      }
    }
  }
}
    `;

/**
 * __useReservableResourcesQuery__
 *
 * To run a query within a React component, call `useReservableResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservableResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservableResourcesQuery({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReservableResourcesQuery(baseOptions: Apollo.QueryHookOptions<ReservableResourcesQuery, ReservableResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservableResourcesQuery, ReservableResourcesQueryVariables>(ReservableResourcesDocument, options);
      }
export function useReservableResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservableResourcesQuery, ReservableResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservableResourcesQuery, ReservableResourcesQueryVariables>(ReservableResourcesDocument, options);
        }
export type ReservableResourcesQueryHookResult = ReturnType<typeof useReservableResourcesQuery>;
export type ReservableResourcesLazyQueryHookResult = ReturnType<typeof useReservableResourcesLazyQuery>;
export type ReservableResourcesQueryResult = Apollo.QueryResult<ReservableResourcesQuery, ReservableResourcesQueryVariables>;
export const GetDefaultApplicationDocument = gql`
    query getDefaultApplication {
  search(phrase: "default", filter: {type: APPLICATION}, limit: 1, page: 0) {
    results {
      ... on Application {
        name
        id
        nid
        cloudWasteAggregate {
          hasWaste
          cost
          count
        }
        cost {
          currentMonth
          previousMonth
          forecast
          dirty
        }
        environments {
          id
          name
          cost {
            currentMonth
            previousMonth
            forecast
            dirty
          }
          resourceCount
          orgUnitsV2(limit: 50, page: 0) {
            orgUnits {
              nid
              name
              businessContext {
                color
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDefaultApplicationQuery__
 *
 * To run a query within a React component, call `useGetDefaultApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultApplicationQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>(GetDefaultApplicationDocument, options);
      }
export function useGetDefaultApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>(GetDefaultApplicationDocument, options);
        }
export type GetDefaultApplicationQueryHookResult = ReturnType<typeof useGetDefaultApplicationQuery>;
export type GetDefaultApplicationLazyQueryHookResult = ReturnType<typeof useGetDefaultApplicationLazyQuery>;
export type GetDefaultApplicationQueryResult = Apollo.QueryResult<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>;
export const SetApplicationBudgetDocument = gql`
    mutation setApplicationBudget($nid: ID!, $budget: BudgetInput!) {
  setApplicationBudget(nid: $nid, budget: $budget)
}
    `;
export type SetApplicationBudgetMutationFn = Apollo.MutationFunction<SetApplicationBudgetMutation, SetApplicationBudgetMutationVariables>;

/**
 * __useSetApplicationBudgetMutation__
 *
 * To run a mutation, you first call `useSetApplicationBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApplicationBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApplicationBudgetMutation, { data, loading, error }] = useSetApplicationBudgetMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      budget: // value for 'budget'
 *   },
 * });
 */
export function useSetApplicationBudgetMutation(baseOptions?: Apollo.MutationHookOptions<SetApplicationBudgetMutation, SetApplicationBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApplicationBudgetMutation, SetApplicationBudgetMutationVariables>(SetApplicationBudgetDocument, options);
      }
export type SetApplicationBudgetMutationHookResult = ReturnType<typeof useSetApplicationBudgetMutation>;
export type SetApplicationBudgetMutationResult = Apollo.MutationResult<SetApplicationBudgetMutation>;
export type SetApplicationBudgetMutationOptions = Apollo.BaseMutationOptions<SetApplicationBudgetMutation, SetApplicationBudgetMutationVariables>;
export const SetEnvironmentBudgetDocument = gql`
    mutation setEnvironmentBudget($nid: ID!, $budget: BudgetInput!) {
  setEnvironmentBudget(nid: $nid, budget: $budget)
}
    `;
export type SetEnvironmentBudgetMutationFn = Apollo.MutationFunction<SetEnvironmentBudgetMutation, SetEnvironmentBudgetMutationVariables>;

/**
 * __useSetEnvironmentBudgetMutation__
 *
 * To run a mutation, you first call `useSetEnvironmentBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnvironmentBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnvironmentBudgetMutation, { data, loading, error }] = useSetEnvironmentBudgetMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      budget: // value for 'budget'
 *   },
 * });
 */
export function useSetEnvironmentBudgetMutation(baseOptions?: Apollo.MutationHookOptions<SetEnvironmentBudgetMutation, SetEnvironmentBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEnvironmentBudgetMutation, SetEnvironmentBudgetMutationVariables>(SetEnvironmentBudgetDocument, options);
      }
export type SetEnvironmentBudgetMutationHookResult = ReturnType<typeof useSetEnvironmentBudgetMutation>;
export type SetEnvironmentBudgetMutationResult = Apollo.MutationResult<SetEnvironmentBudgetMutation>;
export type SetEnvironmentBudgetMutationOptions = Apollo.BaseMutationOptions<SetEnvironmentBudgetMutation, SetEnvironmentBudgetMutationVariables>;
export const UnsetApplicationBudgetDocument = gql`
    mutation unsetApplicationBudget($nid: ID!) {
  unsetApplicationBudget(nid: $nid)
}
    `;
export type UnsetApplicationBudgetMutationFn = Apollo.MutationFunction<UnsetApplicationBudgetMutation, UnsetApplicationBudgetMutationVariables>;

/**
 * __useUnsetApplicationBudgetMutation__
 *
 * To run a mutation, you first call `useUnsetApplicationBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetApplicationBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetApplicationBudgetMutation, { data, loading, error }] = useUnsetApplicationBudgetMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useUnsetApplicationBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UnsetApplicationBudgetMutation, UnsetApplicationBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetApplicationBudgetMutation, UnsetApplicationBudgetMutationVariables>(UnsetApplicationBudgetDocument, options);
      }
export type UnsetApplicationBudgetMutationHookResult = ReturnType<typeof useUnsetApplicationBudgetMutation>;
export type UnsetApplicationBudgetMutationResult = Apollo.MutationResult<UnsetApplicationBudgetMutation>;
export type UnsetApplicationBudgetMutationOptions = Apollo.BaseMutationOptions<UnsetApplicationBudgetMutation, UnsetApplicationBudgetMutationVariables>;
export const GetMonthlyReportDocument = gql`
    query GetMonthlyReport($period: String!, $reportType: ReportType!, $businessContextId: String) {
  monthlyReport(
    input: {period: $period, reportType: $reportType, businessContextId: $businessContextId}
  ) {
    status
    fileUrl
    error
    jobId
    reportType
  }
}
    `;

/**
 * __useGetMonthlyReportQuery__
 *
 * To run a query within a React component, call `useGetMonthlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyReportQuery({
 *   variables: {
 *      period: // value for 'period'
 *      reportType: // value for 'reportType'
 *      businessContextId: // value for 'businessContextId'
 *   },
 * });
 */
export function useGetMonthlyReportQuery(baseOptions: Apollo.QueryHookOptions<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>(GetMonthlyReportDocument, options);
      }
export function useGetMonthlyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>(GetMonthlyReportDocument, options);
        }
export type GetMonthlyReportQueryHookResult = ReturnType<typeof useGetMonthlyReportQuery>;
export type GetMonthlyReportLazyQueryHookResult = ReturnType<typeof useGetMonthlyReportLazyQuery>;
export type GetMonthlyReportQueryResult = Apollo.QueryResult<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>;
export const CustomerCreatedAtDocument = gql`
    query customerCreatedAt {
  organization {
    id
    createdAt
  }
}
    `;

/**
 * __useCustomerCreatedAtQuery__
 *
 * To run a query within a React component, call `useCustomerCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCreatedAtQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerCreatedAtQuery(baseOptions?: Apollo.QueryHookOptions<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>(CustomerCreatedAtDocument, options);
      }
export function useCustomerCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>(CustomerCreatedAtDocument, options);
        }
export type CustomerCreatedAtQueryHookResult = ReturnType<typeof useCustomerCreatedAtQuery>;
export type CustomerCreatedAtLazyQueryHookResult = ReturnType<typeof useCustomerCreatedAtLazyQuery>;
export type CustomerCreatedAtQueryResult = Apollo.QueryResult<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>;
export const BusinessContextsMinimalDocument = gql`
    query BusinessContextsMinimal($limit: Int!, $page: Int!) {
  businessContexts(limit: $limit, page: $page) {
    businessContexts {
      id
      name
    }
    pages
    count
  }
}
    `;

/**
 * __useBusinessContextsMinimalQuery__
 *
 * To run a query within a React component, call `useBusinessContextsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextsMinimalQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBusinessContextsMinimalQuery(baseOptions: Apollo.QueryHookOptions<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>(BusinessContextsMinimalDocument, options);
      }
export function useBusinessContextsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>(BusinessContextsMinimalDocument, options);
        }
export type BusinessContextsMinimalQueryHookResult = ReturnType<typeof useBusinessContextsMinimalQuery>;
export type BusinessContextsMinimalLazyQueryHookResult = ReturnType<typeof useBusinessContextsMinimalLazyQuery>;
export type BusinessContextsMinimalQueryResult = Apollo.QueryResult<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>;
export const OrgUnitTypesV2Document = gql`
    query orgUnitTypesV2($limit: Int!, $page: Int!) {
  orgUnitTypesV2(limit: $limit, page: $page) {
    types {
      id
      name
      description
    }
    pages
    count
  }
}
    `;

/**
 * __useOrgUnitTypesV2Query__
 *
 * To run a query within a React component, call `useOrgUnitTypesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitTypesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitTypesV2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrgUnitTypesV2Query(baseOptions: Apollo.QueryHookOptions<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>(OrgUnitTypesV2Document, options);
      }
export function useOrgUnitTypesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>(OrgUnitTypesV2Document, options);
        }
export type OrgUnitTypesV2QueryHookResult = ReturnType<typeof useOrgUnitTypesV2Query>;
export type OrgUnitTypesV2LazyQueryHookResult = ReturnType<typeof useOrgUnitTypesV2LazyQuery>;
export type OrgUnitTypesV2QueryResult = Apollo.QueryResult<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>;
export const AddOrgUnitTypeV2Document = gql`
    mutation addOrgUnitTypeV2($input: AddOrgUnitTypeV2!) {
  addOrgUnitTypeV2(input: $input) {
    id
  }
}
    `;
export type AddOrgUnitTypeV2MutationFn = Apollo.MutationFunction<AddOrgUnitTypeV2Mutation, AddOrgUnitTypeV2MutationVariables>;

/**
 * __useAddOrgUnitTypeV2Mutation__
 *
 * To run a mutation, you first call `useAddOrgUnitTypeV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgUnitTypeV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgUnitTypeV2Mutation, { data, loading, error }] = useAddOrgUnitTypeV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrgUnitTypeV2Mutation(baseOptions?: Apollo.MutationHookOptions<AddOrgUnitTypeV2Mutation, AddOrgUnitTypeV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrgUnitTypeV2Mutation, AddOrgUnitTypeV2MutationVariables>(AddOrgUnitTypeV2Document, options);
      }
export type AddOrgUnitTypeV2MutationHookResult = ReturnType<typeof useAddOrgUnitTypeV2Mutation>;
export type AddOrgUnitTypeV2MutationResult = Apollo.MutationResult<AddOrgUnitTypeV2Mutation>;
export type AddOrgUnitTypeV2MutationOptions = Apollo.BaseMutationOptions<AddOrgUnitTypeV2Mutation, AddOrgUnitTypeV2MutationVariables>;
export const RemoveOrgUnitTypeV2Document = gql`
    mutation removeOrgUnitTypeV2($id: ID!) {
  removeOrgUnitTypeV2(id: $id)
}
    `;
export type RemoveOrgUnitTypeV2MutationFn = Apollo.MutationFunction<RemoveOrgUnitTypeV2Mutation, RemoveOrgUnitTypeV2MutationVariables>;

/**
 * __useRemoveOrgUnitTypeV2Mutation__
 *
 * To run a mutation, you first call `useRemoveOrgUnitTypeV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgUnitTypeV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgUnitTypeV2Mutation, { data, loading, error }] = useRemoveOrgUnitTypeV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrgUnitTypeV2Mutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrgUnitTypeV2Mutation, RemoveOrgUnitTypeV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrgUnitTypeV2Mutation, RemoveOrgUnitTypeV2MutationVariables>(RemoveOrgUnitTypeV2Document, options);
      }
export type RemoveOrgUnitTypeV2MutationHookResult = ReturnType<typeof useRemoveOrgUnitTypeV2Mutation>;
export type RemoveOrgUnitTypeV2MutationResult = Apollo.MutationResult<RemoveOrgUnitTypeV2Mutation>;
export type RemoveOrgUnitTypeV2MutationOptions = Apollo.BaseMutationOptions<RemoveOrgUnitTypeV2Mutation, RemoveOrgUnitTypeV2MutationVariables>;
export const UpdateOrgUnitTypeV2Document = gql`
    mutation updateOrgUnitTypeV2($id: ID!, $input: UpdateOrgUnitTypeV2!) {
  updateOrgUnitTypeV2(id: $id, input: $input)
}
    `;
export type UpdateOrgUnitTypeV2MutationFn = Apollo.MutationFunction<UpdateOrgUnitTypeV2Mutation, UpdateOrgUnitTypeV2MutationVariables>;

/**
 * __useUpdateOrgUnitTypeV2Mutation__
 *
 * To run a mutation, you first call `useUpdateOrgUnitTypeV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgUnitTypeV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgUnitTypeV2Mutation, { data, loading, error }] = useUpdateOrgUnitTypeV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgUnitTypeV2Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgUnitTypeV2Mutation, UpdateOrgUnitTypeV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgUnitTypeV2Mutation, UpdateOrgUnitTypeV2MutationVariables>(UpdateOrgUnitTypeV2Document, options);
      }
export type UpdateOrgUnitTypeV2MutationHookResult = ReturnType<typeof useUpdateOrgUnitTypeV2Mutation>;
export type UpdateOrgUnitTypeV2MutationResult = Apollo.MutationResult<UpdateOrgUnitTypeV2Mutation>;
export type UpdateOrgUnitTypeV2MutationOptions = Apollo.BaseMutationOptions<UpdateOrgUnitTypeV2Mutation, UpdateOrgUnitTypeV2MutationVariables>;
export const GetOrgUnitWithChildrenDocument = gql`
    query GetOrgUnitWithChildren($id: ID!) {
  orgUnitWithChildren(nid: $id) {
    nid
    name
    description
    businessContext {
      id
      name
    }
    type {
      name
    }
    contact {
      name
      email
    }
    cost {
      currentMonth
      previousMonth
      forecast
      dirty
    }
    environments(limit: 100, page: 0) {
      environments {
        id
        nid
        name
        description
        cost {
          currentMonth
          previousMonth
          forecast
          dirty
        }
        budget {
          amount
          currency
        }
        application {
          id
          nid
          name
          cost {
            currentMonth
            previousMonth
            forecast
          }
          environmentsV2(limit: 100, page: 0) {
            environments {
              id
              name
              budget {
                currency
                amount
              }
              cost {
                currentMonth
                previousMonth
                forecast
                dirty
              }
              orgUnitsV2(limit: 100, page: 0) {
                orgUnits {
                  nid
                  name
                }
              }
              resourceCount
            }
          }
        }
      }
      count
      pages
    }
    children {
      nid
      name
      businessContext {
        id
      }
      cost {
        currentMonth
        previousMonth
        forecast
        dirty
      }
      environments(limit: 100, page: 0) {
        environments {
          cost {
            currentMonth
            previousMonth
            forecast
            dirty
          }
          budget {
            amount
            currency
          }
        }
        count
      }
    }
  }
}
    `;

/**
 * __useGetOrgUnitWithChildrenQuery__
 *
 * To run a query within a React component, call `useGetOrgUnitWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnitWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnitWithChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgUnitWithChildrenQuery(baseOptions: Apollo.QueryHookOptions<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>(GetOrgUnitWithChildrenDocument, options);
      }
export function useGetOrgUnitWithChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>(GetOrgUnitWithChildrenDocument, options);
        }
export type GetOrgUnitWithChildrenQueryHookResult = ReturnType<typeof useGetOrgUnitWithChildrenQuery>;
export type GetOrgUnitWithChildrenLazyQueryHookResult = ReturnType<typeof useGetOrgUnitWithChildrenLazyQuery>;
export type GetOrgUnitWithChildrenQueryResult = Apollo.QueryResult<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>;
export const GetAppsWithEnvironmentsDocument = gql`
    query getAppsWithEnvironments {
  applications {
    name
    id
    nid
    environments {
      name
      id
      nid
      resourceCount
      orgUnitsV2(limit: 100, page: 0) {
        orgUnits {
          nid
          name
          businessContext {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAppsWithEnvironmentsQuery__
 *
 * To run a query within a React component, call `useGetAppsWithEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsWithEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsWithEnvironmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppsWithEnvironmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>(GetAppsWithEnvironmentsDocument, options);
      }
export function useGetAppsWithEnvironmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>(GetAppsWithEnvironmentsDocument, options);
        }
export type GetAppsWithEnvironmentsQueryHookResult = ReturnType<typeof useGetAppsWithEnvironmentsQuery>;
export type GetAppsWithEnvironmentsLazyQueryHookResult = ReturnType<typeof useGetAppsWithEnvironmentsLazyQuery>;
export type GetAppsWithEnvironmentsQueryResult = Apollo.QueryResult<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>;
export const OrgUnitBaseDetailsDocument = gql`
    query OrgUnitBaseDetails($id: ID!) {
  orgUnitV2(nid: $id) {
    nid
    name
    description
    type {
      name
    }
    contact {
      id
      name
    }
  }
}
    `;

/**
 * __useOrgUnitBaseDetailsQuery__
 *
 * To run a query within a React component, call `useOrgUnitBaseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitBaseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitBaseDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgUnitBaseDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>(OrgUnitBaseDetailsDocument, options);
      }
export function useOrgUnitBaseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>(OrgUnitBaseDetailsDocument, options);
        }
export type OrgUnitBaseDetailsQueryHookResult = ReturnType<typeof useOrgUnitBaseDetailsQuery>;
export type OrgUnitBaseDetailsLazyQueryHookResult = ReturnType<typeof useOrgUnitBaseDetailsLazyQuery>;
export type OrgUnitBaseDetailsQueryResult = Apollo.QueryResult<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>;
export const GetOrgUnitTypeDocument = gql`
    query GetOrgUnitType($id: ID!) {
  orgUnitV2(nid: $id) {
    type {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOrgUnitTypeQuery__
 *
 * To run a query within a React component, call `useGetOrgUnitTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnitTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnitTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgUnitTypeQuery(baseOptions: Apollo.QueryHookOptions<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>(GetOrgUnitTypeDocument, options);
      }
export function useGetOrgUnitTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>(GetOrgUnitTypeDocument, options);
        }
export type GetOrgUnitTypeQueryHookResult = ReturnType<typeof useGetOrgUnitTypeQuery>;
export type GetOrgUnitTypeLazyQueryHookResult = ReturnType<typeof useGetOrgUnitTypeLazyQuery>;
export type GetOrgUnitTypeQueryResult = Apollo.QueryResult<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>;
export const UpdateBusinessContextDocument = gql`
    mutation UpdateBusinessContext($id: String!, $input: UpdateBusinessContext!) {
  updateBusinessContext(id: $id, input: $input)
}
    `;
export type UpdateBusinessContextMutationFn = Apollo.MutationFunction<UpdateBusinessContextMutation, UpdateBusinessContextMutationVariables>;

/**
 * __useUpdateBusinessContextMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessContextMutation, { data, loading, error }] = useUpdateBusinessContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessContextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessContextMutation, UpdateBusinessContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessContextMutation, UpdateBusinessContextMutationVariables>(UpdateBusinessContextDocument, options);
      }
export type UpdateBusinessContextMutationHookResult = ReturnType<typeof useUpdateBusinessContextMutation>;
export type UpdateBusinessContextMutationResult = Apollo.MutationResult<UpdateBusinessContextMutation>;
export type UpdateBusinessContextMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessContextMutation, UpdateBusinessContextMutationVariables>;
export const RemoveBusinessContextDocument = gql`
    mutation RemoveBusinessContext($id: String!) {
  removeBusinessContext(id: $id)
}
    `;
export type RemoveBusinessContextMutationFn = Apollo.MutationFunction<RemoveBusinessContextMutation, RemoveBusinessContextMutationVariables>;

/**
 * __useRemoveBusinessContextMutation__
 *
 * To run a mutation, you first call `useRemoveBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBusinessContextMutation, { data, loading, error }] = useRemoveBusinessContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBusinessContextMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBusinessContextMutation, RemoveBusinessContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBusinessContextMutation, RemoveBusinessContextMutationVariables>(RemoveBusinessContextDocument, options);
      }
export type RemoveBusinessContextMutationHookResult = ReturnType<typeof useRemoveBusinessContextMutation>;
export type RemoveBusinessContextMutationResult = Apollo.MutationResult<RemoveBusinessContextMutation>;
export type RemoveBusinessContextMutationOptions = Apollo.BaseMutationOptions<RemoveBusinessContextMutation, RemoveBusinessContextMutationVariables>;
export const AddOrgUnitV2Document = gql`
    mutation AddOrgUnitV2($input: AddOrgUnitV2!) {
  addOrgUnitV2(input: $input) {
    id
  }
}
    `;
export type AddOrgUnitV2MutationFn = Apollo.MutationFunction<AddOrgUnitV2Mutation, AddOrgUnitV2MutationVariables>;

/**
 * __useAddOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useAddOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgUnitV2Mutation, { data, loading, error }] = useAddOrgUnitV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrgUnitV2Mutation(baseOptions?: Apollo.MutationHookOptions<AddOrgUnitV2Mutation, AddOrgUnitV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrgUnitV2Mutation, AddOrgUnitV2MutationVariables>(AddOrgUnitV2Document, options);
      }
export type AddOrgUnitV2MutationHookResult = ReturnType<typeof useAddOrgUnitV2Mutation>;
export type AddOrgUnitV2MutationResult = Apollo.MutationResult<AddOrgUnitV2Mutation>;
export type AddOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<AddOrgUnitV2Mutation, AddOrgUnitV2MutationVariables>;
export const RemoveOrgUnitV2Document = gql`
    mutation removeOrgUnitV2($id: ID!) {
  removeOrgUnitV2(nid: $id)
}
    `;
export type RemoveOrgUnitV2MutationFn = Apollo.MutationFunction<RemoveOrgUnitV2Mutation, RemoveOrgUnitV2MutationVariables>;

/**
 * __useRemoveOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useRemoveOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgUnitV2Mutation, { data, loading, error }] = useRemoveOrgUnitV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrgUnitV2Mutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrgUnitV2Mutation, RemoveOrgUnitV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrgUnitV2Mutation, RemoveOrgUnitV2MutationVariables>(RemoveOrgUnitV2Document, options);
      }
export type RemoveOrgUnitV2MutationHookResult = ReturnType<typeof useRemoveOrgUnitV2Mutation>;
export type RemoveOrgUnitV2MutationResult = Apollo.MutationResult<RemoveOrgUnitV2Mutation>;
export type RemoveOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<RemoveOrgUnitV2Mutation, RemoveOrgUnitV2MutationVariables>;
export const UpdateOrgUnitV2Document = gql`
    mutation updateOrgUnitV2($nid: ID!, $input: UpdateOrgUnitV2!) {
  updateOrgUnitV2(nid: $nid, input: $input)
}
    `;
export type UpdateOrgUnitV2MutationFn = Apollo.MutationFunction<UpdateOrgUnitV2Mutation, UpdateOrgUnitV2MutationVariables>;

/**
 * __useUpdateOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useUpdateOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgUnitV2Mutation, { data, loading, error }] = useUpdateOrgUnitV2Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgUnitV2Mutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgUnitV2Mutation, UpdateOrgUnitV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgUnitV2Mutation, UpdateOrgUnitV2MutationVariables>(UpdateOrgUnitV2Document, options);
      }
export type UpdateOrgUnitV2MutationHookResult = ReturnType<typeof useUpdateOrgUnitV2Mutation>;
export type UpdateOrgUnitV2MutationResult = Apollo.MutationResult<UpdateOrgUnitV2Mutation>;
export type UpdateOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<UpdateOrgUnitV2Mutation, UpdateOrgUnitV2MutationVariables>;
export const AddBusinessContextDocument = gql`
    mutation AddBusinessContext($input: AddBusinessContext!) {
  addBusinessContext(input: $input) {
    id
  }
}
    `;
export type AddBusinessContextMutationFn = Apollo.MutationFunction<AddBusinessContextMutation, AddBusinessContextMutationVariables>;

/**
 * __useAddBusinessContextMutation__
 *
 * To run a mutation, you first call `useAddBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBusinessContextMutation, { data, loading, error }] = useAddBusinessContextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBusinessContextMutation(baseOptions?: Apollo.MutationHookOptions<AddBusinessContextMutation, AddBusinessContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBusinessContextMutation, AddBusinessContextMutationVariables>(AddBusinessContextDocument, options);
      }
export type AddBusinessContextMutationHookResult = ReturnType<typeof useAddBusinessContextMutation>;
export type AddBusinessContextMutationResult = Apollo.MutationResult<AddBusinessContextMutation>;
export type AddBusinessContextMutationOptions = Apollo.BaseMutationOptions<AddBusinessContextMutation, AddBusinessContextMutationVariables>;
export const BusinessContextsDocument = gql`
    query BusinessContexts($limit: Int!, $page: Int!) {
  businessContexts(limit: $limit, page: $page) {
    businessContexts {
      id
      name
      color
    }
    pages
    count
  }
}
    `;

/**
 * __useBusinessContextsQuery__
 *
 * To run a query within a React component, call `useBusinessContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBusinessContextsQuery(baseOptions: Apollo.QueryHookOptions<BusinessContextsQuery, BusinessContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessContextsQuery, BusinessContextsQueryVariables>(BusinessContextsDocument, options);
      }
export function useBusinessContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessContextsQuery, BusinessContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessContextsQuery, BusinessContextsQueryVariables>(BusinessContextsDocument, options);
        }
export type BusinessContextsQueryHookResult = ReturnType<typeof useBusinessContextsQuery>;
export type BusinessContextsLazyQueryHookResult = ReturnType<typeof useBusinessContextsLazyQuery>;
export type BusinessContextsQueryResult = Apollo.QueryResult<BusinessContextsQuery, BusinessContextsQueryVariables>;
export const BusinessContextDocument = gql`
    query BusinessContext($id: ID!) {
  businessContext(id: $id) {
    id
    name
    description
    color
    costAllocation {
      allocated
      unallocated
    }
    orgUnits {
      orgUnits {
        nid
        name
        description
        parentNid
        environments(limit: 1, page: 0) {
          count
        }
        businessContext {
          id
        }
        cost {
          currentMonth
          previousMonth
          forecast
          dirty
        }
        type {
          id
          name
        }
        contact {
          id
          name
        }
      }
      count
    }
  }
}
    `;

/**
 * __useBusinessContextQuery__
 *
 * To run a query within a React component, call `useBusinessContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessContextQuery(baseOptions: Apollo.QueryHookOptions<BusinessContextQuery, BusinessContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessContextQuery, BusinessContextQueryVariables>(BusinessContextDocument, options);
      }
export function useBusinessContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessContextQuery, BusinessContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessContextQuery, BusinessContextQueryVariables>(BusinessContextDocument, options);
        }
export type BusinessContextQueryHookResult = ReturnType<typeof useBusinessContextQuery>;
export type BusinessContextLazyQueryHookResult = ReturnType<typeof useBusinessContextLazyQuery>;
export type BusinessContextQueryResult = Apollo.QueryResult<BusinessContextQuery, BusinessContextQueryVariables>;
export const GetOrgUnitTypesV2Document = gql`
    query GetOrgUnitTypesV2 {
  orgUnitTypesV2(limit: 100, page: 0) {
    types {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOrgUnitTypesV2Query__
 *
 * To run a query within a React component, call `useGetOrgUnitTypesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnitTypesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnitTypesV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgUnitTypesV2Query(baseOptions?: Apollo.QueryHookOptions<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>(GetOrgUnitTypesV2Document, options);
      }
export function useGetOrgUnitTypesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>(GetOrgUnitTypesV2Document, options);
        }
export type GetOrgUnitTypesV2QueryHookResult = ReturnType<typeof useGetOrgUnitTypesV2Query>;
export type GetOrgUnitTypesV2LazyQueryHookResult = ReturnType<typeof useGetOrgUnitTypesV2LazyQuery>;
export type GetOrgUnitTypesV2QueryResult = Apollo.QueryResult<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>;
export const DriftRecordsDocument = gql`
    query DriftRecords($limit: Int!, $page: Int!) {
  estate(filter: {drifted: true}, limit: $limit, page: $page) {
    count
    pages
    resources {
      ... on CloudResource {
        id
        provider
        name
        providerType
        drifted
        environment {
          name
          id
          application {
            name
            id
          }
        }
        pendingAssignment {
          name
          id
          application {
            name
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDriftRecordsQuery__
 *
 * To run a query within a React component, call `useDriftRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriftRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriftRecordsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDriftRecordsQuery(baseOptions: Apollo.QueryHookOptions<DriftRecordsQuery, DriftRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriftRecordsQuery, DriftRecordsQueryVariables>(DriftRecordsDocument, options);
      }
export function useDriftRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriftRecordsQuery, DriftRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriftRecordsQuery, DriftRecordsQueryVariables>(DriftRecordsDocument, options);
        }
export type DriftRecordsQueryHookResult = ReturnType<typeof useDriftRecordsQuery>;
export type DriftRecordsLazyQueryHookResult = ReturnType<typeof useDriftRecordsLazyQuery>;
export type DriftRecordsQueryResult = Apollo.QueryResult<DriftRecordsQuery, DriftRecordsQueryVariables>;
export const UpdateDriftsDocument = gql`
    mutation UpdateDrifts($ids: [String], $accept: Boolean) {
  updateDriftStatuses(ids: $ids, accept: $accept)
}
    `;
export type UpdateDriftsMutationFn = Apollo.MutationFunction<UpdateDriftsMutation, UpdateDriftsMutationVariables>;

/**
 * __useUpdateDriftsMutation__
 *
 * To run a mutation, you first call `useUpdateDriftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriftsMutation, { data, loading, error }] = useUpdateDriftsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useUpdateDriftsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriftsMutation, UpdateDriftsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriftsMutation, UpdateDriftsMutationVariables>(UpdateDriftsDocument, options);
      }
export type UpdateDriftsMutationHookResult = ReturnType<typeof useUpdateDriftsMutation>;
export type UpdateDriftsMutationResult = Apollo.MutationResult<UpdateDriftsMutation>;
export type UpdateDriftsMutationOptions = Apollo.BaseMutationOptions<UpdateDriftsMutation, UpdateDriftsMutationVariables>;
export const GetDiscoveryRulesDocument = gql`
    query GetDiscoveryRules($envId: String!) {
  discoveryRules(environmentId: $envId) {
    id
    name
    match {
      nid
      cloudAccount
      resourceGroup
      projectId
      type
      subtype
      tags {
        key
        value
      }
    }
    exclusive
  }
}
    `;

/**
 * __useGetDiscoveryRulesQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryRulesQuery({
 *   variables: {
 *      envId: // value for 'envId'
 *   },
 * });
 */
export function useGetDiscoveryRulesQuery(baseOptions: Apollo.QueryHookOptions<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>(GetDiscoveryRulesDocument, options);
      }
export function useGetDiscoveryRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>(GetDiscoveryRulesDocument, options);
        }
export type GetDiscoveryRulesQueryHookResult = ReturnType<typeof useGetDiscoveryRulesQuery>;
export type GetDiscoveryRulesLazyQueryHookResult = ReturnType<typeof useGetDiscoveryRulesLazyQuery>;
export type GetDiscoveryRulesQueryResult = Apollo.QueryResult<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>;
export const AddDiscoveryRuleDocument = gql`
    mutation AddDiscoveryRule($input: AddDiscoveryRule!) {
  addDiscoveryRule(input: $input) {
    id
  }
}
    `;
export type AddDiscoveryRuleMutationFn = Apollo.MutationFunction<AddDiscoveryRuleMutation, AddDiscoveryRuleMutationVariables>;

/**
 * __useAddDiscoveryRuleMutation__
 *
 * To run a mutation, you first call `useAddDiscoveryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiscoveryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiscoveryRuleMutation, { data, loading, error }] = useAddDiscoveryRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDiscoveryRuleMutation(baseOptions?: Apollo.MutationHookOptions<AddDiscoveryRuleMutation, AddDiscoveryRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiscoveryRuleMutation, AddDiscoveryRuleMutationVariables>(AddDiscoveryRuleDocument, options);
      }
export type AddDiscoveryRuleMutationHookResult = ReturnType<typeof useAddDiscoveryRuleMutation>;
export type AddDiscoveryRuleMutationResult = Apollo.MutationResult<AddDiscoveryRuleMutation>;
export type AddDiscoveryRuleMutationOptions = Apollo.BaseMutationOptions<AddDiscoveryRuleMutation, AddDiscoveryRuleMutationVariables>;
export const RemoveDiscoveryRuleDocument = gql`
    mutation RemoveDiscoveryRule($id: String!) {
  removeDiscoveryRule(id: $id)
}
    `;
export type RemoveDiscoveryRuleMutationFn = Apollo.MutationFunction<RemoveDiscoveryRuleMutation, RemoveDiscoveryRuleMutationVariables>;

/**
 * __useRemoveDiscoveryRuleMutation__
 *
 * To run a mutation, you first call `useRemoveDiscoveryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiscoveryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiscoveryRuleMutation, { data, loading, error }] = useRemoveDiscoveryRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDiscoveryRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDiscoveryRuleMutation, RemoveDiscoveryRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDiscoveryRuleMutation, RemoveDiscoveryRuleMutationVariables>(RemoveDiscoveryRuleDocument, options);
      }
export type RemoveDiscoveryRuleMutationHookResult = ReturnType<typeof useRemoveDiscoveryRuleMutation>;
export type RemoveDiscoveryRuleMutationResult = Apollo.MutationResult<RemoveDiscoveryRuleMutation>;
export type RemoveDiscoveryRuleMutationOptions = Apollo.BaseMutationOptions<RemoveDiscoveryRuleMutation, RemoveDiscoveryRuleMutationVariables>;
export const UpdateDiscoveryRuleDocument = gql`
    mutation UpdateDiscoveryRule($input: UpdateDiscoveryRule!) {
  updateDiscoveryRule(input: $input)
}
    `;
export type UpdateDiscoveryRuleMutationFn = Apollo.MutationFunction<UpdateDiscoveryRuleMutation, UpdateDiscoveryRuleMutationVariables>;

/**
 * __useUpdateDiscoveryRuleMutation__
 *
 * To run a mutation, you first call `useUpdateDiscoveryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscoveryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscoveryRuleMutation, { data, loading, error }] = useUpdateDiscoveryRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiscoveryRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscoveryRuleMutation, UpdateDiscoveryRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscoveryRuleMutation, UpdateDiscoveryRuleMutationVariables>(UpdateDiscoveryRuleDocument, options);
      }
export type UpdateDiscoveryRuleMutationHookResult = ReturnType<typeof useUpdateDiscoveryRuleMutation>;
export type UpdateDiscoveryRuleMutationResult = Apollo.MutationResult<UpdateDiscoveryRuleMutation>;
export type UpdateDiscoveryRuleMutationOptions = Apollo.BaseMutationOptions<UpdateDiscoveryRuleMutation, UpdateDiscoveryRuleMutationVariables>;
export const EnvironmentDocument = gql`
    query Environment($id: String!, $query: String, $filter: ResourceFilter, $limit: Int!, $page: Int!) {
  environment(id: $id) {
    id
    nid
    name
    budget {
      currency
      amount
    }
    cloudWasteAggregate {
      hasWaste
      cost
      count
    }
    cost {
      currentMonth
      previousMonth
      forecast
      dirty
      currentMonthDaily
    }
    owner {
      id
    }
    environmentType
    application {
      nid
      name
    }
    contacts {
      id
      name
      email
    }
    description
    estate(query: $query, filter: $filter, limit: $limit, page: $page) {
      resources {
        ... on CloudResource {
          id
          name
          providerId
          provider
          region
          cloudWaste {
            isWaste
            reason
          }
          cost {
            currentMonth
            previousMonth
            forecast
            dirty
          }
          providerType
        }
        ... on CostResource {
          id
          provider
          providerType
          region
          environmentsPaginated(envNid: $id, limit: 1, page: 0) {
            environments {
              cost {
                currentMonth
                previousMonth
                forecast
                dirty
              }
            }
          }
        }
      }
      count
    }
  }
}
    `;

/**
 * __useEnvironmentQuery__
 *
 * To run a query within a React component, call `useEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEnvironmentQuery(baseOptions: Apollo.QueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
      }
export function useEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
        }
export type EnvironmentQueryHookResult = ReturnType<typeof useEnvironmentQuery>;
export type EnvironmentLazyQueryHookResult = ReturnType<typeof useEnvironmentLazyQuery>;
export type EnvironmentQueryResult = Apollo.QueryResult<EnvironmentQuery, EnvironmentQueryVariables>;
export const EnvironmentContactsDocument = gql`
    query EnvironmentContacts($id: String!) {
  environment(id: $id) {
    id
    name
    owner {
      id
      name
    }
    description
    contacts {
      id
      name
    }
  }
}
    `;

/**
 * __useEnvironmentContactsQuery__
 *
 * To run a query within a React component, call `useEnvironmentContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnvironmentContactsQuery(baseOptions: Apollo.QueryHookOptions<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>(EnvironmentContactsDocument, options);
      }
export function useEnvironmentContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>(EnvironmentContactsDocument, options);
        }
export type EnvironmentContactsQueryHookResult = ReturnType<typeof useEnvironmentContactsQuery>;
export type EnvironmentContactsLazyQueryHookResult = ReturnType<typeof useEnvironmentContactsLazyQuery>;
export type EnvironmentContactsQueryResult = Apollo.QueryResult<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>;
export const AccountOptionsDocument = gql`
    query AccountOptions {
  accounts {
    id
    provider
    providerId
    name
  }
}
    `;

/**
 * __useAccountOptionsQuery__
 *
 * To run a query within a React component, call `useAccountOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AccountOptionsQuery, AccountOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountOptionsQuery, AccountOptionsQueryVariables>(AccountOptionsDocument, options);
      }
export function useAccountOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountOptionsQuery, AccountOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountOptionsQuery, AccountOptionsQueryVariables>(AccountOptionsDocument, options);
        }
export type AccountOptionsQueryHookResult = ReturnType<typeof useAccountOptionsQuery>;
export type AccountOptionsLazyQueryHookResult = ReturnType<typeof useAccountOptionsLazyQuery>;
export type AccountOptionsQueryResult = Apollo.QueryResult<AccountOptionsQuery, AccountOptionsQueryVariables>;
export const AddEnvToOrgUnitV2Document = gql`
    mutation AddEnvToOrgUnitV2($envId: ID!, $orgUnitId: ID!) {
  addEnvToOrgUnitV2(envId: $envId, orgUnitId: $orgUnitId)
}
    `;
export type AddEnvToOrgUnitV2MutationFn = Apollo.MutationFunction<AddEnvToOrgUnitV2Mutation, AddEnvToOrgUnitV2MutationVariables>;

/**
 * __useAddEnvToOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useAddEnvToOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnvToOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnvToOrgUnitV2Mutation, { data, loading, error }] = useAddEnvToOrgUnitV2Mutation({
 *   variables: {
 *      envId: // value for 'envId'
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useAddEnvToOrgUnitV2Mutation(baseOptions?: Apollo.MutationHookOptions<AddEnvToOrgUnitV2Mutation, AddEnvToOrgUnitV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEnvToOrgUnitV2Mutation, AddEnvToOrgUnitV2MutationVariables>(AddEnvToOrgUnitV2Document, options);
      }
export type AddEnvToOrgUnitV2MutationHookResult = ReturnType<typeof useAddEnvToOrgUnitV2Mutation>;
export type AddEnvToOrgUnitV2MutationResult = Apollo.MutationResult<AddEnvToOrgUnitV2Mutation>;
export type AddEnvToOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<AddEnvToOrgUnitV2Mutation, AddEnvToOrgUnitV2MutationVariables>;
export const RemoveEnvFromOrgUnitV2Document = gql`
    mutation RemoveEnvFromOrgUnitV2($envId: ID!, $orgUnitId: ID!) {
  removeEnvFromOrgUnitV2(envId: $envId, orgUnitId: $orgUnitId)
}
    `;
export type RemoveEnvFromOrgUnitV2MutationFn = Apollo.MutationFunction<RemoveEnvFromOrgUnitV2Mutation, RemoveEnvFromOrgUnitV2MutationVariables>;

/**
 * __useRemoveEnvFromOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useRemoveEnvFromOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnvFromOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnvFromOrgUnitV2Mutation, { data, loading, error }] = useRemoveEnvFromOrgUnitV2Mutation({
 *   variables: {
 *      envId: // value for 'envId'
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useRemoveEnvFromOrgUnitV2Mutation(baseOptions?: Apollo.MutationHookOptions<RemoveEnvFromOrgUnitV2Mutation, RemoveEnvFromOrgUnitV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEnvFromOrgUnitV2Mutation, RemoveEnvFromOrgUnitV2MutationVariables>(RemoveEnvFromOrgUnitV2Document, options);
      }
export type RemoveEnvFromOrgUnitV2MutationHookResult = ReturnType<typeof useRemoveEnvFromOrgUnitV2Mutation>;
export type RemoveEnvFromOrgUnitV2MutationResult = Apollo.MutationResult<RemoveEnvFromOrgUnitV2Mutation>;
export type RemoveEnvFromOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<RemoveEnvFromOrgUnitV2Mutation, RemoveEnvFromOrgUnitV2MutationVariables>;
export const GetEnvironmentOrgUnitsDocument = gql`
    query GetEnvironmentOrgUnits($id: String!) {
  environment(id: $id) {
    nid
    id
    name
    orgUnitsV2(limit: 50, page: 0) {
      orgUnits {
        nid
        name
        businessContext {
          id
          color
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetEnvironmentOrgUnitsQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentOrgUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentOrgUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentOrgUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnvironmentOrgUnitsQuery(baseOptions: Apollo.QueryHookOptions<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>(GetEnvironmentOrgUnitsDocument, options);
      }
export function useGetEnvironmentOrgUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>(GetEnvironmentOrgUnitsDocument, options);
        }
export type GetEnvironmentOrgUnitsQueryHookResult = ReturnType<typeof useGetEnvironmentOrgUnitsQuery>;
export type GetEnvironmentOrgUnitsLazyQueryHookResult = ReturnType<typeof useGetEnvironmentOrgUnitsLazyQuery>;
export type GetEnvironmentOrgUnitsQueryResult = Apollo.QueryResult<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>;
export const UnsetEnvironmentBudgetDocument = gql`
    mutation unsetEnvironmentBudget($nid: ID!) {
  unsetEnvironmentBudget(nid: $nid)
}
    `;
export type UnsetEnvironmentBudgetMutationFn = Apollo.MutationFunction<UnsetEnvironmentBudgetMutation, UnsetEnvironmentBudgetMutationVariables>;

/**
 * __useUnsetEnvironmentBudgetMutation__
 *
 * To run a mutation, you first call `useUnsetEnvironmentBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetEnvironmentBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetEnvironmentBudgetMutation, { data, loading, error }] = useUnsetEnvironmentBudgetMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useUnsetEnvironmentBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UnsetEnvironmentBudgetMutation, UnsetEnvironmentBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetEnvironmentBudgetMutation, UnsetEnvironmentBudgetMutationVariables>(UnsetEnvironmentBudgetDocument, options);
      }
export type UnsetEnvironmentBudgetMutationHookResult = ReturnType<typeof useUnsetEnvironmentBudgetMutation>;
export type UnsetEnvironmentBudgetMutationResult = Apollo.MutationResult<UnsetEnvironmentBudgetMutation>;
export type UnsetEnvironmentBudgetMutationOptions = Apollo.BaseMutationOptions<UnsetEnvironmentBudgetMutation, UnsetEnvironmentBudgetMutationVariables>;
export const CostResourceEnvironmentsDocument = gql`
    query CostResourceEnvironments($id: String!, $limit: Int!, $page: Int) {
  estateRecord(nid: $id) {
    ... on CostResource {
      environmentsPaginated(limit: $limit, page: $page) {
        environments {
          cost {
            currentMonth
            previousMonth
            forecast
            dirty
          }
          environment {
            name
            id
            application {
              name
              id
            }
          }
        }
        count
        pages
      }
    }
  }
}
    `;

/**
 * __useCostResourceEnvironmentsQuery__
 *
 * To run a query within a React component, call `useCostResourceEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostResourceEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostResourceEnvironmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCostResourceEnvironmentsQuery(baseOptions: Apollo.QueryHookOptions<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>(CostResourceEnvironmentsDocument, options);
      }
export function useCostResourceEnvironmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>(CostResourceEnvironmentsDocument, options);
        }
export type CostResourceEnvironmentsQueryHookResult = ReturnType<typeof useCostResourceEnvironmentsQuery>;
export type CostResourceEnvironmentsLazyQueryHookResult = ReturnType<typeof useCostResourceEnvironmentsLazyQuery>;
export type CostResourceEnvironmentsQueryResult = Apollo.QueryResult<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>;
export const ResourceDocument = gql`
    query Resource($id: String!) {
  estateRecord(nid: $id) {
    ... on CloudResource {
      id
      provider
      providerId
      providerType
      name
      region
      drifted
      cloudAccountId
      cloudWaste {
        isWaste
        reason
      }
      pendingAssignment {
        id
        name
        application {
          name
          id
        }
      }
      cost {
        currentMonth
        previousMonth
        forecast
        dirty
      }
      environment {
        name
        id
        application {
          name
          id
        }
      }
      metadataAndTags {
        tags {
          key
          value
        }
        metadata {
          key
          value
        }
      }
      drifted
      reservation {
        from
        to
      }
    }
    ... on CostResource {
      id
      provider
      providerType
      cost {
        currentMonth
        previousMonth
        forecast
        dirty
      }
      metadataAndTags {
        tags {
          key
          value
        }
        metadata {
          key
          value
        }
      }
    }
  }
}
    `;

/**
 * __useResourceQuery__
 *
 * To run a query within a React component, call `useResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceQuery(baseOptions: Apollo.QueryHookOptions<ResourceQuery, ResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, options);
      }
export function useResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceQuery, ResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, options);
        }
export type ResourceQueryHookResult = ReturnType<typeof useResourceQuery>;
export type ResourceLazyQueryHookResult = ReturnType<typeof useResourceLazyQuery>;
export type ResourceQueryResult = Apollo.QueryResult<ResourceQuery, ResourceQueryVariables>;
export const ReservationDetailsDocument = gql`
    query ReservationDetails($reservationId: String!) {
  reservationDetails(reservationId: $reservationId) {
    dailyUtilization {
      utilization
      benefit
      forecast
      timeSeries {
        date
        value
        benefit
      }
    }
    monthlyUtilization {
      utilization
      benefit
      forecast
      timeSeries {
        date
        value
        benefit
      }
    }
    from
    to
    instanceType
    purchaseOption
    paymentOption
    upfrontCost
    monthlyCost
  }
}
    `;

/**
 * __useReservationDetailsQuery__
 *
 * To run a query within a React component, call `useReservationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationDetailsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useReservationDetailsQuery(baseOptions: Apollo.QueryHookOptions<ReservationDetailsQuery, ReservationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationDetailsQuery, ReservationDetailsQueryVariables>(ReservationDetailsDocument, options);
      }
export function useReservationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationDetailsQuery, ReservationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationDetailsQuery, ReservationDetailsQueryVariables>(ReservationDetailsDocument, options);
        }
export type ReservationDetailsQueryHookResult = ReturnType<typeof useReservationDetailsQuery>;
export type ReservationDetailsLazyQueryHookResult = ReturnType<typeof useReservationDetailsLazyQuery>;
export type ReservationDetailsQueryResult = Apollo.QueryResult<ReservationDetailsQuery, ReservationDetailsQueryVariables>;
export const ListReservationCoveredInstancesDocument = gql`
    query ListReservationCoveredInstances($reservationId: String!, $page: Int!, $limit: Int!) {
  listReservationCoveredInstances(
    reservationId: $reservationId
    page: $page
    limit: $limit
  ) {
    pages
    count
    instances {
      id
      nid
      provider
      type
      name
      account
      location
      environment {
        nid
        name
        application {
          nid
          name
        }
      }
      benefit
    }
  }
}
    `;

/**
 * __useListReservationCoveredInstancesQuery__
 *
 * To run a query within a React component, call `useListReservationCoveredInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationCoveredInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationCoveredInstancesQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListReservationCoveredInstancesQuery(baseOptions: Apollo.QueryHookOptions<ListReservationCoveredInstancesQuery, ListReservationCoveredInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReservationCoveredInstancesQuery, ListReservationCoveredInstancesQueryVariables>(ListReservationCoveredInstancesDocument, options);
      }
export function useListReservationCoveredInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReservationCoveredInstancesQuery, ListReservationCoveredInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReservationCoveredInstancesQuery, ListReservationCoveredInstancesQueryVariables>(ListReservationCoveredInstancesDocument, options);
        }
export type ListReservationCoveredInstancesQueryHookResult = ReturnType<typeof useListReservationCoveredInstancesQuery>;
export type ListReservationCoveredInstancesLazyQueryHookResult = ReturnType<typeof useListReservationCoveredInstancesLazyQuery>;
export type ListReservationCoveredInstancesQueryResult = Apollo.QueryResult<ListReservationCoveredInstancesQuery, ListReservationCoveredInstancesQueryVariables>;
export const ResourcesDocument = gql`
    query resources($page: Int!, $limit: Int!, $filter: ResourceFilter, $query: String) {
  estate(limit: $limit, page: $page, filter: $filter, query: $query) {
    count
    resources {
      ... on CloudResource {
        id
        provider
        providerId
        providerType
        name
        region
        cloudAccountId
        cost {
          currentMonth
          forecast
          dirty
        }
        environment {
          name
          id
          nid
          application {
            name
            id
            nid
          }
        }
        cloudWaste {
          isWaste
          reason
        }
      }
      ... on CostResource {
        id
        provider
        providerType
        cost {
          currentMonth
          forecast
          dirty
        }
      }
    }
  }
}
    `;

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useResourcesQuery(baseOptions: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
      }
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
        }
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesQueryResult = Apollo.QueryResult<ResourcesQuery, ResourcesQueryVariables>;
export const ResourcesNewDocument = gql`
    query resourcesNew($page: Int!, $limit: Int!, $sort: SearchSort, $filter: EstateV2Filter) {
  estateV2(limit: $limit, page: $page, sort: $sort, filter: $filter) {
    count
    cost {
      currentMonthTotal
    }
    resources {
      ... on CloudResource {
        id
        provider
        providerId
        providerType
        name
        region
        cloudAccountId
        cost {
          currentMonth
          forecast
          dirty
        }
        environment {
          name
          id
          nid
          application {
            name
            id
            nid
          }
        }
        cloudWaste {
          isWaste
          reason
        }
      }
      ... on CostResource {
        id
        provider
        providerType
        assignments {
          appCount
          envCount
        }
        cost {
          currentMonth
          forecast
          dirty
        }
      }
    }
  }
}
    `;

/**
 * __useResourcesNewQuery__
 *
 * To run a query within a React component, call `useResourcesNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesNewQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useResourcesNewQuery(baseOptions: Apollo.QueryHookOptions<ResourcesNewQuery, ResourcesNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesNewQuery, ResourcesNewQueryVariables>(ResourcesNewDocument, options);
      }
export function useResourcesNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesNewQuery, ResourcesNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesNewQuery, ResourcesNewQueryVariables>(ResourcesNewDocument, options);
        }
export type ResourcesNewQueryHookResult = ReturnType<typeof useResourcesNewQuery>;
export type ResourcesNewLazyQueryHookResult = ReturnType<typeof useResourcesNewLazyQuery>;
export type ResourcesNewQueryResult = Apollo.QueryResult<ResourcesNewQuery, ResourcesNewQueryVariables>;
export const AttachResourceDocument = gql`
    mutation AttachResource($environmentNid: String!, $resourceNid: String!) {
  attachResource(environmentNid: $environmentNid, resourceNid: $resourceNid)
}
    `;
export type AttachResourceMutationFn = Apollo.MutationFunction<AttachResourceMutation, AttachResourceMutationVariables>;

/**
 * __useAttachResourceMutation__
 *
 * To run a mutation, you first call `useAttachResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachResourceMutation, { data, loading, error }] = useAttachResourceMutation({
 *   variables: {
 *      environmentNid: // value for 'environmentNid'
 *      resourceNid: // value for 'resourceNid'
 *   },
 * });
 */
export function useAttachResourceMutation(baseOptions?: Apollo.MutationHookOptions<AttachResourceMutation, AttachResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachResourceMutation, AttachResourceMutationVariables>(AttachResourceDocument, options);
      }
export type AttachResourceMutationHookResult = ReturnType<typeof useAttachResourceMutation>;
export type AttachResourceMutationResult = Apollo.MutationResult<AttachResourceMutation>;
export type AttachResourceMutationOptions = Apollo.BaseMutationOptions<AttachResourceMutation, AttachResourceMutationVariables>;
export const EstateExportDocument = gql`
    mutation EstateExport($input: EstateRecordsExportInput!) {
  requestEstateRecordsExport(input: $input) {
    exportJobId
  }
}
    `;
export type EstateExportMutationFn = Apollo.MutationFunction<EstateExportMutation, EstateExportMutationVariables>;

/**
 * __useEstateExportMutation__
 *
 * To run a mutation, you first call `useEstateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estateExportMutation, { data, loading, error }] = useEstateExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstateExportMutation(baseOptions?: Apollo.MutationHookOptions<EstateExportMutation, EstateExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EstateExportMutation, EstateExportMutationVariables>(EstateExportDocument, options);
      }
export type EstateExportMutationHookResult = ReturnType<typeof useEstateExportMutation>;
export type EstateExportMutationResult = Apollo.MutationResult<EstateExportMutation>;
export type EstateExportMutationOptions = Apollo.BaseMutationOptions<EstateExportMutation, EstateExportMutationVariables>;
export const EstateDownloadDocument = gql`
    query EstateDownload($exportJobId: ID!) {
  downloadExport(exportJobId: $exportJobId) {
    fileUrl
  }
}
    `;

/**
 * __useEstateDownloadQuery__
 *
 * To run a query within a React component, call `useEstateDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateDownloadQuery({
 *   variables: {
 *      exportJobId: // value for 'exportJobId'
 *   },
 * });
 */
export function useEstateDownloadQuery(baseOptions: Apollo.QueryHookOptions<EstateDownloadQuery, EstateDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstateDownloadQuery, EstateDownloadQueryVariables>(EstateDownloadDocument, options);
      }
export function useEstateDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateDownloadQuery, EstateDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstateDownloadQuery, EstateDownloadQueryVariables>(EstateDownloadDocument, options);
        }
export type EstateDownloadQueryHookResult = ReturnType<typeof useEstateDownloadQuery>;
export type EstateDownloadLazyQueryHookResult = ReturnType<typeof useEstateDownloadLazyQuery>;
export type EstateDownloadQueryResult = Apollo.QueryResult<EstateDownloadQuery, EstateDownloadQueryVariables>;
export const GetAppsWithEnvsDocument = gql`
    query getAppsWithEnvs {
  applications {
    name
    id
    nid
    environments {
      name
      id
      nid
    }
  }
}
    `;

/**
 * __useGetAppsWithEnvsQuery__
 *
 * To run a query within a React component, call `useGetAppsWithEnvsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsWithEnvsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsWithEnvsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppsWithEnvsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>(GetAppsWithEnvsDocument, options);
      }
export function useGetAppsWithEnvsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>(GetAppsWithEnvsDocument, options);
        }
export type GetAppsWithEnvsQueryHookResult = ReturnType<typeof useGetAppsWithEnvsQuery>;
export type GetAppsWithEnvsLazyQueryHookResult = ReturnType<typeof useGetAppsWithEnvsLazyQuery>;
export type GetAppsWithEnvsQueryResult = Apollo.QueryResult<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>;
export const CloudWastePoliciesDocument = gql`
    query CloudWastePolicies {
  cloudWasteSummary {
    policies {
      id
      title
    }
  }
}
    `;

/**
 * __useCloudWastePoliciesQuery__
 *
 * To run a query within a React component, call `useCloudWastePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWastePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWastePoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWastePoliciesQuery(baseOptions?: Apollo.QueryHookOptions<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>(CloudWastePoliciesDocument, options);
      }
export function useCloudWastePoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>(CloudWastePoliciesDocument, options);
        }
export type CloudWastePoliciesQueryHookResult = ReturnType<typeof useCloudWastePoliciesQuery>;
export type CloudWastePoliciesLazyQueryHookResult = ReturnType<typeof useCloudWastePoliciesLazyQuery>;
export type CloudWastePoliciesQueryResult = Apollo.QueryResult<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>;
export const GetAppsByIdsDocument = gql`
    query getAppsByIds($ids: [String!]) {
  applicationsByIds(ids: $ids) {
    name
    id
    nid
  }
}
    `;

/**
 * __useGetAppsByIdsQuery__
 *
 * To run a query within a React component, call `useGetAppsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetAppsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppsByIdsQuery, GetAppsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppsByIdsQuery, GetAppsByIdsQueryVariables>(GetAppsByIdsDocument, options);
      }
export function useGetAppsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppsByIdsQuery, GetAppsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppsByIdsQuery, GetAppsByIdsQueryVariables>(GetAppsByIdsDocument, options);
        }
export type GetAppsByIdsQueryHookResult = ReturnType<typeof useGetAppsByIdsQuery>;
export type GetAppsByIdsLazyQueryHookResult = ReturnType<typeof useGetAppsByIdsLazyQuery>;
export type GetAppsByIdsQueryResult = Apollo.QueryResult<GetAppsByIdsQuery, GetAppsByIdsQueryVariables>;
export const GetEnvsByIdsDocument = gql`
    query getEnvsByIds($ids: [String!]) {
  environmentsByIds(ids: $ids) {
    name
    id
    nid
  }
}
    `;

/**
 * __useGetEnvsByIdsQuery__
 *
 * To run a query within a React component, call `useGetEnvsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetEnvsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetEnvsByIdsQuery, GetEnvsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvsByIdsQuery, GetEnvsByIdsQueryVariables>(GetEnvsByIdsDocument, options);
      }
export function useGetEnvsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvsByIdsQuery, GetEnvsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvsByIdsQuery, GetEnvsByIdsQueryVariables>(GetEnvsByIdsDocument, options);
        }
export type GetEnvsByIdsQueryHookResult = ReturnType<typeof useGetEnvsByIdsQuery>;
export type GetEnvsByIdsLazyQueryHookResult = ReturnType<typeof useGetEnvsByIdsLazyQuery>;
export type GetEnvsByIdsQueryResult = Apollo.QueryResult<GetEnvsByIdsQuery, GetEnvsByIdsQueryVariables>;
export const TeamLeaderDashboardDocument = gql`
    query TeamLeaderDashboard {
  applications {
    nid
    name
    displayName
    cost {
      currentMonth
      currentMonthDaily
      previousMonth
      forecast
      dirty
    }
    environments {
      resourceCount
    }
  }
}
    `;

/**
 * __useTeamLeaderDashboardQuery__
 *
 * To run a query within a React component, call `useTeamLeaderDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamLeaderDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamLeaderDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamLeaderDashboardQuery(baseOptions?: Apollo.QueryHookOptions<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>(TeamLeaderDashboardDocument, options);
      }
export function useTeamLeaderDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>(TeamLeaderDashboardDocument, options);
        }
export type TeamLeaderDashboardQueryHookResult = ReturnType<typeof useTeamLeaderDashboardQuery>;
export type TeamLeaderDashboardLazyQueryHookResult = ReturnType<typeof useTeamLeaderDashboardLazyQuery>;
export type TeamLeaderDashboardQueryResult = Apollo.QueryResult<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>;
export const CloudWasteSummaryDocument = gql`
    query CloudWasteSummary {
  cloudWasteSummary {
    policies {
      id
      title
      description
      cost
      count
    }
    totalCost
  }
}
    `;

/**
 * __useCloudWasteSummaryQuery__
 *
 * To run a query within a React component, call `useCloudWasteSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWasteSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWasteSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWasteSummaryQuery(baseOptions?: Apollo.QueryHookOptions<CloudWasteSummaryQuery, CloudWasteSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloudWasteSummaryQuery, CloudWasteSummaryQueryVariables>(CloudWasteSummaryDocument, options);
      }
export function useCloudWasteSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloudWasteSummaryQuery, CloudWasteSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloudWasteSummaryQuery, CloudWasteSummaryQueryVariables>(CloudWasteSummaryDocument, options);
        }
export type CloudWasteSummaryQueryHookResult = ReturnType<typeof useCloudWasteSummaryQuery>;
export type CloudWasteSummaryLazyQueryHookResult = ReturnType<typeof useCloudWasteSummaryLazyQuery>;
export type CloudWasteSummaryQueryResult = Apollo.QueryResult<CloudWasteSummaryQuery, CloudWasteSummaryQueryVariables>;
export const EstateRecordsAccountStatusDocument = gql`
    query EstateRecordsAccountStatus {
  accounts {
    activated
  }
}
    `;

/**
 * __useEstateRecordsAccountStatusQuery__
 *
 * To run a query within a React component, call `useEstateRecordsAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateRecordsAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateRecordsAccountStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstateRecordsAccountStatusQuery(baseOptions?: Apollo.QueryHookOptions<EstateRecordsAccountStatusQuery, EstateRecordsAccountStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstateRecordsAccountStatusQuery, EstateRecordsAccountStatusQueryVariables>(EstateRecordsAccountStatusDocument, options);
      }
export function useEstateRecordsAccountStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateRecordsAccountStatusQuery, EstateRecordsAccountStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstateRecordsAccountStatusQuery, EstateRecordsAccountStatusQueryVariables>(EstateRecordsAccountStatusDocument, options);
        }
export type EstateRecordsAccountStatusQueryHookResult = ReturnType<typeof useEstateRecordsAccountStatusQuery>;
export type EstateRecordsAccountStatusLazyQueryHookResult = ReturnType<typeof useEstateRecordsAccountStatusLazyQuery>;
export type EstateRecordsAccountStatusQueryResult = Apollo.QueryResult<EstateRecordsAccountStatusQuery, EstateRecordsAccountStatusQueryVariables>;
export const TopSpendingApplicationsDocument = gql`
    query TopSpendingApplications($limit: Int!) {
  topSpendingApplications(limit: $limit) {
    topSpending {
      ...Spends
    }
    defaultApplication {
      ...Spends
    }
    other {
      ...Spends
    }
  }
}
    ${SpendsFragmentDoc}`;

/**
 * __useTopSpendingApplicationsQuery__
 *
 * To run a query within a React component, call `useTopSpendingApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopSpendingApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopSpendingApplicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTopSpendingApplicationsQuery(baseOptions: Apollo.QueryHookOptions<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>(TopSpendingApplicationsDocument, options);
      }
export function useTopSpendingApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>(TopSpendingApplicationsDocument, options);
        }
export type TopSpendingApplicationsQueryHookResult = ReturnType<typeof useTopSpendingApplicationsQuery>;
export type TopSpendingApplicationsLazyQueryHookResult = ReturnType<typeof useTopSpendingApplicationsLazyQuery>;
export type TopSpendingApplicationsQueryResult = Apollo.QueryResult<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>;
export const ApplicationsCountDocument = gql`
    query ApplicationsCount {
  applicationsPaginated(limit: 1, page: 0) {
    count
  }
}
    `;

/**
 * __useApplicationsCountQuery__
 *
 * To run a query within a React component, call `useApplicationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsCountQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsCountQuery, ApplicationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsCountQuery, ApplicationsCountQueryVariables>(ApplicationsCountDocument, options);
      }
export function useApplicationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsCountQuery, ApplicationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsCountQuery, ApplicationsCountQueryVariables>(ApplicationsCountDocument, options);
        }
export type ApplicationsCountQueryHookResult = ReturnType<typeof useApplicationsCountQuery>;
export type ApplicationsCountLazyQueryHookResult = ReturnType<typeof useApplicationsCountLazyQuery>;
export type ApplicationsCountQueryResult = Apollo.QueryResult<ApplicationsCountQuery, ApplicationsCountQueryVariables>;
export const OrganizationCostDocument = gql`
    query OrganizationCost {
  organization {
    id
    cost {
      currentMonth
      previousMonth
      previousMonthDayToDay
      forecast
      dirty
    }
  }
}
    `;

/**
 * __useOrganizationCostQuery__
 *
 * To run a query within a React component, call `useOrganizationCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCostQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationCostQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationCostQuery, OrganizationCostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationCostQuery, OrganizationCostQueryVariables>(OrganizationCostDocument, options);
      }
export function useOrganizationCostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationCostQuery, OrganizationCostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationCostQuery, OrganizationCostQueryVariables>(OrganizationCostDocument, options);
        }
export type OrganizationCostQueryHookResult = ReturnType<typeof useOrganizationCostQuery>;
export type OrganizationCostLazyQueryHookResult = ReturnType<typeof useOrganizationCostLazyQuery>;
export type OrganizationCostQueryResult = Apollo.QueryResult<OrganizationCostQuery, OrganizationCostQueryVariables>;
export const GlobalCostAwsDocument = gql`
    query GlobalCostAWS {
  organization {
    id
    cost(providers: [AWS]) {
      currentMonth
      previousMonth
      forecast
      dirty
    }
  }
}
    `;

/**
 * __useGlobalCostAwsQuery__
 *
 * To run a query within a React component, call `useGlobalCostAwsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalCostAwsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalCostAwsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalCostAwsQuery(baseOptions?: Apollo.QueryHookOptions<GlobalCostAwsQuery, GlobalCostAwsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalCostAwsQuery, GlobalCostAwsQueryVariables>(GlobalCostAwsDocument, options);
      }
export function useGlobalCostAwsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalCostAwsQuery, GlobalCostAwsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalCostAwsQuery, GlobalCostAwsQueryVariables>(GlobalCostAwsDocument, options);
        }
export type GlobalCostAwsQueryHookResult = ReturnType<typeof useGlobalCostAwsQuery>;
export type GlobalCostAwsLazyQueryHookResult = ReturnType<typeof useGlobalCostAwsLazyQuery>;
export type GlobalCostAwsQueryResult = Apollo.QueryResult<GlobalCostAwsQuery, GlobalCostAwsQueryVariables>;
export const GlobalCostAzureDocument = gql`
    query GlobalCostAZURE {
  organization {
    id
    cost(providers: [AZURE]) {
      currentMonth
      previousMonth
      forecast
      dirty
    }
  }
}
    `;

/**
 * __useGlobalCostAzureQuery__
 *
 * To run a query within a React component, call `useGlobalCostAzureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalCostAzureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalCostAzureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalCostAzureQuery(baseOptions?: Apollo.QueryHookOptions<GlobalCostAzureQuery, GlobalCostAzureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalCostAzureQuery, GlobalCostAzureQueryVariables>(GlobalCostAzureDocument, options);
      }
export function useGlobalCostAzureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalCostAzureQuery, GlobalCostAzureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalCostAzureQuery, GlobalCostAzureQueryVariables>(GlobalCostAzureDocument, options);
        }
export type GlobalCostAzureQueryHookResult = ReturnType<typeof useGlobalCostAzureQuery>;
export type GlobalCostAzureLazyQueryHookResult = ReturnType<typeof useGlobalCostAzureLazyQuery>;
export type GlobalCostAzureQueryResult = Apollo.QueryResult<GlobalCostAzureQuery, GlobalCostAzureQueryVariables>;
export const GlobalCostGcpDocument = gql`
    query GlobalCostGCP {
  organization {
    id
    cost(providers: [GCP]) {
      currentMonth
      previousMonth
      forecast
      dirty
    }
  }
}
    `;

/**
 * __useGlobalCostGcpQuery__
 *
 * To run a query within a React component, call `useGlobalCostGcpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalCostGcpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalCostGcpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalCostGcpQuery(baseOptions?: Apollo.QueryHookOptions<GlobalCostGcpQuery, GlobalCostGcpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalCostGcpQuery, GlobalCostGcpQueryVariables>(GlobalCostGcpDocument, options);
      }
export function useGlobalCostGcpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalCostGcpQuery, GlobalCostGcpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalCostGcpQuery, GlobalCostGcpQueryVariables>(GlobalCostGcpDocument, options);
        }
export type GlobalCostGcpQueryHookResult = ReturnType<typeof useGlobalCostGcpQuery>;
export type GlobalCostGcpLazyQueryHookResult = ReturnType<typeof useGlobalCostGcpLazyQuery>;
export type GlobalCostGcpQueryResult = Apollo.QueryResult<GlobalCostGcpQuery, GlobalCostGcpQueryVariables>;
export const BusinessContextsAllocationDocument = gql`
    query BusinessContextsAllocation {
  businessContexts(limit: 50, page: 0) {
    businessContexts {
      id
      name
      costAllocation {
        allocated
        unallocated
      }
    }
  }
}
    `;

/**
 * __useBusinessContextsAllocationQuery__
 *
 * To run a query within a React component, call `useBusinessContextsAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextsAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextsAllocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessContextsAllocationQuery(baseOptions?: Apollo.QueryHookOptions<BusinessContextsAllocationQuery, BusinessContextsAllocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessContextsAllocationQuery, BusinessContextsAllocationQueryVariables>(BusinessContextsAllocationDocument, options);
      }
export function useBusinessContextsAllocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessContextsAllocationQuery, BusinessContextsAllocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessContextsAllocationQuery, BusinessContextsAllocationQueryVariables>(BusinessContextsAllocationDocument, options);
        }
export type BusinessContextsAllocationQueryHookResult = ReturnType<typeof useBusinessContextsAllocationQuery>;
export type BusinessContextsAllocationLazyQueryHookResult = ReturnType<typeof useBusinessContextsAllocationLazyQuery>;
export type BusinessContextsAllocationQueryResult = Apollo.QueryResult<BusinessContextsAllocationQuery, BusinessContextsAllocationQueryVariables>;
export const EstateRecordsCountDocument = gql`
    query EstateRecordsCount {
  estate(page: 0, limit: 1) {
    count
  }
}
    `;

/**
 * __useEstateRecordsCountQuery__
 *
 * To run a query within a React component, call `useEstateRecordsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateRecordsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateRecordsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstateRecordsCountQuery(baseOptions?: Apollo.QueryHookOptions<EstateRecordsCountQuery, EstateRecordsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstateRecordsCountQuery, EstateRecordsCountQueryVariables>(EstateRecordsCountDocument, options);
      }
export function useEstateRecordsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateRecordsCountQuery, EstateRecordsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstateRecordsCountQuery, EstateRecordsCountQueryVariables>(EstateRecordsCountDocument, options);
        }
export type EstateRecordsCountQueryHookResult = ReturnType<typeof useEstateRecordsCountQuery>;
export type EstateRecordsCountLazyQueryHookResult = ReturnType<typeof useEstateRecordsCountLazyQuery>;
export type EstateRecordsCountQueryResult = Apollo.QueryResult<EstateRecordsCountQuery, EstateRecordsCountQueryVariables>;
export const DriftedResourcesCountDocument = gql`
    query DriftedResourcesCount {
  estate(limit: 1, page: 0, filter: {drifted: true}) {
    count
  }
}
    `;

/**
 * __useDriftedResourcesCountQuery__
 *
 * To run a query within a React component, call `useDriftedResourcesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriftedResourcesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriftedResourcesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriftedResourcesCountQuery(baseOptions?: Apollo.QueryHookOptions<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>(DriftedResourcesCountDocument, options);
      }
export function useDriftedResourcesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>(DriftedResourcesCountDocument, options);
        }
export type DriftedResourcesCountQueryHookResult = ReturnType<typeof useDriftedResourcesCountQuery>;
export type DriftedResourcesCountLazyQueryHookResult = ReturnType<typeof useDriftedResourcesCountLazyQuery>;
export type DriftedResourcesCountQueryResult = Apollo.QueryResult<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>;
export const UnassignedResourcesCountDocument = gql`
    query UnassignedResourcesCount {
  estate(limit: 1, page: 0, filter: {unassigned: true}) {
    count
  }
}
    `;

/**
 * __useUnassignedResourcesCountQuery__
 *
 * To run a query within a React component, call `useUnassignedResourcesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedResourcesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedResourcesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnassignedResourcesCountQuery(baseOptions?: Apollo.QueryHookOptions<UnassignedResourcesCountQuery, UnassignedResourcesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnassignedResourcesCountQuery, UnassignedResourcesCountQueryVariables>(UnassignedResourcesCountDocument, options);
      }
export function useUnassignedResourcesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassignedResourcesCountQuery, UnassignedResourcesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnassignedResourcesCountQuery, UnassignedResourcesCountQueryVariables>(UnassignedResourcesCountDocument, options);
        }
export type UnassignedResourcesCountQueryHookResult = ReturnType<typeof useUnassignedResourcesCountQuery>;
export type UnassignedResourcesCountLazyQueryHookResult = ReturnType<typeof useUnassignedResourcesCountLazyQuery>;
export type UnassignedResourcesCountQueryResult = Apollo.QueryResult<UnassignedResourcesCountQuery, UnassignedResourcesCountQueryVariables>;
export const PeopleDocument = gql`
    query People {
  people {
    id
    name
    email
  }
}
    `;

/**
 * __usePeopleQuery__
 *
 * To run a query within a React component, call `usePeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeopleQuery(baseOptions?: Apollo.QueryHookOptions<PeopleQuery, PeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
      }
export function usePeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleQuery, PeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
        }
export type PeopleQueryHookResult = ReturnType<typeof usePeopleQuery>;
export type PeopleLazyQueryHookResult = ReturnType<typeof usePeopleLazyQuery>;
export type PeopleQueryResult = Apollo.QueryResult<PeopleQuery, PeopleQueryVariables>;
export const AddPersonDocument = gql`
    mutation addPerson($name: String!, $email: String!) {
  addPerson(input: {name: $name, email: $email}) {
    id
  }
}
    `;
export type AddPersonMutationFn = Apollo.MutationFunction<AddPersonMutation, AddPersonMutationVariables>;

/**
 * __useAddPersonMutation__
 *
 * To run a mutation, you first call `useAddPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonMutation, { data, loading, error }] = useAddPersonMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddPersonMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonMutation, AddPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPersonMutation, AddPersonMutationVariables>(AddPersonDocument, options);
      }
export type AddPersonMutationHookResult = ReturnType<typeof useAddPersonMutation>;
export type AddPersonMutationResult = Apollo.MutationResult<AddPersonMutation>;
export type AddPersonMutationOptions = Apollo.BaseMutationOptions<AddPersonMutation, AddPersonMutationVariables>;
export const RemovePersonDocument = gql`
    mutation removePerson($id: String!) {
  removePerson(id: $id)
}
    `;
export type RemovePersonMutationFn = Apollo.MutationFunction<RemovePersonMutation, RemovePersonMutationVariables>;

/**
 * __useRemovePersonMutation__
 *
 * To run a mutation, you first call `useRemovePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonMutation, { data, loading, error }] = useRemovePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePersonMutation(baseOptions?: Apollo.MutationHookOptions<RemovePersonMutation, RemovePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePersonMutation, RemovePersonMutationVariables>(RemovePersonDocument, options);
      }
export type RemovePersonMutationHookResult = ReturnType<typeof useRemovePersonMutation>;
export type RemovePersonMutationResult = Apollo.MutationResult<RemovePersonMutation>;
export type RemovePersonMutationOptions = Apollo.BaseMutationOptions<RemovePersonMutation, RemovePersonMutationVariables>;
export const UpdatePersonDocument = gql`
    mutation updatePerson($id: String!, $name: String, $email: String) {
  updatePerson(input: {id: $id, name: $name, email: $email})
}
    `;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdatePersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options);
      }
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>;
export const ListReservationsDocument = gql`
    query listReservations($page: Int!, $limit: Int!, $start: String!, $end: String!) {
  listReservations(limit: $limit, page: $page, start: $start, end: $end) {
    pages
    count
    reservations {
      nid: reservationId
      from
      to
      upfrontCost
      purchaseOption: paymentOption
      instanceType
      monthlyCost
      description: productDescription
      provider
      location: region
      account: cloudAccount
      monthlyUtilization {
        totalUtilisation: utilization
        benefit
      }
      dailyUtilization {
        monthlyUtilisation: utilization
        benefit
      }
    }
  }
}
    `;

/**
 * __useListReservationsQuery__
 *
 * To run a query within a React component, call `useListReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useListReservationsQuery(baseOptions: Apollo.QueryHookOptions<ListReservationsQuery, ListReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReservationsQuery, ListReservationsQueryVariables>(ListReservationsDocument, options);
      }
export function useListReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReservationsQuery, ListReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReservationsQuery, ListReservationsQueryVariables>(ListReservationsDocument, options);
        }
export type ListReservationsQueryHookResult = ReturnType<typeof useListReservationsQuery>;
export type ListReservationsLazyQueryHookResult = ReturnType<typeof useListReservationsLazyQuery>;
export type ListReservationsQueryResult = Apollo.QueryResult<ListReservationsQuery, ListReservationsQueryVariables>;
export const ListReservationCoverageByInstanceTypeDocument = gql`
    query listReservationCoverageByInstanceType($page: Int!, $limit: Int!, $start: String!, $end: String!) {
  listReservationCoverageByInstanceType(
    limit: $limit
    page: $page
    start: $start
    end: $end
  ) {
    pages
    count
    resources {
      instanceType
      platform
      region
      provider
      averageCoverage
      totalCoveredHours
      totalOnDemandHours
      totalOnDemandCost
    }
  }
}
    `;

/**
 * __useListReservationCoverageByInstanceTypeQuery__
 *
 * To run a query within a React component, call `useListReservationCoverageByInstanceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationCoverageByInstanceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationCoverageByInstanceTypeQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useListReservationCoverageByInstanceTypeQuery(baseOptions: Apollo.QueryHookOptions<ListReservationCoverageByInstanceTypeQuery, ListReservationCoverageByInstanceTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReservationCoverageByInstanceTypeQuery, ListReservationCoverageByInstanceTypeQueryVariables>(ListReservationCoverageByInstanceTypeDocument, options);
      }
export function useListReservationCoverageByInstanceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReservationCoverageByInstanceTypeQuery, ListReservationCoverageByInstanceTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReservationCoverageByInstanceTypeQuery, ListReservationCoverageByInstanceTypeQueryVariables>(ListReservationCoverageByInstanceTypeDocument, options);
        }
export type ListReservationCoverageByInstanceTypeQueryHookResult = ReturnType<typeof useListReservationCoverageByInstanceTypeQuery>;
export type ListReservationCoverageByInstanceTypeLazyQueryHookResult = ReturnType<typeof useListReservationCoverageByInstanceTypeLazyQuery>;
export type ListReservationCoverageByInstanceTypeQueryResult = Apollo.QueryResult<ListReservationCoverageByInstanceTypeQuery, ListReservationCoverageByInstanceTypeQueryVariables>;
export const ListReservationsRecommendationsDocument = gql`
    query listReservationsRecommendations($page: Int!, $limit: Int!) {
  listReservationsRecommendations(limit: $limit, page: $page) {
    pages
    count
    recommendations {
      numberOfInstances
      termInYears
      instanceType
      platform
      provider
      account
      monthlySavings
      monthlySavingsPercentage
      upfrontCost
      recurringMonthlyCost
      expectedUtilisation
    }
  }
}
    `;

/**
 * __useListReservationsRecommendationsQuery__
 *
 * To run a query within a React component, call `useListReservationsRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationsRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationsRecommendationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListReservationsRecommendationsQuery(baseOptions: Apollo.QueryHookOptions<ListReservationsRecommendationsQuery, ListReservationsRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReservationsRecommendationsQuery, ListReservationsRecommendationsQueryVariables>(ListReservationsRecommendationsDocument, options);
      }
export function useListReservationsRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReservationsRecommendationsQuery, ListReservationsRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReservationsRecommendationsQuery, ListReservationsRecommendationsQueryVariables>(ListReservationsRecommendationsDocument, options);
        }
export type ListReservationsRecommendationsQueryHookResult = ReturnType<typeof useListReservationsRecommendationsQuery>;
export type ListReservationsRecommendationsLazyQueryHookResult = ReturnType<typeof useListReservationsRecommendationsLazyQuery>;
export type ListReservationsRecommendationsQueryResult = Apollo.QueryResult<ListReservationsRecommendationsQuery, ListReservationsRecommendationsQueryVariables>;
export const ReservationsGlobalUtilisationDocument = gql`
    query reservationsGlobalUtilisation {
  reservationsGlobalUtilisation {
    totalReservationCost
    totalOnDemandCostEquivalent
    totalBenefit
    timeSeries {
      date
      value
      benefit
    }
  }
}
    `;

/**
 * __useReservationsGlobalUtilisationQuery__
 *
 * To run a query within a React component, call `useReservationsGlobalUtilisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsGlobalUtilisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsGlobalUtilisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useReservationsGlobalUtilisationQuery(baseOptions?: Apollo.QueryHookOptions<ReservationsGlobalUtilisationQuery, ReservationsGlobalUtilisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsGlobalUtilisationQuery, ReservationsGlobalUtilisationQueryVariables>(ReservationsGlobalUtilisationDocument, options);
      }
export function useReservationsGlobalUtilisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsGlobalUtilisationQuery, ReservationsGlobalUtilisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsGlobalUtilisationQuery, ReservationsGlobalUtilisationQueryVariables>(ReservationsGlobalUtilisationDocument, options);
        }
export type ReservationsGlobalUtilisationQueryHookResult = ReturnType<typeof useReservationsGlobalUtilisationQuery>;
export type ReservationsGlobalUtilisationLazyQueryHookResult = ReturnType<typeof useReservationsGlobalUtilisationLazyQuery>;
export type ReservationsGlobalUtilisationQueryResult = Apollo.QueryResult<ReservationsGlobalUtilisationQuery, ReservationsGlobalUtilisationQueryVariables>;
export const ReservationsGlobalCoverageDocument = gql`
    query reservationsGlobalCoverage {
  reservationsGlobalCoverage {
    averageCoverage
    totalCoveredHours
    totalOnDemandHours
    totalOnDemandCost
    timeSeries {
      date
      value
    }
  }
}
    `;

/**
 * __useReservationsGlobalCoverageQuery__
 *
 * To run a query within a React component, call `useReservationsGlobalCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsGlobalCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsGlobalCoverageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReservationsGlobalCoverageQuery(baseOptions?: Apollo.QueryHookOptions<ReservationsGlobalCoverageQuery, ReservationsGlobalCoverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsGlobalCoverageQuery, ReservationsGlobalCoverageQueryVariables>(ReservationsGlobalCoverageDocument, options);
      }
export function useReservationsGlobalCoverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsGlobalCoverageQuery, ReservationsGlobalCoverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsGlobalCoverageQuery, ReservationsGlobalCoverageQueryVariables>(ReservationsGlobalCoverageDocument, options);
        }
export type ReservationsGlobalCoverageQueryHookResult = ReturnType<typeof useReservationsGlobalCoverageQuery>;
export type ReservationsGlobalCoverageLazyQueryHookResult = ReturnType<typeof useReservationsGlobalCoverageLazyQuery>;
export type ReservationsGlobalCoverageQueryResult = Apollo.QueryResult<ReservationsGlobalCoverageQuery, ReservationsGlobalCoverageQueryVariables>;
export const UpdateRiCoverageTargetDocument = gql`
    mutation updateRICoverageTarget($target: Float!) {
  updateRICoverageTarget(target: $target)
}
    `;
export type UpdateRiCoverageTargetMutationFn = Apollo.MutationFunction<UpdateRiCoverageTargetMutation, UpdateRiCoverageTargetMutationVariables>;

/**
 * __useUpdateRiCoverageTargetMutation__
 *
 * To run a mutation, you first call `useUpdateRiCoverageTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiCoverageTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiCoverageTargetMutation, { data, loading, error }] = useUpdateRiCoverageTargetMutation({
 *   variables: {
 *      target: // value for 'target'
 *   },
 * });
 */
export function useUpdateRiCoverageTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRiCoverageTargetMutation, UpdateRiCoverageTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRiCoverageTargetMutation, UpdateRiCoverageTargetMutationVariables>(UpdateRiCoverageTargetDocument, options);
      }
export type UpdateRiCoverageTargetMutationHookResult = ReturnType<typeof useUpdateRiCoverageTargetMutation>;
export type UpdateRiCoverageTargetMutationResult = Apollo.MutationResult<UpdateRiCoverageTargetMutation>;
export type UpdateRiCoverageTargetMutationOptions = Apollo.BaseMutationOptions<UpdateRiCoverageTargetMutation, UpdateRiCoverageTargetMutationVariables>;
export const SearchFiltersDocument = gql`
    query SearchFilters {
  applications {
    name
    id
    nid
    environments {
      name
      id
      nid
    }
  }
  accounts {
    provider
    name
    id
    providerId
    activated
    providerType
    status
  }
}
    `;

/**
 * __useSearchFiltersQuery__
 *
 * To run a query within a React component, call `useSearchFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchFiltersQuery(baseOptions?: Apollo.QueryHookOptions<SearchFiltersQuery, SearchFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchFiltersQuery, SearchFiltersQueryVariables>(SearchFiltersDocument, options);
      }
export function useSearchFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFiltersQuery, SearchFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchFiltersQuery, SearchFiltersQueryVariables>(SearchFiltersDocument, options);
        }
export type SearchFiltersQueryHookResult = ReturnType<typeof useSearchFiltersQuery>;
export type SearchFiltersLazyQueryHookResult = ReturnType<typeof useSearchFiltersLazyQuery>;
export type SearchFiltersQueryResult = Apollo.QueryResult<SearchFiltersQuery, SearchFiltersQueryVariables>;
export const CloudWasteSettingsDocument = gql`
    query CloudWasteSettings {
  cloudWasteSettings {
    id
    name
    description
    status
    parameters {
      ... on CloudWasteSettingParameterInt {
        id
        description
        value
        min
        max
      }
    }
  }
}
    `;

/**
 * __useCloudWasteSettingsQuery__
 *
 * To run a query within a React component, call `useCloudWasteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWasteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWasteSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWasteSettingsQuery(baseOptions?: Apollo.QueryHookOptions<CloudWasteSettingsQuery, CloudWasteSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloudWasteSettingsQuery, CloudWasteSettingsQueryVariables>(CloudWasteSettingsDocument, options);
      }
export function useCloudWasteSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloudWasteSettingsQuery, CloudWasteSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloudWasteSettingsQuery, CloudWasteSettingsQueryVariables>(CloudWasteSettingsDocument, options);
        }
export type CloudWasteSettingsQueryHookResult = ReturnType<typeof useCloudWasteSettingsQuery>;
export type CloudWasteSettingsLazyQueryHookResult = ReturnType<typeof useCloudWasteSettingsLazyQuery>;
export type CloudWasteSettingsQueryResult = Apollo.QueryResult<CloudWasteSettingsQuery, CloudWasteSettingsQueryVariables>;
export const UpdateCloudWasteSettingDocument = gql`
    mutation UpdateCloudWasteSetting($input: UpdateCloudWasteSetting!) {
  updateCloudWasteSetting(input: $input)
}
    `;
export type UpdateCloudWasteSettingMutationFn = Apollo.MutationFunction<UpdateCloudWasteSettingMutation, UpdateCloudWasteSettingMutationVariables>;

/**
 * __useUpdateCloudWasteSettingMutation__
 *
 * To run a mutation, you first call `useUpdateCloudWasteSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCloudWasteSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCloudWasteSettingMutation, { data, loading, error }] = useUpdateCloudWasteSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCloudWasteSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCloudWasteSettingMutation, UpdateCloudWasteSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCloudWasteSettingMutation, UpdateCloudWasteSettingMutationVariables>(UpdateCloudWasteSettingDocument, options);
      }
export type UpdateCloudWasteSettingMutationHookResult = ReturnType<typeof useUpdateCloudWasteSettingMutation>;
export type UpdateCloudWasteSettingMutationResult = Apollo.MutationResult<UpdateCloudWasteSettingMutation>;
export type UpdateCloudWasteSettingMutationOptions = Apollo.BaseMutationOptions<UpdateCloudWasteSettingMutation, UpdateCloudWasteSettingMutationVariables>;
export const GlobalTagsDocument = gql`
    query GlobalTags {
  globalTagKeys {
    application
    environment
  }
}
    `;

/**
 * __useGlobalTagsQuery__
 *
 * To run a query within a React component, call `useGlobalTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalTagsQuery(baseOptions?: Apollo.QueryHookOptions<GlobalTagsQuery, GlobalTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalTagsQuery, GlobalTagsQueryVariables>(GlobalTagsDocument, options);
      }
export function useGlobalTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalTagsQuery, GlobalTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalTagsQuery, GlobalTagsQueryVariables>(GlobalTagsDocument, options);
        }
export type GlobalTagsQueryHookResult = ReturnType<typeof useGlobalTagsQuery>;
export type GlobalTagsLazyQueryHookResult = ReturnType<typeof useGlobalTagsLazyQuery>;
export type GlobalTagsQueryResult = Apollo.QueryResult<GlobalTagsQuery, GlobalTagsQueryVariables>;
export const UpdateGlobalKeysDocument = gql`
    mutation UpdateGlobalKeys($application: [String], $environment: [String]) {
  updateGlobalTagKeys(
    input: {application: $application, environment: $environment}
  )
}
    `;
export type UpdateGlobalKeysMutationFn = Apollo.MutationFunction<UpdateGlobalKeysMutation, UpdateGlobalKeysMutationVariables>;

/**
 * __useUpdateGlobalKeysMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalKeysMutation, { data, loading, error }] = useUpdateGlobalKeysMutation({
 *   variables: {
 *      application: // value for 'application'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useUpdateGlobalKeysMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGlobalKeysMutation, UpdateGlobalKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGlobalKeysMutation, UpdateGlobalKeysMutationVariables>(UpdateGlobalKeysDocument, options);
      }
export type UpdateGlobalKeysMutationHookResult = ReturnType<typeof useUpdateGlobalKeysMutation>;
export type UpdateGlobalKeysMutationResult = Apollo.MutationResult<UpdateGlobalKeysMutation>;
export type UpdateGlobalKeysMutationOptions = Apollo.BaseMutationOptions<UpdateGlobalKeysMutation, UpdateGlobalKeysMutationVariables>;
export const CostMappingsDocument = gql`
    query costMappings {
  costMappings {
    id
    type
    provider
    algorithm
    settings {
      allEnvironments
      environments {
        id
        splitValue
      }
    }
  }
}
    `;

/**
 * __useCostMappingsQuery__
 *
 * To run a query within a React component, call `useCostMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCostMappingsQuery(baseOptions?: Apollo.QueryHookOptions<CostMappingsQuery, CostMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostMappingsQuery, CostMappingsQueryVariables>(CostMappingsDocument, options);
      }
export function useCostMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostMappingsQuery, CostMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostMappingsQuery, CostMappingsQueryVariables>(CostMappingsDocument, options);
        }
export type CostMappingsQueryHookResult = ReturnType<typeof useCostMappingsQuery>;
export type CostMappingsLazyQueryHookResult = ReturnType<typeof useCostMappingsLazyQuery>;
export type CostMappingsQueryResult = Apollo.QueryResult<CostMappingsQuery, CostMappingsQueryVariables>;
export const UpdateCostMappingRuleAlgorithmDocument = gql`
    mutation updateCostMappingRuleAlgorithm($id: String!, $algorithm: CostMappingAlgorithm, $settings: CostSettingsInput) {
  updateCostCategoryMapping(
    input: {id: $id, algorithm: $algorithm, settings: $settings}
  )
}
    `;
export type UpdateCostMappingRuleAlgorithmMutationFn = Apollo.MutationFunction<UpdateCostMappingRuleAlgorithmMutation, UpdateCostMappingRuleAlgorithmMutationVariables>;

/**
 * __useUpdateCostMappingRuleAlgorithmMutation__
 *
 * To run a mutation, you first call `useUpdateCostMappingRuleAlgorithmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostMappingRuleAlgorithmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostMappingRuleAlgorithmMutation, { data, loading, error }] = useUpdateCostMappingRuleAlgorithmMutation({
 *   variables: {
 *      id: // value for 'id'
 *      algorithm: // value for 'algorithm'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateCostMappingRuleAlgorithmMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCostMappingRuleAlgorithmMutation, UpdateCostMappingRuleAlgorithmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCostMappingRuleAlgorithmMutation, UpdateCostMappingRuleAlgorithmMutationVariables>(UpdateCostMappingRuleAlgorithmDocument, options);
      }
export type UpdateCostMappingRuleAlgorithmMutationHookResult = ReturnType<typeof useUpdateCostMappingRuleAlgorithmMutation>;
export type UpdateCostMappingRuleAlgorithmMutationResult = Apollo.MutationResult<UpdateCostMappingRuleAlgorithmMutation>;
export type UpdateCostMappingRuleAlgorithmMutationOptions = Apollo.BaseMutationOptions<UpdateCostMappingRuleAlgorithmMutation, UpdateCostMappingRuleAlgorithmMutationVariables>;
export const DefaultApplicationDocument = gql`
    query defaultApplication {
  applications {
    name
    id
    nid
    environments {
      name
      id
      nid
      resourceCount
    }
  }
}
    `;

/**
 * __useDefaultApplicationQuery__
 *
 * To run a query within a React component, call `useDefaultApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultApplicationQuery(baseOptions?: Apollo.QueryHookOptions<DefaultApplicationQuery, DefaultApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultApplicationQuery, DefaultApplicationQueryVariables>(DefaultApplicationDocument, options);
      }
export function useDefaultApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultApplicationQuery, DefaultApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultApplicationQuery, DefaultApplicationQueryVariables>(DefaultApplicationDocument, options);
        }
export type DefaultApplicationQueryHookResult = ReturnType<typeof useDefaultApplicationQuery>;
export type DefaultApplicationLazyQueryHookResult = ReturnType<typeof useDefaultApplicationLazyQuery>;
export type DefaultApplicationQueryResult = Apollo.QueryResult<DefaultApplicationQuery, DefaultApplicationQueryVariables>;
export const GetUsersPermissionsDocument = gql`
    query getUsersPermissions($limit: Int!, $page: Int!) {
  usersPermissions(limit: $limit, page: $page) {
    usersPermissions {
      email
      access {
        role
        applications
        applicationsWithNames {
          nid
          name
          displayName
        }
      }
    }
    pages
    count
  }
}
    `;

/**
 * __useGetUsersPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUsersPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPermissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetUsersPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>(GetUsersPermissionsDocument, options);
      }
export function useGetUsersPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>(GetUsersPermissionsDocument, options);
        }
export type GetUsersPermissionsQueryHookResult = ReturnType<typeof useGetUsersPermissionsQuery>;
export type GetUsersPermissionsLazyQueryHookResult = ReturnType<typeof useGetUsersPermissionsLazyQuery>;
export type GetUsersPermissionsQueryResult = Apollo.QueryResult<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>;
export const RemoveUserPermissionsDocument = gql`
    mutation removeUserPermissions($email: String!) {
  removeUserPermissions(email: $email)
}
    `;
export type RemoveUserPermissionsMutationFn = Apollo.MutationFunction<RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables>;

/**
 * __useRemoveUserPermissionsMutation__
 *
 * To run a mutation, you first call `useRemoveUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPermissionsMutation, { data, loading, error }] = useRemoveUserPermissionsMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables>(RemoveUserPermissionsDocument, options);
      }
export type RemoveUserPermissionsMutationHookResult = ReturnType<typeof useRemoveUserPermissionsMutation>;
export type RemoveUserPermissionsMutationResult = Apollo.MutationResult<RemoveUserPermissionsMutation>;
export type RemoveUserPermissionsMutationOptions = Apollo.BaseMutationOptions<RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables>;
export const SetUserPermissionsDocument = gql`
    mutation setUserPermissions($email: String!, $input: AccessInput!) {
  setUserPermissions(email: $email, input: $input)
}
    `;
export type SetUserPermissionsMutationFn = Apollo.MutationFunction<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>;

/**
 * __useSetUserPermissionsMutation__
 *
 * To run a mutation, you first call `useSetUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPermissionsMutation, { data, loading, error }] = useSetUserPermissionsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>(SetUserPermissionsDocument, options);
      }
export type SetUserPermissionsMutationHookResult = ReturnType<typeof useSetUserPermissionsMutation>;
export type SetUserPermissionsMutationResult = Apollo.MutationResult<SetUserPermissionsMutation>;
export type SetUserPermissionsMutationOptions = Apollo.BaseMutationOptions<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>;
export const ApplicationsPaginatedListDocument = gql`
    query ApplicationsPaginatedList($limit: Int!, $page: Int!) {
  applicationsPaginated(limit: $limit, page: $page) {
    pages
    count
    results {
      name
      description
      nid
    }
  }
}
    `;

/**
 * __useApplicationsPaginatedListQuery__
 *
 * To run a query within a React component, call `useApplicationsPaginatedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useApplicationsPaginatedListQuery(baseOptions: Apollo.QueryHookOptions<ApplicationsPaginatedListQuery, ApplicationsPaginatedListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsPaginatedListQuery, ApplicationsPaginatedListQueryVariables>(ApplicationsPaginatedListDocument, options);
      }
export function useApplicationsPaginatedListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsPaginatedListQuery, ApplicationsPaginatedListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsPaginatedListQuery, ApplicationsPaginatedListQueryVariables>(ApplicationsPaginatedListDocument, options);
        }
export type ApplicationsPaginatedListQueryHookResult = ReturnType<typeof useApplicationsPaginatedListQuery>;
export type ApplicationsPaginatedListLazyQueryHookResult = ReturnType<typeof useApplicationsPaginatedListLazyQuery>;
export type ApplicationsPaginatedListQueryResult = Apollo.QueryResult<ApplicationsPaginatedListQuery, ApplicationsPaginatedListQueryVariables>;